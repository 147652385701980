.my-form
    .form-group
        margin-bottom: 20px
        label
            font-weight: 400

    .input-group
        margin-bottom: 11px
        width: 100%

    .form-control
        border-color: gray-border
        height: 40px

    .has-error
        .form-control                 
            border-color: #ce8483 
            box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 6px #ce8483                            


    input
        color: primary-color
        font-weight: 600
        &::-webkit-input-placeholder
            color: #b1b1b1
            opacity: 1
            font-weight: 400					
        &::-moz-placeholder
            color: #b1b1b1
            opacity: 1
            font-weight: 400	                            
        &:-ms-input-placeholder
            color: #b1b1b1
            opacity: 1
            font-weight: 400		                            
        &:-moz-placeholder
            color: #b1b1b1
            opacity: 1
            font-weight: 400