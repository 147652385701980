#payment-result-text-container
    text-align: center
    padding-bottom: 60px

    h1,
    h2
        padding: 0
        margin: 0
        font-size: 25px

        p
            color: rgb(35, 47, 95)
            background: transparent
            font-size: 25px
            line-height: 25px
            padding: 0
            margin: 0
    h1
        margin-top: 20px

    h2
        background: transparent
        margin-top: 10px

    p
        font-size: 15px
        padding-top: 20px

        a
            color: #57c5c9
