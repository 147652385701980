.information-campaign
    color: buttons-color
    padding-bottom: pad-bot
    @media(max-width: 1024px)
        padding-bottom: 80px
    @media(max-width: 768px)
        padding-bottom: 50px

    .material-wrapper
        display: flex
        flex-wrap: wrap
        justify-content: space-between
        a
            display: flex
            justify-content: center
            align-items: center
            flex-direction: column
            margin-bottom: 20px
            width: 31%
            @media(max-width: 767px)
                width: 100%
            .svg
                height: 29px
            p
                font-size: 13px
                font-weight: bold
                text-transform: uppercase
                margin-top: 12px