#send-mail-popup
    font-family: 'Titillium Web', sans-serif
    color: buttons-color
    position: fixed
    top: 0
    left: 0
    height: 100%
    width: 100%
    background-color: rgba( brand-color, 0.77 )
    z-index: 999999999
    display: none

    .popup-wrapper
        position: absolute
        background-color: #fff
        top: 2%
        height: 96%
        width: 700px
        max-width: 90%
        left: 50%
        transform: translateX(-50%)
        border-radius: 10
        overflow-y: auto 
        .nano-content
            padding: 50px 50px 0
        h1
            margin-bottom: 35px
            font-size: 24px
            font-weight: bold            
        
        label
            display: block
            margin-bottom: 8px
        input
            width: 100%
            margin-bottom: 5px

            &.has-error
                input
                    border: 1px solid #e89999
                    background-color: #ffeded

        .content-message
            margin-top: 45px
            .content
                padding: 22px 
                border: 1px solid #ccc
                border-radius: 4px
                box-shadow: inset 0 1px 1px rgba(0,0,0,0.075)
                margin-bottom: 50px
                h3
                    &:last-child
                        margin-top 50px

                img 
                    max-width: 100%
                    margin-bottom: 40px
                h3
                    font-size: 24px
                    font-weight: bold
                    margin-bottom: 20px

                    strong
                        font-weight: bold
                        
                p
                    margin-bottom: 45px
        .btn-light
            font-size: 13px
            min-width: 174px
            margin-bottom: 30px
        textarea 
            display: none