.quote-below-gallery
    & *
        font-family: 'Titillium Web', sans-serif;
        font-size: 18px;
        line-height: 24px;
        margin: 0 auto;
        color: #232f5f;
        text-align: center;
        @media(min-width: 992px)
            font-size: 24px;
