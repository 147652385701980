.join-text-wrapper
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0% 5%;
    align-content: center;
    text-align: center;
    @media(min-width: 992px)
        padding: 0 20% 5%;
    .join-date 
        font-family: 'Titillium Web', sans-serif;
        font-size: 24px;
        line-height: 34px;
        font-weight: bold;
        color: #57c5c9;
        margin: 0;
        @media(min-width: 992px)
            font-size: 45px;
            line-height: 65px;
    .join-title
        font-family: 'Titillium Web', sans-serif;
        font-size: 30px;
        line-height: 40px;
        font-weight: bold;
        color: #232f5f; 
        background: none !important; 
        margin: 20px -20px 0; 
        @media(min-width: 992px)
            font-size: 58px;
            line-height: 76px
    .join-subtitle
        font-family: 'Titillium Web', sans-serif;
        font-size: 18px;
        font-weight: normal;
        line-height: 34px;
        color: #57c5c9;
        margin: 0;
        @media(min-width: 992px)
            font-size: 36px;
            line-height: 64px
