#support
    color: buttons-color
    .support-content  
        padding-bottom: pad-bot
        @media(max-width: 1024px)
            padding-bottom: 80px
        @media(max-width: 768px)
            padding-bottom: 50px

	    
        .support-paragraph
            margin-bottom: 50px
            line-height: 30px
        .support-header
            margin-top: 60px
            margin-bottom: 50px
            @media(max-width:326px)
                font-size: 23px
        .link-pay,
        button
            display: inline-block
            padding: 0 20px
            width: 100%
            height: 200px
            @media(max-width:768px)
                height: 150px
            @media(max-width:425px)
                height: 140px   
            @media(max-width:375px)
                height: 130px
            @media(max-width:320px)
                height: 120px                                     
            display: flex
            align-items: center
            justify-content: center
            border: 1px solid gray-border
            border-radius: 5px
            background-color: white
            @media(max-width:767px)
                margin-bottom: 25px
            transition: background-color 0.3s ease-out            
            &:hover
                background-color: like-white 
                border: 1px solid buttons-color      