.blocks-wrapper
    display: block;
    width: 100%;
    .single-block
        display: flex;
        flex-direction: column;
        // flex-direction: row; // row-reverse
        justify-content: space-between; 
        flex-wrap: wrap;
        width: 100%;
        margin: 0 0 50px;
        @media(min-width: 768px)
            &:nth-child(2n)
                flex-direction: row-reverse;
            &:nth-child(2n-1)
                flex-direction: row;
        .picture-wrapper
            display: flex;
            flex-direction: column; 
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;
            @media(min-width: 768px)
                width: 40%
            .block-picture
                max-width: 100%;
                height: 120px;
                display: block;
                padding-bottom: 25px;
                @media(min-width: 768px)
                    height: 205px;
            .picture-label
                font-family: 'Titillium Web', sans-serif;
                font-size: 24px;
                line-height: 33px;
                font-weight: bold;
                color: #232f5f;
                text-transform: uppercase;
                text-align: center;
                @media(min-width: 992px)
                    font-size: 33px;
        .text-wrapper
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            padding: 20px 5% 0;
            width: 100%;
            @media(min-width: 768px)
                width: 60%;
            > *
                color: #232f5f;
                font-family: 'Titillium Web', sans-serif;
                line-height: 28px;
            ul 
                list-style: none;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding: 20px 10px 10px;
                margin: 0;
                li
                    font-size: 20px;
                    padding-left: 15px;
                    position: relative;
                li::before
                    content: '';
                    width: 7px;
                    height: 7px;
                    position: absolute;
                    top: 10px;
                    left: -10px;
                    border-radius: 50%;
                    background-color: #1792a8;
            p
                font-size: 18px;
                line-height: 28px;
                padding: 0 0 10px;
                background: none;
                @media(min-width: 992px)
                    font-size: 20px
            h2
                font-size: 18px;
                line-height: 28px;
                padding: 0;
                background: none;
                font-weight: bold;
                margin: 0;
                display: contents;
                @media(min-width: 992px)
                    font-size: 24px
                    display: block;







