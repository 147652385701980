div.content-wrapper
    width: 100%;
    // height: 515px;
    position: relative;
    .info-block
        margin: -100px 0 0;
        height: 100%;
        background-color: #57c5c9;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-betwween;
        flex-wrap: wrap;
        padding: 8% 9%;
        align-content: center;
        @media(min-width: 992px)
            margin: -170px 12% 0;
            padding: 3% 16%;
        .start-header
            & *
                font-family: 'Titillium Web', sans-serif;
                font-size: 30px !important;
                line-height: 35px;
                text-align: center;
                color: #232f5f !important;
                margin: 0px;
                @media(min-width: 992px)
                    font-size: 45px !important;
                    line-height: 56px;
        .start-quote
            margin: 40px 0 20px;
            & *
                font-family: 'Titillium Web', sans-serif;
                font-size: 22px !important;
                line-height: 29px;
                color: white !important;
                text-align: center; 
                line-height: 35px;
                margin: 0px;
                @media(min-width: 992px)
                    font-size: 30px !important;
                    line-height: 43px;
        .quote-info
            & *
                font-family: 'Titillium Web', sans-serif;
                font-size: 17px;
                line-height: 29px;
                color: #232f5f;
                text-align: center;
                @media(min-width: 992px)
                    font-size: 18px;
                    line-height: 29px;

