.not-found
    padding-top: 160px
    background-color like-white
    .container
      

        .big
            color: brand-color
            font-weight: bold
            font-size: 250px
            line-height: 1
            @media(max-width: 480px)
                font-size: 100px
                margin-bottom: 8px


        p
            display: inline
            font-size: 24px
            color: brand-color
            @media(max-width: 480px)
                font-size: 21px                
        a
            color: buttons-color
            font-size: 24px
            text-decoration: none
            @media(max-width: 480px)
                font-size: 21px                