#download
    color: buttons-color
    padding-top: 50px
    padding-bottom: 50px
    display: flex
    flex-direction: column
    justify-content: center
    text-align: center		
    margin-top: 0
    background-color: white
    @media(min-width: 768px)
        padding-top: 80px
        padding-bottom: 80px
    @media(min-width: 1024px)
        padding-top: pad-top
        padding-bottom: pad-bot
        flex-direction: row
    div.image-module
        width: 100%
        display: flex;
        justify-content: center
        @media(min-width: 1024px)
            width: 45%
            justify-content: flex-end;
            padding-right: 100px
        .image-wrapper
            width: 291px
            height: 355px
            background-size: cover
            background-position: center
            background-repeat: no-repeat
    div.text-module    
        width: 100%
        margin: 0 auto
        padding: 0 !important
        @media(min-width: 992px)
            width: 55%
            padding-left: 50px
            margin: 0 20px
        h3
            margin-bottom: 20px
            text-align: center
            @media(min-width: 1024px)
                text-align: left
        p
            font-weight: 300
            margin-bottom: space
            text-align: center
            @media(min-width: 1024px)
                text-align: left
        .app
            text-align: center
            @media(min-width: 1024px)
                text-align: left
            img
            &:first-child
                // margin-right: 14px
                @media(max-width: 347px)
                    margin-bottom: 14px    