#use-nav-tabs
	background-color: click-play-font
	.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus 
		color: white
		background-color: transparent
	a
		color: primary-font-color
		text-transform: uppercase
		font-weight: bold
		border-right: none
		border-left:none
		border-top:4px solid transparent
		border-bottom: 4px solid transparent

		&:hover  
			border-right: none
			border-left:none      
			border-top:4px solid transparent     
		&:focus
			color: primary-font-color     
	.nav>li>a:hover, .nav>li>a:focus
		background-color: transparent
	.nav-tabs>li>a:hover

		border-bottom: 4px solid click-play-font           
	.nav-tabs>li.active>a, .nav-tabs>li.active>a:hover, .nav-tabs>li.active>a:focus
		border-color: click-play-font
		border-bottom: 4px solid primary-font-color
		color: white
	.nav-tabs 
		border-bottom: 1px solid click-play-font
