
.flash-message-popup
    position: fixed
    background-color: rgba( #000, 0.55 )
    display: flex
    align-items: center
    justify-content: center
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: 9999999
    transition: all 0.4s ease

    .window-popup
        position: relative
        display: flex
        align-items: center
        justify-content: center        
        background-color: #fff
        border-radius: 5px
        width: 475px
        padding: 36px
        text-align: center
        margin: 0 22px
        .cross-popup
            position: absolute
            top: 20px
            right: 22px
            width: 21px
            height: 21px
            cursor: pointer
            &::before
                position: absolute
                // left: 15px
                content: ''
                height: 22px
                width: 2px
                background-color: brand-color
                transform: rotate(45deg)			
            &::after
                position: absolute
                // left: 15px
                content: ''
                height: 22px
                width: 2px
                background-color: brand-color
                transform: rotate(-45deg)        
        .content-popup
            padding: 70px 0

            h6
                margin-top: 0
                margin-bottom: 32px
                font-size: 24px
                color: buttons-color
                font-weight: 700
                @media(max-width: 425px)
                    font-size: 20px

#download-popup.flash-message-popup
    display: none
    font-family: 'Titillium Web', sans-serif;

    .window-popup
        width: 763px
        padding: 36px
        margin: 0 22px     
        .content-popup
            padding: 70px 0

            img 
                max-width 100%
                height: auto
                @media(max-width: 479px)
                    display: none
                @media(max-height: 799px)
                    display: none
            h6
                margin-top: 0
                margin-bottom: 32px
                font-size: 36px
                color: primary-font-color
                font-weight: 700
                max-width: 567px
                @media(max-width: 479px)
                    font-size: 22px
                @media(max-height: 799px)   
                    font-size: 22px                                     
            hr
                margin: 0 0 60px
                @media(max-width: 479px)
                    display: none
                @media(max-height: 799px)                       
                    display: none

            .paragraph1
                color: primary-font-color   
                margin: 0       
                font-size: 17px                      
                @media(max-width: 479px)
                    font-size: 15px 
                @media(max-height: 799px) 
                    font-size: 15px 
            .paragraph2
                color: brand-color
                font-weight: 700
                font-size: 18px
                @media(max-width: 479px)
                    font-size: 16px                 
                @media(max-height: 799px)                    
                    font-size: 16px                        