.net
	position: relative
	height: auto
	color: ligt-grey-font-color
	.logo
		padding-bottom 50px
		svg, img
			width: 185px
	p
		font-size: 14px
		line-height: 24px
		padding-bottom: 0
		margin-bottom: 10px
		color: ligt-grey-font-color
	.info
		&, & *
			font-size: 14px
			margin-top: 20px
			text-align: center
		ul
			list-style-type: none
			padding: 0
			li
				color: primary-font-color
				font-size: 18px
			a, a:hover, a:active, a:focus, a:visited
				// color: primary-font-color
				text-decoration: none
		.social-media
			display: inline
			li
				display: inline
				a
					img
					svg
						width: 28px
						margin-top: 10px
						margin-right: 7px

	h1
		&, & *
			font-size: 40px
			font-weight: bold
			line-height: 4rem
			@media(max-width: 375px)
				font-size: 36px
			@media(max-width: 320px)
				font-size: 30px
			background: transparent
			color: buttons-color
			padding: 0 0 30px

	h2
		&, & *
			font-size: 24px
			background: transparent
			color: buttons-color
			@media(max-width: 320px)
				font-size: 21px
			padding: 0
			margin-bottom: 24px




	.nav-bar
		background-color: primary-font-color
		height: 54px
		width: 100%
		position: fixed
		display: none
		z-index: 1
		@media(max-width: 830px)
			display: block
			a
				color: white
				text-decoration: none

		.nav-bar-section
			position: absolute
			top: 13px
			right: 18px
	.flex-wrapper
		display: flex
		flex-direction: row

		.btn-circle
			width: 30px
			height: 30px
			text-align: center
			padding: 6px 0
			font-size: 12px
			line-height: 1.428571429
			border-radius: 15px
			background-color: secondary-font-color
			color: white

		.left-sidebar
			width: 670px
			padding-top: 22px
			padding-bottom: 22px
			min-height: 100vh
			flex-direction: column
			align-items: center
			display: flex
			.login-content
				width: 100%
				max-width: 475px
				padding: 60px 0
			.host-content
				max-width: 509px
				.info
					padding-top: 24px


			.forms
				.subtitle-member
					margin-top: 22px
				.form-group
					margin-bottom: 11px

					.warning
						display: none
						color: #ce8483

				.input-group
					margin-bottom: 11px
					width: 100%
				.input-col
					margin: 0
				.form-control
					border-color: gray-border
					height: 40px

					&.not-valid
						border-color: red !important

				.has-error
					.form-control
						border-color: #ce8483
						box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 6px #ce8483

					.warning
						display: block

				input
					color: primary-color
					&::-webkit-input-placeholder
						color: #b1b1b1
						opacity: 1
					&::-moz-placeholder
						color: #b1b1b1
						opacity: 1
					&:-ms-input-placeholder
						color: #b1b1b1
						opacity: 1
					&:-moz-placeholder
						color: #b1b1b1
						opacity: 1

				.input-group

					.btn-search

						button
							font-weight: bold
							color: secondary-font-color
							background-color transparent
							border: 1px solid #d7d7d7
							border-right-radius: 4px
							height: 40px
							text-transform: uppercase
							width: 96px

				.search-border
					font-size: 13px
					border: 1px solid gray-border
					border-radius: 4px
					overflow: hidden
					padding: 15px 35px
					margin-bottom: 11px
					border-top: none


					input
						height: 22px
						font-size: 13px
						text-align: center
						background-color: background-input
						transition: none
					label
						font-weight: 400
						color: #b1b1b1
					.notrequired
						background-color: transparent


				.right-col
					padding: 0 0 0 11px
					@media ( max-width: 767px )
						padding: 0

				.checkboxes
					padding-top: 21px
					.form-group
						.checkbox
							label
								padding: 0
								a
									text-decoration: none
									color: ligt-grey-font-color

									strong
										padding-left: 4px
								& > span
									display: flex
									flex-direction: row
									justify-content: center

							input[type=checkbox]
								opacity: 0

								&+ span::before
									content: ""
									display: inline-block
									min-width: 20px
									height: 20px
									transition: all .3s ease
									border-radius: 4px
									background: transparent
									border: 1px solid gray-border
									margin-right: 14px

								&.not-valid
									&+ span::before
										border: 1px solid red


								&:checked
									&+ span::before
										content: ""
										display: inline-block
										min-width: 20px
										height: 20px
										transition: all .3s ease
										border-radius: 4px
										background: transparent
										border: 1px solid gray-border
										margin-right: 14px

									&+ span::after
										content:""
										position: absolute
										top: 10px
										left: 10px
										transform: translate(-50%, -50%)
										min-width: 10px
										min-height: 10px
										background-color: primary-font-color
										border-radius: 50%
										transition: all .3s ease
		#is-parish-known
			border: none
			display: flex
			column-gap: 20px
			padding: 0
			margin: 0
			legend
				border: none
			label
				display: flex
				align-items: center
				column-gap: 10px
				width: fit-content
				margin-bottom: 0
				font-size: 16px
				font-weight: normal
				cursor: pointer
				input
					margin: 0
					position: absolute;
					opacity: 0;
					cursor: pointer;
					height: 0;
					width: 0;
				span.mark
					position: relative
					height: 16px
					aspect-ratio: 1

					padding: 0
					border: solid 1px currentColor
					border-radius: 50%
					background-color: transparent
					&:after
						--size: 50%;
						content: ' '
						position: absolute
						top: 50%
						left: 50%
						translate: -50% -50%
						width: var(--size)
						height: var(--size)
						background-color: transparent
						border-radius: inherit
				input:checked ~ .mark:after
					background-color: brand-color



		.parish-select
			margin-top: 30px
			&.disabled
				display: none

		.buttons
			margin-top: 60px
			.btn-close
				background-color: transparent
				border: 1px solid primary-font-color
				color: primary-font-color
				font-size: 18px
				font-weight: bold
				text-transform: uppercase
				height: 42px
				width: 116px
				margin-right: 13px
			&:focus
				outline-color: #d7d7d7

			.btn-next
				background-color: primary-font-color
				color: white
				font-size: 18px
				text-transform: uppercase
				height: 42px
				min-width: 93px

		.behind-background
			width: calc( 100% - 670px )
			flex-grow: 2
			height: auto
			background-size: cover
			background-position: center
			width: 50%
			@media( max-width: 768px )
				display: none


				@media( max-width: 1023px )
					display: none
			.right-background-wrapper
				background-size: cover
				background-position: center
				height: 100%
			.return-bar
				position: fixed
				top: 28px
				right: 2%
				z-index: 2
				@media(max-width: 830px)
					display: none


	.stage-2
		display: none
	.wizard
		display: flex
		justify-content: center
		text-align: center
		align-items: center
		min-height: 100px
		.circle
			color: brand-color
			font-weight: bold
			font-size: 30px
			border: 5px solid brand-color
			line-height: 28px
			padding: 0
			width: 40px
			height: 40px
			border-radius: 50%
			transition: all 0.2s ease
			&.active
				color: white
				background-color: brand-color
				width: 50px
				height: 50px
				line-height: 37px


		.separator
			background: brand-color
			width: 44px
			height: 4px
			display: block
			align-self: center

.nopadding
	padding: 0

.seconary-color
	color: brand-color

.form-control
	&:focus
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(215, 215, 215, 0.6)

.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus
	outline-color: shadow-primary-color



.bar
	position:absolute
	left: 0
	bottom: 0
	background-color: primary-font-color
	height: 10px
	width: 100%

