body
	&.no-scroll
		overflow: hidden

main
	font-family: 'Titillium Web', sans-serif; 
	.no-padding
		padding: 0

	p
		font-size: 18px
		@media(max-width: 768px)
			font-size: 16px		
		@media(max-width: 768px)
			font-size: 16px
		@media(max-width: 320px)
			font-size: 14px		
									
	h1
		&, & *	
			font-size: 68px
			font-weight: bold
			color: white			
			margin: -0.14em 0

			background-color: rgba(87, 197, 201, 0.77)
			padding-bottom: 0.1em
			// padding-top: 6px
			padding-left: 30px
			padding-right: 30px
			display inline-block






			@media(max-width: 1600px)
				font-size: 90px
			@media(max-width: 1599px)
				font-size: 75px			
			@media(max-width: 1439px)
				font-size: 70px
			@media(max-width: 1365px)
				font-size: 65px
			@media(max-width: 1023px)
				font-size: 60px						
			@media(max-width: 767px)
				font-size: 33px	



	h2
		&, & *	
			font-size: 44px
			color: white
			background-color: rgba(87, 197, 201, 0.77)

			padding-left: 30px
			padding-right: 30px
			padding-bottom: 0.3em
			padding-top: 0.3em
			margin-top: 12px
			display: inline-block
			@media(max-width: 1439px)
				font-size: 55px
			@media(max-width: 1365px)
				font-size: 45px
			@media(max-width: 1023px)
				font-size: 40px						
			@media(max-width: 767px)
				font-size: 25px
				margin-top: 8px

	h3
		&, & *
			font-size: 60px
			font-weight: bold
			margin-bottom: space
			@media(max-width: 768px)
				font-size: 43px
			@media(max-width: 576px)
				font-size: 25px			
	h4
		&, & *	
			font-size: 30px
			@media(max-width: 1024px)
				font-size: 24px
			@media(max-width: 768px)
				margin-top: 22px
				margin-bottom: 6px		

	h5
		&, & *	
			font-size: 24px


	#home-slider
		@media(max-width: 1199px)
			padding-top: 103px
		.slick-initialized 
			.slick-slide
				display: block
				height: calc( 100vh - 90px )
				background-size: cover
				background-repeat: no-repeat
				background-position: center
				
				position: relative        
	
		.slider
			.button-app
				display: flex
				// justify-content: center
				margin-top: 20px
				&:first-child
					a
						margin-left: 14px
			.slick-next
				right: 0
				content: none
			::before
				font-size: 0

			.slide
				.txt-wrapper
					position: absolute
					top: 50%
					transform: translateY(-50% )
					width: fit-content
					margin-right: 42px
				a
					text-decoration: none
					button
						margin-top: 31px
						border: none
						@media(max-height: 480px)
							margin-top: 15px
						img
							@media(max-width: 425px)
								display: none
				.video
					display: none

				.button-app
					img
						margin: 0 2px

		.slick-dots
			bottom: 53px
			li
				width: 12px
				height: 12px
				button
					font-size: 0
					width: 12px
					height: 12px
					color: transparent
					width: 12px
					height: 12px
					border-radius: 10px
					border: 2px solid brand-color
					@media(max-width:1440px)
						padding: 4px

				::before
					color: transparent
					font-size: 14px					
					top: -2px
					left: -3px
					@media(max-width:1440px)
						font-size: 14px	
						top: -4px
						left: -4px						
			.slick-active
				button
				::before
					color: brand-color
					opacity: 1
		.slider, .slick-initialized, .slick-slider, .slick-dotted
			margin-bottom: 0px



	.meetings
		color: like-white-header
		background-size: cover
		background-position: center
		background-repeat: no-repeat
		position: relative
		@media(min-width: 1440px)
			margin: 0 60px	
		.shadow
			// background-color: rgba(87, 197, 201,0.44)
			width: 100%	
		.content	

			padding-top: pad-top
			@media(max-width: 1024px)
				padding-top: 80px
			@media(max-width: 768px)
				padding-top: 50px

			padding-bottom: 300px
			@media(max-width: 1024px)
				padding-bottom: 270px
			@media(max-width: 768px)
				padding-bottom: 250px							
			position: relative
			z-index: 2
			h3
				color: white
		.opacity-ikon
			position: absolute
			z-index: 1
			bottom: -31px
			left: -277px
			@media(max-width:622px)
				display: none
			svg
				width: 1000px				
				&, & *
						// fill:rgba(168, 209, 231,0.44) !important
						opacity: 0.33




	
	.popup
		// background-color: lighter-blue
		padding-bottom: 0
		@media(max-width: 1024px)
			// padding-bottom: 80px
		@media(max-width: 768px)
			// padding-bottom: 50px		
		position: relative
		z-index: 2
		.wrapper
			display: flex
			flex-direction: row
			flex-wrap: wrap
			// align-items: center
			justify-content: center
			justify-content: space-between
			@media(max-width: 1023px)
				justify-content: center
			margin-top: -190px
			position: relative
			z-index: 10
			
			.item
				width: 48%
				@media(max-width: 1023px)
					width: 550px
				
				.frames
					height: 309px
					background-size: cover
					background-position: center
					repeat: no-repeat
					border: none
					border-radius: 15px;
				@media(min-width: 1024px)
					&:nth-child(-n+2)
						.frames
							border-color: light-blue
				@media(max-width: 1023px)
					&:first-child
						.frames
							border-color: light-blue
				.video
					display: none
				h4
					margin-top: 20px

				// &:nth-child(even)
				// 	.frames
				// 		border-right: 9px solid lightest-blue
				// @media(min-width: 1024px)					
				// 	&:nth-child(-n+2)
				// 		.frames
				// 			border-top: 9px solid light-blue
				// 			border-bottom: 9px solid light-blue
				// 			border-left: 9px solid light-blue
				// 		&:nth-child(2)
				// 			.frames
				// 				border-right: 9px solid light-blue
				// @media(max-width: 1023px)
				// 	&:first-child
				// 		.frames
				// 			border-top: 9px solid light-blue
				// 			border-bottom: 9px solid light-blue
				// 			border-left: 9px solid light-blue
				// 			border-right: 9px solid light-blue
				// 	&:nth-child(n+2)
				// 		.frames
				// 			border-right: 9px solid lightest-blue

				.shadow
					background-color: rgba(0, 0, 00, 0.22)
					height: 100%
					position: relative
					transition: background-color 0.3s ease-out
					border-radius: 15px
					.btn-films-play
						height: 34px
						width: 27px
						fill: white
						position: absolute
						top: 85%
						left: 10%
						transform: translate( -50%, -50% )
						transition: background-color 0.3s ease-out
						transition: fill 0.3s ease-out



				h4
					text-align: left
					font-weight: bold
					color: buttons-color
					line-height: 30px
					transition: color 0.3s ease-out
					// margin-top: 20px
					// margin-bottom: 40px

				p
					font-size: 18px
					line-height: 30px
					font-weight: 300
					color: buttons-color
					text-align: left
					text-transform: uppercase
					margin-bottom: 33px
					transition: color 0.3s ease-out
					@media(max-width: 480px)
						font-size: 16px		

				

			
			.item.hovered
				.shadow
					background-color: rgba(35, 47, 95, 0.65)
					transition: background-color 0.3s ease-out
					cursor: pointer
					.btn-films-play
						fill: click-play-font
						transition: fill 0.3s ease-out
				h4
					color: click-play-font
					transition: color 0.3s ease-out
				p
					color: click-play-font
					transition: color 0.3s ease-out


	.team
		color: like-white-header
		background-size: cover
		background-position: center
		background-position: no-repeat
		margin-top: -50px
		@media(min-width: 1440px)
			margin: -50px 60px 0			
		.shadow
			// background-color: rgba(87, 197, 201,0.44)
			width: 100%	
		.content	
			padding-top: 100px
			@media(max-width: 1024px)
				padding-top: 80px
			@media(max-width: 768px)
				padding-top: 50px

			padding-bottom: 100px
			@media(max-width: 1024px)
				padding-bottom: 80px
			@media(max-width: 768px)
				padding-bottom: 50px			
			.line
				overflow: hidden
				h3
					position: relative
					display: inline-block
					line-height: 69px
					&::after
						content: ""
						display: block
						position: absolute
						background-color: like-white-header
						height: 3px
						bottom: 45px
						left: 105%
						width: 1500px


#popup-window
	background-color: rgba(00, 00, 00, 0.64)
	height: 100vh
	display: flex
	visibility: hidden
	align-items: center
	justify-content: center
	position: fixed
	top: 0
	left: 0
	width: 100%
	z-index: 9999999
	opacity: 0
	transition: all 0.4s ease
	&.visible
		visibility: visible
		opacity: 1
	.popups
		position: relative
		.close
			position: absolute
			top: 0
			right: 0
			width: 32px
			height: 32px
			opacity: 0.5
			@media(max-width: 1100px)
				top: -11px
				right: 29px				
			@media(max-width: 480px)
				top: -39px
				right: 10px				
			&:hover
				opacity: 1
			&::before
				position: absolute
				left: 15px
				content: ''
				height: 33px
				width: 2px
				background-color: #f5f5f5
				transform: rotate(45deg)			
			&::after
				position: absolute
				left: 15px
				content: ''
				height: 33px
				width: 2px
				background-color: #f5f5f5
				transform: rotate(-45deg)
		.small-film		
			max-width: 1024px
			padding: 32px
			@media(max-width: 480px)
				padding: 0					

	iframe
		width: 960px
		height: 540px
		@media(max-width: 1439px)
			max-width: 100%
		@media(max-width: 1366px)
			height: 549px
		@media(max-width: 1024px)
			height: 479px
		@media(max-width: 800px)
			height: 353px
		@media(max-width: 768px)
			height: 335px						
		@media(max-width: 640px)
			height: 263px
		@media(max-width: 600px)
			height: 241px
		@media(max-width: 480px)
			height: 216px

#cookies-statement
	position: fixed
	bottom: 0
	left: 0
	right: 0
	background: rgba( 0, 0, 0, .77 )
	font-size: 14px
	font-family: 'Titillium Web', sans-serif
	z-index: 100000000
	color: brand-color
	padding: 20px 0

	a
		color: brand-color
		text-decoration: underline

	button
		background: transparent
		border: 1px solid brand-color
		border-radius: 5px
		margin-left: 40px
		padding: 0 20px
		text-transform: uppercase
		position: absolute
		top: 50%
		transform: translateY( -50% )