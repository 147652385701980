#meetings-text
    color: buttons-color
    .subpage-meetings-paragraph    
        padding-bottom: 60px 


    // .subpage-meetings-columns
    //     padding-bottom: 100px
    //     @media(max-width: 1024px)
    //         padding-bottom: 70px
    //     @media(max-width: 768px)
    //         padding-bottom: 50px    
    //     h5 
    //         color: brand-color
    //         margin-bottom: 40px
    //         font-weight: bold
    //         @media(max-width: 768px)
    //             margin-top: 20px
    //             margin-bottom: 20px
