#subpage-how    
	color: buttons-color
	
	#points
		padding-bottom: 80px
		a
			text-decoration: none
			color: buttons-color
			transition: background-color 0.3s ease-out                        
			.points-row
				margin-bottom: 20px
				display: flex
				align-items: center
				.points-paragraph
					transition: font-weight 0.3s ease-out 
				&:hover
					.points-paragraph
						font-weight: bold
					.wheel
						background-color: primary-font-color
				.wheel
					height: 51px
					width: 51px
					background-color: brand-color
					border-radius: 50%
					transition: background-color 0.3s ease-out                     
					.wheel-number
						color: white        
						font-size: 33.87px
						text-align: center
						font-weight: bold
	.ground
		section
			&:nth-child(odd)
				background-color: like-white                    
	.point
		padding-top: 80px
		padding-bottom: 80px
		.point-row
			margin-bottom: 30px
			display: flex
			// align-items: center     
			.roundel
				height: 51px
				width: 51px
				background-color: white
				border: 2px solid brand-color
				border-radius: 50%
				margin-top: 10px
				.roundel-number
					color: brand-color      
					font-size: 33.87px
					text-align: center
					font-weight: bold  
		a
			text-decoration: none					 
			.btn-point
				font-size: 13px
				height: 32px
				padding-left: 24px
				padding-right: 24px    
				margin-top: 33px

		.point-img
			margin-top: 50px
			display: flex
			justify-content: center
			img
				max-height: 640px

		.social-media-wrapper
			padding: 0
			list-style-type: none
			margin: 0 auto
			display: block
			text-align: center
			li
				margin: 0 8px
				display: inline
				a
					img
					svg
						width: 64px


	.how-works

		.steps-line
			position: absolute
			top: 0
			left: 0
			width: 100%

			image
				width: 100%