body.no-scroll {
  overflow: hidden;
}
main {
  font-family: 'Titillium Web', sans-serif;
}
main .no-padding {
  padding: 0;
}
main p {
  font-size: 18px;
}
@media (max-width: 768px) {
  main p {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  main p {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  main p {
    font-size: 14px;
  }
}
main h1,
main h1 * {
  font-size: 68px;
  font-weight: bold;
  color: #fff;
  margin: -0.14em 0;
  background-color: rgba(87,197,201,0.77);
  padding-bottom: 0.1em;
  padding-left: 30px;
  padding-right: 30px;
  display: inline-block;
}
@media (max-width: 1600px) {
  main h1,
  main h1 * {
    font-size: 90px;
  }
}
@media (max-width: 1599px) {
  main h1,
  main h1 * {
    font-size: 75px;
  }
}
@media (max-width: 1439px) {
  main h1,
  main h1 * {
    font-size: 70px;
  }
}
@media (max-width: 1365px) {
  main h1,
  main h1 * {
    font-size: 65px;
  }
}
@media (max-width: 1023px) {
  main h1,
  main h1 * {
    font-size: 60px;
  }
}
@media (max-width: 767px) {
  main h1,
  main h1 * {
    font-size: 33px;
  }
}
main h2,
main h2 * {
  font-size: 44px;
  color: #fff;
  background-color: rgba(87,197,201,0.77);
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 0.3em;
  padding-top: 0.3em;
  margin-top: 12px;
  display: inline-block;
}
@media (max-width: 1439px) {
  main h2,
  main h2 * {
    font-size: 55px;
  }
}
@media (max-width: 1365px) {
  main h2,
  main h2 * {
    font-size: 45px;
  }
}
@media (max-width: 1023px) {
  main h2,
  main h2 * {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  main h2,
  main h2 * {
    font-size: 25px;
    margin-top: 8px;
  }
}
main h3,
main h3 * {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  main h3,
  main h3 * {
    font-size: 43px;
  }
}
@media (max-width: 576px) {
  main h3,
  main h3 * {
    font-size: 25px;
  }
}
main h4,
main h4 * {
  font-size: 30px;
}
@media (max-width: 1024px) {
  main h4,
  main h4 * {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  main h4,
  main h4 * {
    margin-top: 22px;
    margin-bottom: 6px;
  }
}
main h5,
main h5 * {
  font-size: 24px;
}
@media (max-width: 1199px) {
  main #home-slider {
    padding-top: 103px;
  }
}
main #home-slider .slick-initialized .slick-slide {
  display: block;
  height: calc(100vh - 90px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
main #home-slider .slider .button-app {
  display: flex;
  margin-top: 20px;
}
main #home-slider .slider .button-app:first-child a {
  margin-left: 14px;
}
main #home-slider .slider .slick-next {
  right: 0;
  content: none;
}
main #home-slider .slider ::before {
  font-size: 0;
}
main #home-slider .slider .slide .txt-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
  margin-right: 42px;
}
main #home-slider .slider .slide a {
  text-decoration: none;
}
main #home-slider .slider .slide a button {
  margin-top: 31px;
  border: none;
}
@media (max-height: 480px) {
  main #home-slider .slider .slide a button {
    margin-top: 15px;
  }
}
@media (max-width: 425px) {
  main #home-slider .slider .slide a button img {
    display: none;
  }
}
main #home-slider .slider .slide .video {
  display: none;
}
main #home-slider .slider .slide .button-app img {
  margin: 0 2px;
}
main #home-slider .slick-dots {
  bottom: 53px;
}
main #home-slider .slick-dots li {
  width: 12px;
  height: 12px;
}
main #home-slider .slick-dots li button {
  font-size: 0;
  width: 12px;
  height: 12px;
  color: transparent;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  border: 2px solid #57c5c9;
}
@media (max-width: 1440px) {
  main #home-slider .slick-dots li button {
    padding: 4px;
  }
}
main #home-slider .slick-dots li ::before {
  color: transparent;
  font-size: 14px;
  top: -2px;
  left: -3px;
}
@media (max-width: 1440px) {
  main #home-slider .slick-dots li ::before {
    font-size: 14px;
    top: -4px;
    left: -4px;
  }
}
main #home-slider .slick-dots .slick-active button,
main #home-slider .slick-dots .slick-active ::before {
  color: #57c5c9;
  opacity: 1;
}
main #home-slider .slider,
main #home-slider .slick-initialized,
main #home-slider .slick-slider,
main #home-slider .slick-dotted {
  margin-bottom: 0px;
}
main .meetings {
  color: #fdfdfd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
@media (min-width: 1440px) {
  main .meetings {
    margin: 0 60px;
  }
}
main .meetings .shadow {
  width: 100%;
}
main .meetings .content {
  padding-top: 120px;
  padding-bottom: 300px;
  position: relative;
  z-index: 2;
}
@media (max-width: 1024px) {
  main .meetings .content {
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  main .meetings .content {
    padding-top: 50px;
  }
}
@media (max-width: 1024px) {
  main .meetings .content {
    padding-bottom: 270px;
  }
}
@media (max-width: 768px) {
  main .meetings .content {
    padding-bottom: 250px;
  }
}
main .meetings .content h3 {
  color: #fff;
}
main .meetings .opacity-ikon {
  position: absolute;
  z-index: 1;
  bottom: -31px;
  left: -277px;
}
@media (max-width: 622px) {
  main .meetings .opacity-ikon {
    display: none;
  }
}
main .meetings .opacity-ikon svg {
  width: 1000px;
}
main .meetings .opacity-ikon svg,
main .meetings .opacity-ikon svg * {
  opacity: 0.33;
}
main .popup {
  padding-bottom: 0;
  position: relative;
  z-index: 2;
}
main .popup .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  justify-content: space-between;
  margin-top: -190px;
  position: relative;
  z-index: 10;
}
@media (max-width: 1023px) {
  main .popup .wrapper {
    justify-content: center;
  }
}
main .popup .wrapper .item {
  width: 48%;
}
@media (max-width: 1023px) {
  main .popup .wrapper .item {
    width: 550px;
  }
}
main .popup .wrapper .item .frames {
  height: 309px;
  background-size: cover;
  background-position: center;
  repeat: no-repeat;
  border: none;
  border-radius: 15px;
}
@media (min-width: 1024px) {
  main .popup .wrapper .item:nth-child(-n+2) .frames {
    border-color: #a8d1e7;
  }
}
@media (max-width: 1023px) {
  main .popup .wrapper .item:first-child .frames {
    border-color: #a8d1e7;
  }
}
main .popup .wrapper .item .video {
  display: none;
}
main .popup .wrapper .item h4 {
  margin-top: 20px;
}
main .popup .wrapper .item .shadow {
  background-color: rgba(0,0,0,0.22);
  height: 100%;
  position: relative;
  transition: background-color 0.3s ease-out;
  border-radius: 15px;
}
main .popup .wrapper .item .shadow .btn-films-play {
  height: 34px;
  width: 27px;
  fill: #fff;
  position: absolute;
  top: 85%;
  left: 10%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease-out;
  transition: fill 0.3s ease-out;
}
main .popup .wrapper .item h4 {
  text-align: left;
  font-weight: bold;
  color: #232f5f;
  line-height: 30px;
  transition: color 0.3s ease-out;
}
main .popup .wrapper .item p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
  color: #232f5f;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 33px;
  transition: color 0.3s ease-out;
}
@media (max-width: 480px) {
  main .popup .wrapper .item p {
    font-size: 16px;
  }
}
main .popup .wrapper .item.hovered .shadow {
  background-color: rgba(35,47,95,0.65);
  transition: background-color 0.3s ease-out;
  cursor: pointer;
}
main .popup .wrapper .item.hovered .shadow .btn-films-play {
  fill: #6cd2d6;
  transition: fill 0.3s ease-out;
}
main .popup .wrapper .item.hovered h4 {
  color: #6cd2d6;
  transition: color 0.3s ease-out;
}
main .popup .wrapper .item.hovered p {
  color: #6cd2d6;
  transition: color 0.3s ease-out;
}
main .team {
  color: #fdfdfd;
  background-size: cover;
  background-position: center;
  background-position: no-repeat;
  margin-top: -50px;
}
@media (min-width: 1440px) {
  main .team {
    margin: -50px 60px 0;
  }
}
main .team .shadow {
  width: 100%;
}
main .team .content {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media (max-width: 1024px) {
  main .team .content {
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  main .team .content {
    padding-top: 50px;
  }
}
@media (max-width: 1024px) {
  main .team .content {
    padding-bottom: 80px;
  }
}
@media (max-width: 768px) {
  main .team .content {
    padding-bottom: 50px;
  }
}
main .team .content .line {
  overflow: hidden;
}
main .team .content .line h3 {
  position: relative;
  display: inline-block;
  line-height: 69px;
}
main .team .content .line h3::after {
  content: "";
  display: block;
  position: absolute;
  background-color: #fdfdfd;
  height: 3px;
  bottom: 45px;
  left: 105%;
  width: 1500px;
}
#popup-window {
  background-color: rgba(0,0,0,0.64);
  height: 100vh;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999;
  opacity: 0;
  transition: all 0.4s ease;
}
#popup-window.visible {
  visibility: visible;
  opacity: 1;
}
#popup-window .popups {
  position: relative;
}
#popup-window .popups .close {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  opacity: 0.5;
}
@media (max-width: 1100px) {
  #popup-window .popups .close {
    top: -11px;
    right: 29px;
  }
}
@media (max-width: 480px) {
  #popup-window .popups .close {
    top: -39px;
    right: 10px;
  }
}
#popup-window .popups .close:hover {
  opacity: 1;
}
#popup-window .popups .close::before {
  position: absolute;
  left: 15px;
  content: '';
  height: 33px;
  width: 2px;
  background-color: #f5f5f5;
  transform: rotate(45deg);
}
#popup-window .popups .close::after {
  position: absolute;
  left: 15px;
  content: '';
  height: 33px;
  width: 2px;
  background-color: #f5f5f5;
  transform: rotate(-45deg);
}
#popup-window .popups .small-film {
  max-width: 1024px;
  padding: 32px;
}
@media (max-width: 480px) {
  #popup-window .popups .small-film {
    padding: 0;
  }
}
#popup-window iframe {
  width: 960px;
  height: 540px;
}
@media (max-width: 1439px) {
  #popup-window iframe {
    max-width: 100%;
  }
}
@media (max-width: 1366px) {
  #popup-window iframe {
    height: 549px;
  }
}
@media (max-width: 1024px) {
  #popup-window iframe {
    height: 479px;
  }
}
@media (max-width: 800px) {
  #popup-window iframe {
    height: 353px;
  }
}
@media (max-width: 768px) {
  #popup-window iframe {
    height: 335px;
  }
}
@media (max-width: 640px) {
  #popup-window iframe {
    height: 263px;
  }
}
@media (max-width: 600px) {
  #popup-window iframe {
    height: 241px;
  }
}
@media (max-width: 480px) {
  #popup-window iframe {
    height: 216px;
  }
}
#cookies-statement {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.77);
  font-size: 14px;
  font-family: 'Titillium Web', sans-serif;
  z-index: 100000000;
  color: #57c5c9;
  padding: 20px 0;
}
#cookies-statement a {
  color: #57c5c9;
  text-decoration: underline;
}
#cookies-statement button {
  background: transparent;
  border: 1px solid #57c5c9;
  border-radius: 5px;
  margin-left: 40px;
  padding: 0 20px;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.not-found {
  padding-top: 160px;
  background-color: #f5f5f5;
}
.not-found .container .big {
  color: #57c5c9;
  font-weight: bold;
  font-size: 250px;
  line-height: 1;
}
@media (max-width: 480px) {
  .not-found .container .big {
    font-size: 100px;
    margin-bottom: 8px;
  }
}
.not-found .container p {
  display: inline;
  font-size: 24px;
  color: #57c5c9;
}
@media (max-width: 480px) {
  .not-found .container p {
    font-size: 21px;
  }
}
.not-found .container a {
  color: #232f5f;
  font-size: 24px;
  text-decoration: none;
}
@media (max-width: 480px) {
  .not-found .container a {
    font-size: 21px;
  }
}
.with-us {
  padding-top: 120px;
  color: #232f5f;
  background-color: #fff;
}
@media (max-width: 1024px) {
  .with-us {
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  .with-us {
    padding-top: 50px;
  }
}
.with-us h3,
.with-us h3 * {
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  .with-us h3,
  .with-us h3 * {
    margin-bottom: 70px;
  }
}
@media (max-width: 768px) {
  .with-us h3,
  .with-us h3 * {
    margin-bottom: 40px;
  }
}
.with-us .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 70px;
}
.with-us .wrapper .item {
  width: 120px;
  height: 120px;
  z-index: 2;
  margin: 30px 10px;
  position: relative;
}
.with-us .wrapper .item::before {
  content: "";
  width: 120%;
  height: 3px;
  position: absolute;
  background-color: #57c5c9;
  top: 50%;
  left: -10%;
  z-index: 0;
}
@media (min-width: 425px) {
  .with-us .wrapper .item {
    width: 150px;
    height: 150px;
    margin: 30px 20px;
  }
  .with-us .wrapper .item::before {
    content: "";
    width: 130%;
    height: 3px;
    position: absolute;
    background-color: #57c5c9;
    top: 50%;
    left: -15%;
    z-index: 0;
  }
}
@media (min-width: 768px) {
  .with-us .wrapper .item {
    width: 250px;
    height: 250px;
    margin: 30px 50px;
  }
  .with-us .wrapper .item::before {
    content: "";
    width: 140%;
    height: 3px;
    position: absolute;
    background-color: #57c5c9;
    top: 50%;
    left: -20%;
    z-index: 0;
  }
}
@media (min-width: 992px) {
  .with-us .wrapper .item::before {
    content: "";
    width: 150%;
    height: 3px;
    position: absolute;
    background-color: #57c5c9;
    top: 50%;
    left: -25%;
    z-index: 0;
  }
}
.with-us .wrapper .item .frames {
  height: 100%;
  width: 100%;
  z-index: 10;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.with-us .wrapper .item h5,
.with-us .wrapper .item h5 * {
  text-align: center;
  font-weight: bold;
  color: #232f5f;
  margin-top: -32px;
  position: relative;
  z-index: 45;
  margin-bottom: 40px;
  font-size: 14px;
  padding: 5px 0;
  background-color: #fff;
}
@media (min-width: 425px) {
  .with-us .wrapper .item h5,
  .with-us .wrapper .item h5 * {
    margin-top: -32px;
  }
}
@media (min-width: 768px) {
  .with-us .wrapper .item h5,
  .with-us .wrapper .item h5 * {
    margin-top: -40px;
    font-size: 21px;
  }
}
@media (min-width: 1200px) {
  .with-us .wrapper .item h5,
  .with-us .wrapper .item h5 * {
    font-size: 23px;
    margin-top: -48px;
  }
}
#download {
  color: #232f5f;
  padding-top: 315px;
  padding-bottom: 120px;
  margin-top: -150px;
  background-color: #f5f5f5;
}
@media (max-width: 1024px) {
  #download {
    padding-top: 220px;
  }
}
@media (max-width: 768px) {
  #download {
    padding-top: 180px;
  }
}
@media (max-width: 1024px) {
  #download {
    padding-bottom: 80px;
  }
}
@media (max-width: 768px) {
  #download {
    padding-bottom: 50px;
  }
}
#download h3 {
  margin-bottom: 20px;
}
#download p {
  font-weight: 200;
  margin-bottom: 50px;
}
#download .app img,
#download .app:first-child {
  margin-right: 14px;
}
@media (max-width: 347px) {
  #download .app img,
  #download .app:first-child {
    margin-bottom: 14px;
  }
}
#download {
  color: #232f5f;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 0;
  background-color: #fff;
}
@media (min-width: 768px) {
  #download {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1024px) {
  #download {
    padding-top: 120px;
    padding-bottom: 120px;
    flex-direction: row;
  }
}
#download div.image-module {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (min-width: 1024px) {
  #download div.image-module {
    width: 45%;
    justify-content: flex-end;
    padding-right: 100px;
  }
}
#download div.image-module .image-wrapper {
  width: 291px;
  height: 355px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#download div.text-module {
  width: 100%;
  margin: 0 auto;
  padding: 0 !important;
}
@media (min-width: 992px) {
  #download div.text-module {
    width: 55%;
    padding-left: 50px;
    margin: 0 20px;
  }
}
#download div.text-module h3 {
  margin-bottom: 20px;
  text-align: center;
}
@media (min-width: 1024px) {
  #download div.text-module h3 {
    text-align: left;
  }
}
#download div.text-module p {
  font-weight: 300;
  margin-bottom: 50px;
  text-align: center;
}
@media (min-width: 1024px) {
  #download div.text-module p {
    text-align: left;
  }
}
#download div.text-module .app {
  text-align: center;
}
@media (min-width: 1024px) {
  #download div.text-module .app {
    text-align: left;
  }
}
@media (max-width: 347px) {
  #download div.text-module .app img,
  #download div.text-module .app:first-child {
    margin-bottom: 14px;
  }
}
footer {
  font-family: 'Titillium Web', sans-serif;
  padding-top: 60px;
  padding-bottom: 120px;
  border-bottom: 10px solid #096272;
  background-color: #1792a8;
  position: relative;
}
footer #scroll-up {
  background-color: transparent;
  border: none;
  outline: none;
  position: fixed;
  bottom: 30px;
  right: 5%;
  visibility: hidden;
  opacity: 0;
  transition: 0.8s all ease;
  z-index: 99;
}
footer #scroll-up img {
  height: 29px;
}
footer #scroll-up.visible {
  visibility: visible;
  opacity: 1;
}
footer #scroll-up.sticky {
  top: -31px;
  position: absolute;
  bottom: auto;
}
footer .no-padding {
  padding: 0;
}
footer .dis-blo {
  display: block;
}
@media (max-width: 1024px) {
  footer {
    padding-bottom: 80px;
  }
}
@media (max-width: 768px) {
  footer {
    padding-bottom: 50px;
  }
}
@media (min-width: 1440px) {
  footer .footer-padding {
    padding: 0 0 0 60px;
  }
}
@media (min-width: 1024px) and (max-width: 1399px) {
  footer .footer-padding {
    padding: 0 0 0 30px;
  }
}
@media (max-width: 991px) {
  footer .footer-padding {
    padding: 0;
  }
}
footer ul {
  list-style-type: none;
  padding: 0;
}
footer ul a,
footer ul a:hover,
footer ul a:active,
footer ul a:focus,
footer ul a:visited {
  color: #fff;
  text-decoration: none;
}
footer ul .social-media {
  display: inline-block;
  margin-top: 10px;
  margin-right: 6px;
}
footer ul .social-media a img,
footer ul .social-media a svg {
  width: 28px;
  height: 28px;
}
footer ul .ui-selectmenu-button.ui-button {
  float: left;
  margin: 0;
  margin-top: 10px;
}
footer h6 {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  color: #57c5c9;
  margin-bottom: 20px;
}
footer .pleo-footer {
  position: absolute;
  right: 10px;
  bottom: 4px;
  outline: 0;
  text-decoration: none;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
footer .pleo-footer span {
  padding-right: 10px;
  font-size: 12px;
  color: #57c5c9;
}
footer .pleo-footer img,
footer .pleo-footer svg {
  display: inline-block;
  float: right;
}
footer button {
  border: 0;
  background: transparent;
  color: #fff;
  padding: 0;
}
#crumbs {
  font-size: 10px;
  background-color: #57c5c9;
  border-bottom: 1px solid #2ca3b3;
}
#crumbs .breadcrumb {
  background-color: #57c5c9;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
}
#crumbs .breadcrumb >.active {
  color: #fff;
}
#crumbs .breadcrumb >li+li:before {
  color: #fff;
}
#crumbs .breadcrumb li {
  text-transform: uppercase;
}
#crumbs .breadcrumb li a,
#crumbs .breadcrumb li a:hover,
#crumbs .breadcrumb li a:active,
#crumbs .breadcrumb li a:focus,
#crumbs .breadcrumb li a:visited {
  color: #fff;
  text-decoration: none;
}
#quotation {
  margin-top: -35px;
}
#quotation .citation {
  background-color: #57c5c9;
  margin-bottom: 95px;
  padding-top: 55px;
  position: relative;
}
#quotation .citation::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 485px;
  background-color: #57c5c9;
}
#quotation .citation .container {
  position: relative;
  z-index: 3;
}
#quotation .citation .container .quote {
  border: none !important;
  padding: 0;
  margin: 0;
}
#quotation .citation .container .quote p {
  font-size: 36px;
  color: #fff;
  font-style: italic;
}
@media (max-width: 425px) {
  #quotation .citation .container .quote p {
    font-size: 32px;
  }
}
@media (max-width: 320px) {
  #quotation .citation .container .quote p {
    font-size: 24px;
  }
}
#quotation .citation .container .quote cite {
  font-size: 16px;
  color: #167477;
  font-weight: bold;
}
#quotation .citation .bg-img {
  position: absolute;
  top: 75%;
  right: 8.33%;
  height: 295px;
  z-index: 2;
  opacity: 0.33;
}
#quotation .decoration-green {
  position: relative;
  width: 28%;
  height: 36px;
  margin-left: auto;
  margin-right: auto;
  background-color: #57c5c9;
  z-index: 2;
  margin-bottom: -1px;
}
@media (max-width: 578px) {
  #quotation .decoration-green {
    transform: scale(0.7);
    margin-bottom: -7px;
  }
}
@media (max-width: 364px) {
  #quotation .decoration-green {
    transform: scale(0.6);
    margin-bottom: -8px;
  }
}
#quotation .decoration-green::before {
  content: "";
  display: block;
  position: absolute;
  height: 36px;
  bottom: 0px;
  right: 99%;
  width: 210px;
  transform: rotateX(180deg) rotate(180deg);
  background-image: url("../img/decoration-green-right.png");
  background-repeat: no-repeat;
}
#quotation .decoration-green::after {
  content: "";
  display: block;
  position: absolute;
  height: 36px;
  bottom: 0px;
  left: 99%;
  width: 210px;
  background-image: url("../img/decoration-green-right.png");
  background-repeat: no-repeat;
}
.how-works {
  padding-top: 80px;
  margin-top: 0px;
  background-repeat: no-repeat;
  background-size: 1223px;
  background-position: 45% 166px;
}
@media (max-width: 1199px) {
  .how-works {
    background-image: none !important;
  }
}
.how-works .content {
  position: relative;
}
.how-works .content > .row-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.how-works .content > .row-flex .item-wrapper:nth-child(-n+3) .item a {
  margin-bottom: 168px;
}
@media (max-width: 1199px) {
  .how-works .content > .row-flex .item-wrapper:nth-child(-n+4) .item a {
    margin-bottom: 110px;
  }
}
@media (max-width: 767px) {
  .how-works .content > .row-flex .item-wrapper:nth-child(-n+5) .item a {
    margin-bottom: 110px;
  }
}
@media (min-width: 1200px) {
  .how-works .content .items-wrapper .item-wrapper:nth-child(-n+3) .item .item-img .cover-img .ball {
    bottom: 91px;
  }
}
@media (min-width: 1200px) {
  .how-works .content .items-wrapper .item-wrapper:nth-child(4) .item .item-img .cover-img .ball {
    bottom: 31px;
  }
}
@media (min-width: 2260px) {
  .how-works .content .items-wrapper .item-wrapper:nth-child(4) .item .item-img .cover-img .ball {
    right: 118%;
  }
}
@media (max-width: 1600px) {
  .how-works .content .items-wrapper .item-wrapper:nth-child(4) .item .item-img .cover-img .ball {
    right: 101%;
  }
}
@media (min-width: 1441px) {
  .how-works .content .items-wrapper .item-wrapper {
    width: 30%;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .how-works .content .items-wrapper .item-wrapper {
    width: 33%;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .how-works .content .items-wrapper .item-wrapper {
    width: 49%;
  }
}
@media (max-width: 767px) {
  .how-works .content .items-wrapper .item-wrapper {
    width: 70%;
  }
}
@media (max-width: 500px) {
  .how-works .content .items-wrapper .item-wrapper {
    width: 80%;
  }
}
@media (max-width: 458px) {
  .how-works .content .items-wrapper .item-wrapper {
    width: 90%;
  }
}
@media (max-width: 412px) {
  .how-works .content .items-wrapper .item-wrapper {
    width: 100%;
  }
}
.how-works .content .items-wrapper .item-wrapper .item {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
@media (max-width: 1199px) and (min-width: 992px) {
  .how-works .content .items-wrapper .item-wrapper .item {
    padding: 0 22px;
  }
}
.how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img {
  position: relative;
  display: inline-block;
}
@media (max-width: 1199px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img {
    display: block;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img {
    padding: 0 58px;
  }
}
.how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img img {
  max-width: 100%;
  padding-bottom: 25px;
  height: 205px;
  display: inline-block;
}
@media (max-width: 600px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img img {
    height: 185px;
  }
}
@media (max-width: 375px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img img {
    height: 157px;
  }
}
@media (max-width: 320px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img img {
    height: 146px;
  }
}
.how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img .ball {
  color: #fff;
  font-size: 33.87px;
  font-weight: bold;
  background-color: #57c5c9;
  height: 52px;
  right: calc(100% - 52px);
  width: 52px;
  border-radius: 50%;
  bottom: 18px;
  right: 106%;
  display: inline-block;
  position: absolute;
  top: initial;
}
@media (max-width: 1199px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img .ball {
    left: -6px;
    bottom: 22px;
  }
}
@media (max-width: 991px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img .ball {
    left: -1px;
  }
}
@media (max-width: 375px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img .ball {
    left: 0px;
  }
}
@media (max-width: 600px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img .ball {
    height: 43px;
    width: 43px;
    font-size: 28.87px;
  }
}
.how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img.circle img {
  bottom: 86px;
}
@media (max-width: 991px) {
  .how-works .content .items-wrapper .item-wrapper .item .item-img .cover-img.circle img {
    bottom: 30px;
  }
}
.how-works .content .items-wrapper .item-wrapper .item .paragraph {
  height: 80px;
}
@media (max-width: 600px) {
  .how-works .content .items-wrapper .item-wrapper .item .paragraph {
    height: 66px;
  }
}
.how-works .content .items-wrapper .item-wrapper .item a {
  font-size: 13px;
  height: 32px;
  padding: 18px 25px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  width: auto;
  background-color: transparent;
}
@media (max-width: 425px) {
  .how-works .content .items-wrapper .item-wrapper .item a {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .how-works .content .items-wrapper .item-wrapper .item a {
    margin-top: 5px;
  }
}
.how-works .content .items-wrapper .item-wrapper .item a:hover {
  background-color: #57c5c9;
}
.how-works .content .items-wrapper .item-wrapper .item a:focus {
  background-color: #57c5c9;
}
.how-works .content .steps-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.how-works .content .steps-line image {
  width: 100%;
}
#page-header {
  overflow: hidden;
  margin-bottom: 60px;
  background-color: #57c5c9;
}
@media (max-width: 1199px) {
  #page-header {
    padding-top: 103px;
  }
}
#page-header .pageheader-center {
  background-color: #57c5c9;
  color: #fff;
  height: 230px;
  display: flex;
  align-items: center;
  position: relative;
}
@media (max-width: 1000px) {
  #page-header .pageheader-center {
    height: auto;
  }
}
@media (max-width: 1024px) {
  #page-header .pageheader-center {
    transform: translate(13%);
  }
}
@media (max-width: 1000px) {
  #page-header .pageheader-center {
    transform: none;
  }
}
#page-header .pageheader-center:before {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  width: 100%;
  height: 100%;
  background-color: #1792a8;
}
@media (max-width: 1000px) {
  #page-header .pageheader-center:before {
    display: none;
  }
}
#page-header .pageheader-center .pageheader-svg {
  height: -webkit-fill-available;
  position: absolute;
  left: -180px;
  width: 360px;
  height: 230px;
  bottom: 0;
}
@media (max-width: 1000px) {
  #page-header .pageheader-center .pageheader-svg {
    display: none;
  }
}
#page-header .pageheader-center .pageheader-col {
  padding-top: 22px;
  padding-bottom: 22px;
}
@media (min-width: 1440px) {
  #page-header .pageheader-center .pageheader-col {
    padding-left: 19.6%;
  }
}
@media (min-width: 1001px) {
  #page-header .pageheader-center .pageheader-col {
    padding-left: 25%;
  }
}
@media (max-width: 1000px) {
  #page-header .pageheader-center .pageheader-col {
    padding-left: 22px;
  }
}
#page-header .pageheader-center h3,
#page-header .pageheader-center h3 * {
  margin-bottom: 26px;
  margin-top: 0;
}
#page-header .pageheader-center h6,
#page-header .pageheader-center h6 * {
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 0px;
}
#use-nav-tabs {
  background-color: #6cd2d6;
}
#use-nav-tabs .nav-tabs>li.active>a,
#use-nav-tabs .nav-tabs>li.active>a:hover,
#use-nav-tabs .nav-tabs>li.active>a:focus {
  color: #fff;
  background-color: transparent;
}
#use-nav-tabs a {
  color: #1792a8;
  text-transform: uppercase;
  font-weight: bold;
  border-right: none;
  border-left: none;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
}
#use-nav-tabs a:hover {
  border-right: none;
  border-left: none;
  border-top: 4px solid transparent;
}
#use-nav-tabs a:focus {
  color: #1792a8;
}
#use-nav-tabs .nav>li>a:hover,
#use-nav-tabs .nav>li>a:focus {
  background-color: transparent;
}
#use-nav-tabs .nav-tabs>li>a:hover {
  border-bottom: 4px solid #6cd2d6;
}
#use-nav-tabs .nav-tabs>li.active>a,
#use-nav-tabs .nav-tabs>li.active>a:hover,
#use-nav-tabs .nav-tabs>li.active>a:focus {
  border-color: #6cd2d6;
  border-bottom: 4px solid #1792a8;
  color: #fff;
}
#use-nav-tabs .nav-tabs {
  border-bottom: 1px solid #6cd2d6;
}
#tabs-menu {
  background-color: #6cd2d6;
}
@media (max-width: 991px) {
  #tabs-menu {
    height: auto;
  }
}
@media (max-width: 1200px) {
  #tabs-menu .nav {
    display: none;
  }
}
#tabs-menu .open-tabs-menu {
  display: block;
}
#tabs-menu ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}
@media (max-width: 1200px) {
  #tabs-menu ul {
    flex-direction: column;
  }
}
#tabs-menu ul li {
  margin-right: 64px;
  cursor: pointer;
}
#tabs-menu ul li a {
  text-decoration: none;
  color: #1792a8;
  display: block;
  padding-top: 18px;
  padding-bottom: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 4px solid #6cd2d6;
  transition: border-bottom 0.3s ease-out;
}
#tabs-menu .active {
  color: #fff;
  border-bottom: 4px solid #1792a8;
}
@media (max-width: 1200px) {
  #tabs-menu .active {
    border-bottom: none;
  }
}
#tabs-menu #arrow-tabs-manu {
  background-color: #57c5c9;
  padding-left: 22px;
}
@media (min-width: 1201px) {
  #tabs-menu #arrow-tabs-manu {
    display: none;
  }
}
#tabs-menu #arrow-tabs-manu img {
  transform: rotate(180deg);
}
#tabs-menu #arrow-tabs-manu .active-tab {
  font-family: 'Titillium Web', sans-serif;
  padding-left: 13px;
  color: #fff;
  text-transform: uppercase;
}
.underline-header-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin: 50px 0;
}
@media (min-width: 992px) {
  .underline-header-wrapper {
    margin: 100px 0;
  }
}
.underline-header-wrapper .underline-header {
  color: #232f5f !important;
  font-size: 30px;
  line-height: 32px;
  font-weight: bold;
  display: block;
  position: relative;
  white-space: nowrap;
}
@media (min-width: 992px) {
  .underline-header-wrapper .underline-header {
    font-size: 45px;
    line-height: 32px;
  }
}
.underline-header-wrapper .underline-header::after {
  content: '';
  height: 5px;
  width: 110%;
  background-color: #57c5c9;
  position: absolute;
  z-index: -1;
  left: -5%;
  bottom: -5px;
}
.button-wrapper {
  display: flex;
}
.button-wrapper .link-bialy-button {
  outline: none;
  text-decoration: none;
  cursor: pointer;
}
.button-wrapper .button-events {
  display: flex;
  align-items: center;
  padding: 0 38px 0 55px;
  color: #1792a8;
  background-color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  font-size: 14px;
  border-radius: 23px;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
}
@media (min-width: 992px) {
  .button-wrapper .button-events {
    font-size: 18px;
  }
}
.button-wrapper .button-events .big-add-symbol {
  font-size: 30px;
  margin: 0px 0px 7px 20px;
}
.button-green-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}
.button-green-wrapper .link-zielony-button {
  outline: none;
  text-decoration: none;
  cursor: pointer;
  display: flex;
}
.button-green-wrapper .button-events-line {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 18px 0 35px;
  color: #fff;
  background-color: #57c5c9;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  font-size: 14px;
  border-radius: 23px;
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
}
@media (min-width: 992px) {
  .button-green-wrapper .button-events-line {
    font-size: 18px;
    padding: 0 38px 0 55px;
  }
}
.button-green-wrapper .button-events-line::after {
  content: '';
  background-color: #57c5c9;
  z-index: -1;
  height: 4px;
  width: 125%;
  position: absolute;
  right: -12%;
}
.button-green-wrapper .button-events-line .big-add-symbol {
  font-size: 30px;
  margin: 0px 0px 7px 20px;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:active,
a:hover {
  outline-width: 0;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: inherit;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
figure {
  margin: 1em 40px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}
optgroup {
  font-weight: bold;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.ui-selectmenu-button.ui-button {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
  height: 22px;
  width: 56px;
  float: right;
  padding: 0 6px;
  line-height: 23px;
  height: 25px;
}
.ui-selectmenu-button.ui-button .ui-icon {
  background-image: url("../img/select-arrow.png");
  background-position: center;
  transition: transform 0.4s ease;
  margin-top: 3px;
}
.ui-selectmenu-button.ui-button:focus {
  outline-color: #fff;
}
.ui-selectmenu-button.ui-button:focus .ui-selectmenu-text {
  height: 18px;
  width: 18px;
}
.ui-selectmenu-button.ui-button:focus:focus {
  outline-color: #fff;
}
.ui-selectmenu-button.ui-button.ui-selectmenu-button-open .ui-icon {
  transform: rotate(180deg);
}
.ui-selectmenu-button.ui-button {
  background: #1792a8;
}
.btn-return {
  color: #fff;
  background: #1792a8;
  border: 1px solid #fff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  height: 25px;
  width: 167px;
  margin-right: 11px;
  display: inline-block;
}
.btn-return a {
  color: #fff;
  text-decoration: none;
}
.btn-return:focus {
  outline-color: #fff;
}
.ui-selectmenu-menu {
  margin: -1px;
}
.ui-selectmenu-menu.ui-front.ui-selectmenu-open {
  top: 52px;
  left: 1775.7px;
  position: fixed;
  right: 70px;
  z-index: 99999999;
  display: inline-table;
}
.ui-selectmenu-menu.ui-front.ui-selectmenu-open .ui-menu.ui-corner-bottom.ui-widget.ui-widget-content.overflow {
  border-radius: 3px;
}
.ui-widget-content {
  background: #1792a8;
  color: #fff;
}
.ui-widget.ui-widget-content {
  border: 1px solid #fff;
  border-top: transparent;
  border-radius: 4px;
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  background: #1792a8;
  border: 1px solid #1792a8;
}
.select2-selection.select2-selection--single {
  background-color: #fff;
}
.select2-offscreen {
  position: fixed;
}
.select2-container .select2-selection--single {
  height: 40px;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent;
  border-width: 5px;
  transform: rotate(-180deg);
}
.select2-container--default .select2-selection--single {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
  padding-left: 35px;
  color: #797979;
}
.select2-container--default .select2-selection--single:focus {
  outline-color: #d7d7d7;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 94px;
  border-left: 1px solid #ccc;
  height: calc(100% - 1px);
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url("../img/form-arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  height: 6px;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  width: 9px;
  transition: transform 0.4s ease;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  display: none;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f0f0f0;
  color: #bcbcbc;
}
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f0f0f0;
}
.select2-results__options li {
  padding-left: 35px;
  color: #bcbcbc;
}
.select2-container--default {
  width: 100% !important;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  outline-color: #d7d7d7;
}
.select-background .ui-.buttonui-menu-item-wrapper:hover,
.select-background .ui-button:active,
.select-background .ui-widget-content .ui-menu-item-wrapper,
.select-background .ui-widget-header .ui-menu-item-wrapper,
.select-background a.ui-button:active {
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'Titillium Web', sans-serif;
  margin: -1px;
}
.language-white .ui-widget-content {
  background: #fff;
  color: #1792a8;
}
.language-white .ui-state-active,
.language-white .ui-widget-content .ui-state-active,
.language-white .ui-widget-header .ui-state-active,
.language-white a.ui-button:active,
.language-white .ui-button:active,
.language-white .ui-button.ui-state-active:hover {
  background: #fff;
  border: 1px solid #57c5c9;
}
.language-white .ui-widget.ui-widget-content {
  border: 1px solid #f00;
  width: 55px;
}
.select-background {
  position: absolute !important;
}
.select-background .ui-state-active,
.select-background .ui-widget-content .ui-state-active,
.select-background .ui-widget-header .ui-state-active,
.select-background a.ui-button:active,
.select-background .ui-button:active,
.select-background .ui-button.ui-state-active:hover {
  background: #57c5c9;
  color: #fff;
}
.select-background .ui-widget-content {
  background: #fff;
  color: #1792a8;
}
.select-background .ui-widget-content .ui-menu-item-wrapper,
.select-background .ui-widget-header .ui-menu-item-wrapper,
.select-background a.ui-button:active,
.select-background .ui-button:active,
.select-background .ui-.buttonui-menu-item-wrapper:hover {
  font-weight: bold;
  border: none;
  margin: 0;
}
.select-background .ui-widget.ui-widget-content {
  border-color: #57c5c9;
  border-radius: 5px;
}
.select-background .ui-widget.ui-widget-content {
  width: 70px !important;
}
.select-background .ui-selectmenu-menu.ui-front.ui-selectmenu-open {
  position: static;
}
.select-background .select2-offscreen {
  position: static;
}
.select-button .ui-selectmenu-button.ui-button {
  margin-left: 0px;
}
.select-open .ui-widget.ui-widget-content {
  width: 56px !important;
}
.net.panels .ui-selectmenu-menu.ui-front.ui-selectmenu-open {
  position: fixed !important;
}
.net {
  position: relative;
  height: auto;
  color: #797979;
}
.net .logo {
  padding-bottom: 50px;
}
.net .logo svg,
.net .logo img {
  width: 185px;
}
.net p {
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 0;
  margin-bottom: 10px;
  color: #797979;
}
.net .info,
.net .info * {
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
}
.net .info ul {
  list-style-type: none;
  padding: 0;
}
.net .info ul li {
  color: #1792a8;
  font-size: 18px;
}
.net .info ul a,
.net .info ul a:hover,
.net .info ul a:active,
.net .info ul a:focus,
.net .info ul a:visited {
  text-decoration: none;
}
.net .info .social-media {
  display: inline;
}
.net .info .social-media li {
  display: inline;
}
.net .info .social-media li a img,
.net .info .social-media li a svg {
  width: 28px;
  margin-top: 10px;
  margin-right: 7px;
}
.net h1,
.net h1 * {
  font-size: 40px;
  font-weight: bold;
  line-height: 4rem;
  background: transparent;
  color: #232f5f;
  padding: 0 0 30px;
}
@media (max-width: 375px) {
  .net h1,
  .net h1 * {
    font-size: 36px;
  }
}
@media (max-width: 320px) {
  .net h1,
  .net h1 * {
    font-size: 30px;
  }
}
.net h2,
.net h2 * {
  font-size: 24px;
  background: transparent;
  color: #232f5f;
  padding: 0;
  margin-bottom: 24px;
}
@media (max-width: 320px) {
  .net h2,
  .net h2 * {
    font-size: 21px;
  }
}
.net .nav-bar {
  background-color: #1792a8;
  height: 54px;
  width: 100%;
  position: fixed;
  display: none;
  z-index: 1;
}
@media (max-width: 830px) {
  .net .nav-bar {
    display: block;
  }
  .net .nav-bar a {
    color: #fff;
    text-decoration: none;
  }
}
.net .nav-bar .nav-bar-section {
  position: absolute;
  top: 13px;
  right: 18px;
}
.net .flex-wrapper {
  display: flex;
  flex-direction: row;
}
.net .flex-wrapper .btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  background-color: #f96b69;
  color: #fff;
}
.net .flex-wrapper .left-sidebar {
  width: 670px;
  padding-top: 22px;
  padding-bottom: 22px;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.net .flex-wrapper .left-sidebar .login-content {
  width: 100%;
  max-width: 475px;
  padding: 60px 0;
}
.net .flex-wrapper .left-sidebar .host-content {
  max-width: 509px;
}
.net .flex-wrapper .left-sidebar .host-content .info {
  padding-top: 24px;
}
.net .flex-wrapper .left-sidebar .forms .subtitle-member {
  margin-top: 22px;
}
.net .flex-wrapper .left-sidebar .forms .form-group {
  margin-bottom: 11px;
}
.net .flex-wrapper .left-sidebar .forms .form-group .warning {
  display: none;
  color: #ce8483;
}
.net .flex-wrapper .left-sidebar .forms .input-group {
  margin-bottom: 11px;
  width: 100%;
}
.net .flex-wrapper .left-sidebar .forms .input-col {
  margin: 0;
}
.net .flex-wrapper .left-sidebar .forms .form-control {
  border-color: #d7d7d7;
  height: 40px;
}
.net .flex-wrapper .left-sidebar .forms .form-control.not-valid {
  border-color: #f00 !important;
}
.net .flex-wrapper .left-sidebar .forms .has-error .form-control {
  border-color: #ce8483;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 6px #ce8483;
}
.net .flex-wrapper .left-sidebar .forms .has-error .warning {
  display: block;
}
.net .flex-wrapper .left-sidebar .forms input {
  color: #232f5f;
}
.net .flex-wrapper .left-sidebar .forms input::-webkit-input-placeholder {
  color: #b1b1b1;
  opacity: 1;
}
.net .flex-wrapper .left-sidebar .forms input::-moz-placeholder {
  color: #b1b1b1;
  opacity: 1;
}
.net .flex-wrapper .left-sidebar .forms input:-ms-input-placeholder {
  color: #b1b1b1;
  opacity: 1;
}
.net .flex-wrapper .left-sidebar .forms input:-moz-placeholder {
  color: #b1b1b1;
  opacity: 1;
}
.net .flex-wrapper .left-sidebar .forms .input-group .btn-search button {
  font-weight: bold;
  color: #f96b69;
  background-color: transparent;
  border: 1px solid #d7d7d7;
  border-right-radius: 4px;
  height: 40px;
  text-transform: uppercase;
  width: 96px;
}
.net .flex-wrapper .left-sidebar .forms .search-border {
  font-size: 13px;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  overflow: hidden;
  padding: 15px 35px;
  margin-bottom: 11px;
  border-top: none;
}
.net .flex-wrapper .left-sidebar .forms .search-border input {
  height: 22px;
  font-size: 13px;
  text-align: center;
  background-color: #e9f3f9;
  transition: none;
}
.net .flex-wrapper .left-sidebar .forms .search-border label {
  font-weight: 400;
  color: #b1b1b1;
}
.net .flex-wrapper .left-sidebar .forms .search-border .notrequired {
  background-color: transparent;
}
.net .flex-wrapper .left-sidebar .forms .right-col {
  padding: 0 0 0 11px;
}
@media (max-width: 767px) {
  .net .flex-wrapper .left-sidebar .forms .right-col {
    padding: 0;
  }
}
.net .flex-wrapper .left-sidebar .forms .checkboxes {
  padding-top: 21px;
}
.net .flex-wrapper .left-sidebar .forms .checkboxes .form-group .checkbox label {
  padding: 0;
}
.net .flex-wrapper .left-sidebar .forms .checkboxes .form-group .checkbox label a {
  text-decoration: none;
  color: #797979;
}
.net .flex-wrapper .left-sidebar .forms .checkboxes .form-group .checkbox label a strong {
  padding-left: 4px;
}
.net .flex-wrapper .left-sidebar .forms .checkboxes .form-group .checkbox label > span {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.net .flex-wrapper .left-sidebar .forms .checkboxes .form-group .checkbox input[type=checkbox] {
  opacity: 0;
}
.net .flex-wrapper .left-sidebar .forms .checkboxes .form-group .checkbox input[type=checkbox]+ span::before {
  content: "";
  display: inline-block;
  min-width: 20px;
  height: 20px;
  transition: all 0.3s ease;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #d7d7d7;
  margin-right: 14px;
}
.net .flex-wrapper .left-sidebar .forms .checkboxes .form-group .checkbox input[type=checkbox].not-valid+ span::before {
  border: 1px solid #f00;
}
.net .flex-wrapper .left-sidebar .forms .checkboxes .form-group .checkbox input[type=checkbox]:checked+ span::before {
  content: "";
  display: inline-block;
  min-width: 20px;
  height: 20px;
  transition: all 0.3s ease;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #d7d7d7;
  margin-right: 14px;
}
.net .flex-wrapper .left-sidebar .forms .checkboxes .form-group .checkbox input[type=checkbox]:checked+ span::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  transform: translate(-50%, -50%);
  min-width: 10px;
  min-height: 10px;
  background-color: #1792a8;
  border-radius: 50%;
  transition: all 0.3s ease;
}
.net .flex-wrapper #is-parish-known {
  border: none;
  display: flex;
  column-gap: 20px;
  padding: 0;
  margin: 0;
}
.net .flex-wrapper #is-parish-known legend {
  border: none;
}
.net .flex-wrapper #is-parish-known label {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: fit-content;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}
.net .flex-wrapper #is-parish-known label input {
  margin: 0;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.net .flex-wrapper #is-parish-known label span.mark {
  position: relative;
  height: 16px;
  aspect-ratio: 1;
  padding: 0;
  border: solid 1px currentColor;
  border-radius: 50%;
  background-color: transparent;
}
.net .flex-wrapper #is-parish-known label span.mark:after {
  --size: 50%;
  content: ' ';
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: var(--size);
  height: var(--size);
  background-color: transparent;
  border-radius: inherit;
}
.net .flex-wrapper #is-parish-known label input:checked ~ .mark:after {
  background-color: #57c5c9;
}
.net .flex-wrapper .parish-select {
  margin-top: 30px;
}
.net .flex-wrapper .parish-select.disabled {
  display: none;
}
.net .flex-wrapper .buttons {
  margin-top: 60px;
}
.net .flex-wrapper .buttons .btn-close {
  background-color: transparent;
  border: 1px solid #1792a8;
  color: #1792a8;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  height: 42px;
  width: 116px;
  margin-right: 13px;
}
.net .flex-wrapper .buttons:focus {
  outline-color: #d7d7d7;
}
.net .flex-wrapper .buttons .btn-next {
  background-color: #1792a8;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  height: 42px;
  min-width: 93px;
}
.net .flex-wrapper .behind-background {
  width: calc(100% - 670px);
  flex-grow: 2;
  height: auto;
  background-size: cover;
  background-position: center;
  width: 50%;
}
@media (max-width: 768px) {
  .net .flex-wrapper .behind-background {
    display: none;
  }
}
.net .flex-wrapper .behind-background .right-background-wrapper {
  background-size: cover;
  background-position: center;
  height: 100%;
}
.net .flex-wrapper .behind-background .return-bar {
  position: fixed;
  top: 28px;
  right: 2%;
  z-index: 2;
}
@media (max-width: 830px) {
  .net .flex-wrapper .behind-background .return-bar {
    display: none;
  }
}
@media (max-width: 768px) and (max-width: 1023px) {
  .net .flex-wrapper .behind-background {
    display: none;
  }
}
.net .stage-2 {
  display: none;
}
.net .wizard {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  min-height: 100px;
}
.net .wizard .circle {
  color: #57c5c9;
  font-weight: bold;
  font-size: 30px;
  border: 5px solid #57c5c9;
  line-height: 28px;
  padding: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.2s ease;
}
.net .wizard .circle.active {
  color: #fff;
  background-color: #57c5c9;
  width: 50px;
  height: 50px;
  line-height: 37px;
}
.net .wizard .separator {
  background: #57c5c9;
  width: 44px;
  height: 4px;
  display: block;
  align-self: center;
}
.nopadding {
  padding: 0;
}
.seconary-color {
  color: #57c5c9;
}
.form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(215,215,215,0.6);
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline-color: #1f2a55;
}
.bar {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #1792a8;
  height: 10px;
  width: 100%;
}
header nav {
  font-family: 'Titillium Web';
}
@media (max-width: 1199px) {
  header nav {
    position: fixed;
    z-index: 999999;
    width: 100%;
    background-color: #fff;
  }
}
header nav .main-menu {
  display: flex;
  justify-content: space-around;
}
header nav ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  align-items: center;
  margin: 0;
  height: 105px;
  padding: 0;
}
header nav ul li {
  position: relative;
}
header nav ul li.submenu-visible .submenu {
  display: block;
}
header nav ul li.submenu-visible .submenu .column-wrapper {
  max-height: inherit;
  transition: max-height 0.25s ease-in;
}
@media (max-width: 1199px) and (min-width: 425px) {
  header nav ul li.submenu-visible .submenu .column-wrapper {
    padding-left: 29px;
  }
}
header nav ul li > .active {
  font-weight: bold;
}
header nav ul li .submenu {
  position: absolute;
  top: calc(100% + 20px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 3;
  border-radius: 5px;
  box-shadow: 0 1px 6px 2px rgba(0,0,0,0.05);
  display: none;
}
@media (max-width: 1199px) {
  header nav ul li .submenu {
    display: block;
  }
}
header nav ul li .submenu .close-ico {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
header nav ul li .submenu .close-ico .svg {
  position: relative;
  z-index: 5;
  display: block;
}
header nav ul li .submenu .close-ico::after {
  content: "";
  position: absolute;
  bottom: calc(100% - 9px);
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 18px;
  height: 18px;
  z-index: 0;
  background-color: #fff;
  box-shadow: -2px -2px 6px 0px rgba(0,0,0,0.05);
}
header nav ul li .submenu .close-ico::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 16px;
  z-index: 1;
  background-color: #fff;
}
header nav ul li .submenu.submenu-lg {
  width: 604px;
}
@media (max-width: 1199px) {
  header nav ul li .submenu.submenu-lg {
    width: auto;
  }
}
@media (max-width: 1199px) {
  header nav ul li .submenu.submenu-lg .column-wrapper .column {
    width: auto;
  }
}
@media (max-width: 1850px) {
  header nav ul li .submenu.submenu-lg {
    left: -100px;
    transform: none;
  }
  header nav ul li .submenu.submenu-lg .close-ico {
    left: 132px;
  }
}
header nav ul li .submenu.submenu-md {
  width: 310px;
}
@media (max-width: 1200px) {
  header nav ul li .submenu.submenu-md {
    display: none;
  }
}
@media (max-width: 1199px) {
  header nav ul li .submenu.submenu-md {
    display: block;
  }
}
header nav ul li .submenu.submenu-md .column-wrapper .column {
  width: 100%;
}
header nav ul li .submenu.submenu-sm {
  width: 190px;
}
header nav ul li .submenu.submenu-sm .column-wrapper .column {
  width: 100%;
}
header nav ul li .submenu.submenu-sm .column-wrapper .column .column-items .column-item a {
  padding: 5px 12px;
}
header nav ul li .submenu .column-wrapper {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
header nav ul li .submenu .column-wrapper .column-header {
  font-size: 14px;
  margin: 0 11px 19px;
  padding: 12px 0 15px;
  border-bottom: 1px solid #57c5c9;
  font-weight: bold;
  color: #57c5c9;
}
header nav ul li .submenu .column-wrapper .columns {
  display: flex;
}
@media (max-width: 1199px) {
  header nav ul li .submenu .column-wrapper .columns {
    display: block;
  }
}
header nav ul li .submenu .column-wrapper .column {
  width: 50%;
  padding: 11px;
}
header nav ul li .submenu .column-wrapper .column .column-items {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
header nav ul li .submenu .column-wrapper .column .column-items > .column-item {
  width: 100%;
  margin-bottom: 10px;
}
header nav ul li .submenu .column-wrapper .column .column-items > .column-item:last-child {
  margin-bottom: 0;
}
header nav ul li .submenu .column-wrapper .column .column-items > .column-item > a {
  display: flex;
  flex-direction: row;
  border: 1px solid #1792a8;
  border-radius: 5px;
  align-items: center;
  color: #797979;
  transition: all 0.2s ease;
}
header nav ul li .submenu .column-wrapper .column .column-items > .column-item > a:hover {
  font-weight: normal;
  background-color: #ddfeff;
  color: #1792a8;
}
header nav ul li .submenu .column-wrapper .column .column-items > .column-item > a > .img-wrapper {
  width: 70px;
  padding: 20px 12px 25px;
  position: relative;
  text-align: center;
}
header nav ul li .submenu .column-wrapper .column .column-items > .column-item > a > .img-wrapper::before {
  content: "";
  left: 0;
  top: 0;
  width: 1px;
  height: 58px;
  background-color: #57c5c9;
  position: absolute;
}
header nav ul li .submenu .column-wrapper .column .column-items > .column-item > a > .img-wrapper .svg {
  height: 34px;
}
header nav ul li .submenu .column-wrapper .column .column-items > .column-item > a > span {
  display: inline-block;
  width: calc(100% - 70px);
  padding: 0 12px 0 19px;
  line-height: 17px;
}
@media (min-width: 1200px) {
  header nav ul li .submenu .column-wrapper .column .column-items > .column-item > .active {
    font-weight: normal;
    background-color: #ddfeff;
    color: #1792a8;
  }
}
@media (max-width: 1199px) {
  header nav ul li .submenu .column-wrapper .column .column-items > .column-item > .active {
    font-weight: bold;
  }
}
header nav ul.main-menu li {
  padding-right: 0px;
}
header nav ul.main-menu li a {
  font-size: 15px;
  color: #1792a8;
  text-transform: lowercase;
  text-decoration: none;
}
header nav ul.main-menu li:last-child {
  padding-right: 0;
}
@media (min-width: 1200px) {
  header nav ul.main-menu li.md-show {
    display: none;
  }
}
@media (min-width: 531px) {
  header nav ul.main-menu li.sm-show {
    display: none;
  }
}
@media (max-width: 1024px) {
  header nav ul.main-menu li.xs-hide {
    display: none;
  }
}
@media (min-width: 1025px) {
  header nav ul.main-menu li.xs-show {
    display: none;
  }
}
@media (max-width: 1199px) {
  header nav ul.main-menu {
    position: fixed;
    z-index: 2;
    top: 90px;
    left: 0;
    width: 100%;
    height: calc(100vh - 90px);
    background-color: #fff;
    flex-direction: column;
    align-items: left;
    padding: 0px 22px 0px;
    overflow-y: auto;
    display: none;
  }
  header nav ul.main-menu .ui-selectmenu-button.ui-button {
    float: none;
    margin: 0;
  }
  header nav ul.main-menu li {
    line-height: normal;
    margin: 35px 0;
    width: 100% !important;
  }
  header nav ul.main-menu .submenu {
    box-shadow: none;
    position: static;
    transform: none;
  }
  header nav ul.main-menu .submenu .close-ico {
    display: none;
  }
  header nav ul.main-menu .submenu.submenu-md {
    width: 100%;
  }
  header nav ul.main-menu .submenu.submenu-md .column-wrapper .column {
    width: 100%;
  }
  header nav ul.main-menu .submenu.submenu-sm .column-wrapper .column .column-items .column-item a {
    padding: 23px 0 0 0;
  }
  header nav ul.main-menu .submenu .column-wrapper {
    padding: 0;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
  }
  header nav ul.main-menu .submenu .column-wrapper .column-header {
    padding: 26px 0 0 0;
    margin: 0;
  }
  header nav ul.main-menu .submenu .column-wrapper .column {
    padding: 0;
  }
  header nav ul.main-menu .submenu .column-wrapper .column .column-items > .column-item {
    margin: 0;
  }
  header nav ul.main-menu .submenu .column-wrapper .column .column-items > .column-item > a {
    border: none;
  }
  header nav ul.main-menu .submenu .column-wrapper .column .column-items > .column-item > a:hover {
    background-color: #fff;
    color: #797979;
    font-weight: bold;
  }
  header nav ul.main-menu .submenu .column-wrapper .column .column-items > .column-item > a > .img-wrapper {
    display: none;
  }
  header nav ul.main-menu .submenu .column-wrapper .column .column-items > .column-item > a > .img-wrapper::before {
    content: "";
  }
  header nav ul.main-menu .submenu .column-wrapper .column .column-items > .column-item > a > .img-wrapper > img {
    max-width: 100%;
  }
  header nav ul.main-menu .submenu .column-wrapper .column .column-items > .column-item > a > span {
    padding: 23px 0 0 0;
    width: 100%;
  }
}
@media (max-width: 1199px) and (max-height: 599px) {
  header nav ul.main-menu li {
    margin: 26px 0;
  }
}
@media (max-width: 1199px) and (max-width: 1199px) {
  header nav ul.main-menu .column-wrapper {
    width: fit-content;
  }
}
header nav ul.actions-menu {
  flex-direction: row-reverse;
  display: flex;
}
@media (min-width: 1200px) {
  header nav ul.actions-menu {
    justify-content: space-around;
  }
}
header nav ul.actions-menu li a {
  color: #1792a8;
  text-decoration: none;
}
header nav ul.actions-menu li a.btn-normal {
  padding: 0 15px;
  line-height: 20px;
  border: 1px solid #57c5c9;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.15s ease;
  font-size: 15px;
}
header nav ul.actions-menu li a.btn-normal:hover {
  color: #fff;
  border: 1px solid #57c5c9;
  background-color: #57c5c9;
}
header nav ul.actions-menu li .btn-join-project {
  background-color: #1792a8;
  color: #fff;
  border: 1px solid #1792a8 !important;
}
header nav ul.actions-menu li .btn-join-project:hover {
  border: 1px solid #57c5c9 !important;
}
header nav ul.actions-menu li.mobile-menu-toggler {
  flex-direction: column;
  justify-content: space-between;
  height: 14px;
  width: 21px;
  cursor: pointer;
  display: none;
}
@media (max-width: 600px) and (min-width: 513px) {
  header nav ul.actions-menu li.mobile-menu-toggler {
    margin-left: 0px;
  }
}
header nav ul.actions-menu li.mobile-menu-toggler span {
  width: 100%;
  height: 2px;
  background-color: #1792a8;
}
@media (max-width: 1199px) {
  header nav ul.actions-menu li.mobile-menu-toggler {
    display: flex;
  }
}
@media (max-width: 1199px) {
  header nav ul.actions-menu li.md-hide {
    display: none;
  }
}
@media (max-width: 530px) {
  header nav ul.actions-menu li.sm-hide {
    display: none;
  }
}
header nav .logo-wrapper {
  padding: 15px 22px 0;
}
@media (max-width: 1199px) {
  header nav .logo-wrapper {
    padding: 2px 22px 0;
  }
}
header nav .logo-wrapper .logo {
  display: inline-block;
  height: 90px;
  display: flex;
  justify-content: center;
}
@media (max-width: 1200px) {
  header nav .logo-wrapper .logo {
    justify-content: flex-start;
  }
}
@media (max-width: 1199px) {
  header nav .logo-wrapper .logo {
    width: 150px;
  }
}
@media (min-width: 1200px) {
  header nav .logo-wrapper .logo {
    width: 174px;
  }
}
@media (max-width: 1199px) {
  header nav .logo-wrapper .logo-lrg {
    display: none;
  }
}
header nav .logo-wrapper .logo-lrg img {
  width: 150px;
  height: 90px;
}
@media (min-width: 1200px) {
  header nav .logo-wrapper .logo-small {
    display: none;
  }
}
header nav .logo-wrapper .logo-small img {
  width: 174px;
  height: 90px;
}
header .decoration {
  position: relative;
  width: 222px;
  height: 15px;
  margin: auto;
  background-color: #fff;
  margin-bottom: -13px;
  z-index: 2;
}
@media (max-width: 1199px) {
  header .decoration {
    display: none;
  }
}
header .decoration::before {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  bottom: 0px;
  right: 98%;
  width: 93px;
  transform: rotateX(180deg) rotate(180deg);
  background-image: url("../img/decoration-right.png");
}
header .decoration::after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  bottom: 0px;
  left: 98%;
  width: 93px;
  background-image: url("../img/decoration-right.png");
}
.menu-open nav ul.main-menu {
  display: block;
}
@media (min-width: 1200px) {
  .menu-open nav ul.main-menu {
    display: flex;
  }
}
.menu-open nav ul.actions-menu li.mobile-menu-toggler span {
  transition: 0.3s transform linear;
  transform-origin: center;
}
.menu-open nav ul.actions-menu li.mobile-menu-toggler span:first-child {
  transform: rotate(45deg) translateY(8px);
}
.menu-open nav ul.actions-menu li.mobile-menu-toggler span:nth-child(2) {
  display: none;
}
.menu-open nav ul.actions-menu li.mobile-menu-toggler span:nth-child(3) {
  transform: rotate(-45deg) translateY(-8px);
}
.menu-open nav .logo-wrapper .logo-small {
  display: none;
}
@media (max-width: 599px) {
  .menu-open nav .actions-menu {
    height: 58px;
  }
}
@media (max-width: 1199px) and (min-width: 426px) {
  .menu-open nav ul.main-menu {
    padding: 0 22px 0 100px;
  }
}
@media (max-width: 599px) {
  .menu-open nav ul.main-menu {
    top: 59px;
    height: calc(100vh - 42px);
  }
}
@media (max-width: 425px) {
  .menu-open nav ul.main-menu {
    padding: 0 22px;
  }
}
.btn,
.navbar-btn {
  text-transform: uppercase;
  border-width: 1px;
  font-weight: 700 !important;
  background-image: none;
  font-family: 'Titillium Web', sans-serif;
}
.btn-background {
  background-image: none;
  background-color: transparent;
}
.btn-background:focus {
  background-image: none;
  background-color: transparent;
}
.btn-white {
  color: #1792a8;
  background-color: #fff;
  transition: background-color 0.3s ease-out;
}
.btn-white:hover {
  color: #1792a8;
  color: #fff;
  background-color: #57c5c9;
}
.btn-white:focus {
  color: #fff;
  background-color: #57c5c9;
}
.button-nav {
  margin-top: 34px;
}
.btn-log {
  width: 96px;
  border-color: #a8d1e7;
}
.btn-join {
  width: 153px;
  border-color: #a8d1e7;
  margin-left: 13px;
}
.btn-join-project {
  background-color: #1792a8;
  color: #fff;
}
.btn,
.btn-default,
.navbar-btn,
.floatleft,
.button-nav {
  height: 20px;
}
.btn-send {
  background-color: #1792a8;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  height: 42px;
  padding-left: 20px;
  padding-right: 20px;
}
.btn-send:hover {
  color: #fff;
}
.btn-send:focus {
  color: #fff;
}
.btn-wide {
  width: 100%;
  white-space: normal;
  height: auto;
}
.btn-play {
  font-size: 18px;
  height: 42px;
  display: block;
  margin-top: 50px;
  border: 1px solid #57c5c9;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
@media (max-width: 425px) {
  .btn-play {
    font-size: 16px;
  }
}
.btn-play img,
.btn-play svg {
  display: inline-block;
  margin-right: 12px;
  height: 12px;
  width: 10px;
  fill: #1792a8;
}
.btn-play:hover svg {
  fill: #fff;
}
@media (max-width: 425px) {
  .btn-steps {
    font-size: 12px;
  }
}
.btn-circle-play {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease-out;
}
.btn-circle-play:hover {
  background-color: #6cd2d6;
}
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
.btn-circle-play {
  outline-color: transparent;
}
.btn-standard {
  height: 42px;
  font-size: 18px;
  color: #232f5f;
  text-transform: lowercase;
  background-color: #fff;
  margin-top: 50px;
  padding: 6px 14px;
}
.btn-standard::first-letter {
  text-transform: uppercase;
}
.btn-films-play {
  height: 34px;
  width: 27px;
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.3s ease-out;
  transition: fill 0.3s ease-out;
}
header .ui-selectmenu-button.ui-button {
  font-family: 'Titillium Web', sans-serif;
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 15px;
  line-height: 21px;
  padding: 0 15px;
  width: 70px;
  text-transform: uppercase;
}
header .ui-selectmenu-button.ui-button:focus,
header .ui-selectmenu-button.ui-button:hover {
  color: #1792a8;
  color: #fff;
  background-color: #57c5c9;
}
header .ui-selectmenu-button.ui-button:focus .ui-icon,
header .ui-selectmenu-button.ui-button:hover .ui-icon {
  transform: rotate(180deg);
  background-image: url("../img/select-arrow.png");
  background-position: center;
  transition: transform 0.4s ease;
}
header .ui-selectmenu-button.ui-button .ui-icon {
  background-image: url("../img/green-arrow.png");
  background-position: center;
  transition: transform 0.4s ease;
}
header .ui-selectmenu-button.ui-button:focus {
  outline-color: #fff;
}
header .ui-selectmenu-button.ui-button:focus .ui-selectmenu-text {
  height: 18px;
  width: 18px;
}
header .ui-selectmenu-button.ui-button:focus:focus {
  outline-color: #fff;
}
header .ui-selectmenu-button.ui-button.ui-selectmenu-button-open .ui-icon {
  transform: rotate(180deg);
  background-image: url("../img/select-arrow.png");
  background-position: center;
  transition: transform 0.4s ease;
}
header .ui-selectmenu-button.ui-button {
  background: transparent;
  color: #1792a8;
  border: 1px solid #a8d1e7;
}
header .btn-return {
  color: #1792a8;
  background: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
  height: 22px;
  width: 167px;
}
header .btn-return:focus {
  outline-color: #fff;
}
header .ui-selectmenu-menu.ui-front.ui-selectmenu-open {
  top: 50px;
  left: 1775.7px;
  position: fixed;
  right: 70px;
  z-index: 2;
}
header .ui-selectmenu-menu.ui-front.ui-selectmenu-open .ui-menu.ui-corner-bottom.ui-widget.ui-widget-content.overflow {
  border-radius: 3px;
}
header .ui-state-active,
header .ui-widget-content .ui-state-active,
header .ui-widget-header .ui-state-active,
header a.ui-button:active,
header .ui-button:active,
header .ui-button.ui-state-active:hover {
  background: #57c5c9;
  border: 1px solid #a8d1e7;
  color: #fff;
}
header .select2-selection.select2-selection--single {
  background-color: #fff;
}
header .select2-offscreen {
  position: fixed !important;
}
header .select2-container .select2-selection--single {
  height: 40px;
}
header .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent;
  border-width: 5px;
  transform: rotate(-180deg);
}
header .select2-container--default .select2-selection--single {
  border: 1px solid #ccc;
  border-radius: 4px;
}
header .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px;
  padding-left: 35px;
  color: #232f5f;
}
header .select2-container--default .select2-selection--single:focus {
  outline-color: #d7d7d7;
}
header .select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 94px;
  border-left: 1px solid #ccc;
  height: calc(100% - 1px);
}
header .select2-container--default .select2-selection--single .select2-selection__arrow b {
  background-image: url("../img/form-arrow.png");
  background-position: center;
  background-repeat: no-repeat;
  border-color: transparent;
  border-style: solid;
  border-width: 5px;
  height: 6px;
  left: 50%;
  margin-left: -4px;
  margin-top: -4px;
  position: absolute;
  top: 50%;
  width: 9px;
  transition: transform 0.4s ease;
}
header .select2-container--default .select2-results__option[aria-disabled=true] {
  display: none;
}
header .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f0f0f0;
  color: #bcbcbc;
}
header .select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f0f0f0;
}
header .select2-results__options li {
  padding-left: 35px;
  color: #bcbcbc;
}
header .select2-container--default .select2-search--dropdown .select2-search__field {
  outline-color: #d7d7d7;
}
.btn-reset {
  padding: 6px 50px;
}
.btn-popup {
  font-family: 'Titillium Web', sans-serif;
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  background-color: #fff;
  border: 1px #1792a8 solid;
  text-decoration: none;
  color: #1792a8;
  height: 32px;
  padding: 8px 32px;
}
.btn-popup:hover,
.btn-popup:focus {
  color: #1792a8;
}
.btn-tiles {
  border: 1px solid #1792a8;
  border-radius: 5px;
  background-color: #fff;
  min-height: 132px;
  transition: all 500ms ease;
  color: #1792a8;
}
.btn-tiles:hover,
.btn-tiles:focus {
  background-color: #1792a8;
  transition: all 500ms ease;
  color: #fff;
}
.btn-green {
  font-family: 'Titillium Web', sans-serif;
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
  background-color: #57c5c9;
  border: 1px #57c5c9 solid;
  text-decoration: none;
  color: #fff;
  height: 32px;
  transition: all 300ms ease;
}
.btn-green:hover,
.btn-green:focus {
  color: #57c5c9;
  background-color: #fff;
  transition: all 300ms ease;
}
.btn-next {
  background-color: #1792a8;
  color: #fff;
  font-size: 18px;
  text-transform: uppercase;
  height: 42px;
  min-width: 93px;
}
.btn-next:hover,
.btn-next:focus {
  color: #fff;
}
.btn-light {
  background-color: transparent;
  border: 1px solid #1792a8;
  color: #1792a8;
  font-weight: 700;
  text-transform: uppercase;
  height: auto;
  transition: all 300ms ease;
}
.btn-light:hover {
  color: #fff;
  background-color: #1792a8;
  transition: all 300ms ease;
}
.btn-light:focus {
  color: #fff;
  background-color: #1792a8;
  transition: all 300ms ease;
}
.btn-blue {
  background-color: #1792a8;
  border: 1px solid #1792a8;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  height: auto;
  transition: all 300ms ease;
}
.btn-blue:hover {
  color: #1792a8;
  background-color: #fff;
  transition: all 300ms ease;
}
.btn-blue:focus {
  color: #1792a8;
  background-color: #fff;
  transition: all 300ms ease;
}
#send-mail-popup {
  font-family: 'Titillium Web', sans-serif;
  color: #232f5f;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(87,197,201,0.77);
  z-index: 999999999;
  display: none;
}
#send-mail-popup .popup-wrapper {
  position: absolute;
  background-color: #fff;
  top: 2%;
  height: 96%;
  width: 700px;
  max-width: 90%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10;
  overflow-y: auto;
}
#send-mail-popup .popup-wrapper .nano-content {
  padding: 50px 50px 0;
}
#send-mail-popup .popup-wrapper h1 {
  margin-bottom: 35px;
  font-size: 24px;
  font-weight: bold;
}
#send-mail-popup .popup-wrapper label {
  display: block;
  margin-bottom: 8px;
}
#send-mail-popup .popup-wrapper input {
  width: 100%;
  margin-bottom: 5px;
}
#send-mail-popup .popup-wrapper input.has-error input {
  border: 1px solid #e89999;
  background-color: #ffeded;
}
#send-mail-popup .popup-wrapper .content-message {
  margin-top: 45px;
}
#send-mail-popup .popup-wrapper .content-message .content {
  padding: 22px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
  margin-bottom: 50px;
}
#send-mail-popup .popup-wrapper .content-message .content h3:last-child {
  margin-top: 50px;
}
#send-mail-popup .popup-wrapper .content-message .content img {
  max-width: 100%;
  margin-bottom: 40px;
}
#send-mail-popup .popup-wrapper .content-message .content h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}
#send-mail-popup .popup-wrapper .content-message .content h3 strong {
  font-weight: bold;
}
#send-mail-popup .popup-wrapper .content-message .content p {
  margin-bottom: 45px;
}
#send-mail-popup .popup-wrapper .btn-light {
  font-size: 13px;
  min-width: 174px;
  margin-bottom: 30px;
}
#send-mail-popup .popup-wrapper textarea {
  display: none;
}
#homepage .what-is {
  position: relative;
  overflow: hidden;
}
#homepage .what-is .content {
  border-top-right-radius: 7px;
  padding-top: 120px;
  padding-bottom: 120px;
  position: relative;
  z-index: 2;
}
@media (max-width: 1024px) {
  #homepage .what-is .content {
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  #homepage .what-is .content {
    padding-top: 50px;
  }
}
@media (max-width: 1024px) {
  #homepage .what-is .content {
    padding-bottom: 80px;
  }
}
@media (max-width: 768px) {
  #homepage .what-is .content {
    padding-bottom: 50px;
  }
}
#homepage .what-is .content .front {
  position: relative;
  z-index: 1;
}
#homepage .what-is .content h3 {
  color: #232f5f;
}
#homepage .what-is .content p {
  color: #797979;
}
#homepage .what-is .content a {
  text-decoration: none;
}
#homepage .what-is .content a button {
  margin-bottom: 25px;
  margin-top: 60px;
}
@media (max-width: 425px) {
  #homepage .what-is .content a button img {
    display: none;
  }
}
#homepage .what-is .content::after {
  content: "";
  position: absolute;
  right: -50vw;
  display: block;
  background-color: #fff;
  top: 0;
  height: 100%;
  width: 150vw;
  z-index: 0;
  border: 2px solid #fff;
  border-top-right-radius: 8px;
}
@media (min-width: 1024px) {
  #homepage .what-is .content::after {
    right: 8.33%;
  }
}
#homepage .what-is .content .app img,
#homepage .what-is .content .app:first-child {
  margin-right: 14px;
}
@media (max-width: 347px) {
  #homepage .what-is .content .app img,
  #homepage .what-is .content .app:first-child {
    margin-bottom: 14px;
  }
}
@media (max-width: 991px) {
  #homepage .what-is .img-right {
    text-align: center;
  }
}
#homepage .what-is .img-right img {
  max-width: 100%;
  height: auto;
  transform: translateX(25px);
}
@media (max-width: 991px) {
  #homepage .what-is .img-right img {
    transform: none;
    margin-top: 50px;
  }
}
.how-work-newpastoral {
  background-color: #f5f5f5;
  padding-top: 558px;
  margin-top: -500px;
  padding-bottom: 120px;
}
@media (max-width: 320px) {
  .how-work-newpastoral {
    margin-top: -387px;
  }
}
@media (max-width: 1024px) {
  .how-work-newpastoral {
    padding-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .how-work-newpastoral {
    padding-bottom: 50px;
  }
}
.how-work-newpastoral h3 {
  text-align: right;
  color: #232f5f;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
}
.how-work-newpastoral h3::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #fefefe;
  height: 10px;
  bottom: 10px;
  right: 112%;
  width: 693px;
}
.news {
  margin: 0 auto 50px;
}
.news h3 {
  color: #232f5f;
}
.news .module-wrapper {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 1200px) {
  .news .module-wrapper {
    justify-content: space-between;
  }
}
.news .module-wrapper .single-news-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  max-width: 545px;
  margin-bottom: 50px;
}
@media (min-width: 768px) {
  .news .module-wrapper .single-news-wrapper {
    width: 545px;
  }
}
.news .module-wrapper .single-news-wrapper .news-image {
  height: 350px;
  width: 100%;
  z-index: 10;
  border-radius: 20px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .news .module-wrapper .single-news-wrapper .news-image {
    height: 600px;
    width: 545px;
  }
}
.news .module-wrapper .single-news-wrapper .info-wrapper {
  width: 100%;
}
.news .module-wrapper .single-news-wrapper p.news-date {
  font-size: 14px;
  line-height: 24px;
  color: #232f5f;
  font-family: 'Titillium Web', sans-serif;
  margin: 0 !important;
}
.news .module-wrapper .single-news-wrapper h5.news-title {
  font-size: 30px;
  margin: 0 !important;
  line-height: 38px;
  color: #232f5f;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
}
.news .module-wrapper .single-news-wrapper p.news-short-description {
  font-size: 18px;
  line-height: 24px;
  color: #232f5f;
  margin-top: 20px !important;
  font-family: 'Titillium Web', sans-serif;
}
.news .module-wrapper a {
  text-decoration: none;
  width: 545px;
}
.news .module-wrapper a button.btn-news {
  border-width: 2px;
  font-size: 18px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  border-radius: 4px;
  text-transform: none;
  text-decoration: none;
  margin-top: 0 !important;
}
.news .module-wrapper a button.btn-news:first-letter {
  text-transform: uppercase;
}
.newsletter {
  color: #fdfdfd;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  height: 725px;
  margin-top: 120px;
  margin-bottom: 50px;
}
@media (min-width: 1440px) {
  .newsletter {
    margin: 150px 60px 50px;
  }
}
.newsletter .blend {
  height: 100%;
  width: 100%;
  background-color: rgba(35,47,95,0.88);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 992px) {
  .newsletter .blend {
    flex-direction: row;
    justify-content: space-between;
  }
}
.newsletter .blend .left-half {
  width: 100%;
  height: 50%;
}
@media (min-width: 992px) {
  .newsletter .blend .left-half {
    width: 50%;
    height: 100%;
  }
}
.newsletter .blend .left-half .content-wrapper-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 50px;
  height: 100%;
}
@media (min-width: 992px) {
  .newsletter .blend .left-half .content-wrapper-left {
    padding-left: 20%;
    padding-right: 50px;
  }
}
@media (min-width: 1200px) {
  .newsletter .blend .left-half .content-wrapper-left {
    padding-left: 37%;
    padding-right: 50px;
  }
}
.newsletter .blend .left-half .content-wrapper-left .pictogram-wrapper {
  width: 56px;
  height: 59px;
  margin-bottom: 20px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
@media (min-width: 992px) {
  .newsletter .blend .left-half .content-wrapper-left .pictogram-wrapper {
    width: 112px;
    height: 118px;
    margin-bottom: 0px;
  }
}
@media (min-width: 1920px) {
  .newsletter .blend .left-half .content-wrapper-left .pictogram-wrapper {
    margin-bottom: 40px;
  }
}
.newsletter .blend .left-half .content-wrapper-left .header-wrapper h3.newsletter-header {
  font-size: 25px;
  line-height: 30px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  color: #fff;
  margin-bottom: 40px;
}
@media (min-width: 576px) {
  .newsletter .blend .left-half .content-wrapper-left .header-wrapper h3.newsletter-header {
    font-size: 43px;
  }
}
@media (min-width: 992px) {
  .newsletter .blend .left-half .content-wrapper-left .header-wrapper h3.newsletter-header {
    font-size: 60px;
    line-height: 69px;
  }
}
.newsletter .blend .left-half .content-wrapper-left .description-wrapper {
  padding-bottom: 20px;
}
@media (min-width: 992px) {
  .newsletter .blend .left-half .content-wrapper-left .description-wrapper {
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .newsletter .blend .left-half .content-wrapper-left .description-wrapper {
    padding-bottom: 50px;
  }
}
.newsletter .blend .left-half .content-wrapper-left .description-wrapper p.newsletter-desc {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Titillium Web', sans-serif;
  color: #fff;
}
@media (min-width: 992px) {
  .newsletter .blend .left-half .content-wrapper-left .description-wrapper p.newsletter-desc {
    font-size: 18px;
    line-height: 24px;
  }
}
.newsletter .blend .right-half {
  width: 100%;
  height: 50%;
  background-color: #232f5f;
}
@media (min-width: 992px) {
  .newsletter .blend .right-half {
    width: 50%;
    height: 100%;
  }
}
.newsletter .blend .right-half .content-wrapper-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px 20px 20px 50px;
}
@media (min-width: 992px) {
  .newsletter .blend .right-half .content-wrapper-right {
    padding-left: 11%;
    padding-right: 20%;
  }
}
@media (min-width: 1200px) {
  .newsletter .blend .right-half .content-wrapper-right {
    padding-left: 11%;
    padding-right: 47%;
  }
}
.newsletter .blend .right-half .content-wrapper-right .input-with-description p.input {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  color: #fff;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .newsletter .blend .right-half .content-wrapper-right .input-with-description p.input {
    font-size: 18px;
    line-height: 24px;
  }
}
.newsletter .blend .right-half .content-wrapper-right .input-with-description form input {
  border: #6cd2d6 3px solid;
  background: none;
  font-size: 18px;
  border-radius: 4px;
  line-height: 24px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  color: #6cd2d6;
  width: 290px;
  height: 42px;
  padding-left: 35px;
  outline: none;
  cursor: pointer;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .newsletter .blend .right-half .content-wrapper-right .input-with-description form input {
    margin-bottom: 60px;
  }
}
.newsletter .blend .right-half .content-wrapper-right .input-with-description form input:active {
  border: #6cd2d6 3px solid;
}
@media (min-width: 992px) {
  .newsletter .blend .right-half .content-wrapper-right .input-with-description form input {
    width: 370px;
    height: 42px;
  }
}
.newsletter .blend .right-half .content-wrapper-right .input-with-description .recaptcha-wrapper button,
.newsletter .blend .right-half .content-wrapper-right .input-with-description .signing-button button {
  background-color: #6cd2d6;
  transition: color 0.3s ease-out, background 0.3s ease-out, border 0.3s ease-out;
  border-radius: 8px;
  color: #232f5f;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  padding: 10px 30px;
  border-color: #232f5f;
  outline: none;
}
.newsletter .blend .right-half .content-wrapper-right .input-with-description .recaptcha-wrapper button:hover,
.newsletter .blend .right-half .content-wrapper-right .input-with-description .signing-button button:hover {
  color: #6cd2d6;
  background-color: #232f5f;
  border-color: #6cd2d6;
}
.newsletter .people {
  height: 30px;
  width: 100%;
  position: absolute;
  bottom: -10px;
  overflow: hidden;
  left: 0;
  transform: scale(1.2);
}
@media (min-width: 600px) {
  .newsletter .people {
    height: 40px;
  }
}
@media (min-width: 992px) {
  .newsletter .people {
    height: 60px;
  }
}
@media (min-width: 1440px) {
  .newsletter .people {
    height: 85px;
    transform: scale(1.2);
  }
}
.newsletter-popup {
  display: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  z-index: 100000;
  background-color: rgba(130,136,163,0.65);
}
.newsletter-popup .popup-blend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.newsletter-popup .popup-blend .popup-content {
  width: 100%;
  height: 300px;
  background-color: #fff;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .newsletter-popup .popup-blend .popup-content {
    width: 655px;
    height: 390px;
  }
}
.newsletter-popup .popup-blend .popup-content img {
  width: 46px;
  height: 46px;
  margin: 0 auto;
}
.newsletter-popup .popup-blend .popup-content h3 {
  margin: 0 auto 30px;
  text-align: center;
  color: #232f5f;
  font-size: 30px;
  padding: 0 30px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  padding: 10px 30px;
}
@media (min-width: 768px) {
  .newsletter-popup .popup-blend .popup-content h3 {
    font-size: 48px;
    line-height: 69px;
    padding: 10px 120px;
  }
}
.newsletter-popup .popup-blend .popup-content p {
  margin: 0 auto;
  color: #232f5f;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: light;
  text-align: center;
  padding: 0 30px;
}
@media (min-width: 768px) {
  .newsletter-popup .popup-blend .popup-content p {
    font-size: 24px;
    line-height: 28px;
    padding: 0 120px;
  }
}
#contact {
  color: #232f5f;
}
#contact #contact-data {
  padding-bottom: 80px;
}
@media (max-width: 768px) {
  #contact #contact-data {
    padding-bottom: 50px;
  }
}
#contact #contact-data .contact-header {
  height: 42px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  #contact #contact-data .contact-header {
    margin-top: 40px;
  }
}
#contact #contact-data .contact-header * {
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
#contact ul {
  list-style-type: none;
  padding: 0;
}
#contact ul li {
  color: #1792a8;
  font-size: 18px;
}
#contact ul a,
#contact ul a:hover,
#contact ul a:active,
#contact ul a:focus,
#contact ul a:visited {
  text-decoration: none;
}
#contact .social-media {
  display: inline;
}
#contact .social-media li {
  display: inline;
}
#contact .social-media li a img,
#contact .social-media li a svg {
  width: 28px;
  margin-top: 10px;
  margin-right: 7px;
}
#contact #contact-form .form-col {
  padding-bottom: 60px;
}
#contact #contact-form .form-col .form-group {
  margin-bottom: 11px;
}
#contact #contact-form .form-col .form-group label {
  font-weight: 400;
}
#contact #contact-form .form-col .form-control {
  border-color: #d7d7d7;
  height: 37px;
}
#contact #contact-form .form-col .form-message {
  height: 100px;
}
#contact #contact-form .form-col input {
  color: #232f5f;
}
#contact #contact-form .form-col input::-webkit-input-placeholder {
  color: #232f5f;
  opacity: 1;
}
#contact #contact-form .form-col input::-moz-placeholder {
  color: #232f5f;
  opacity: 1;
}
#contact #contact-form .form-col input:-ms-input-placeholder {
  color: #232f5f;
  opacity: 1;
}
#contact #contact-form .form-col input:-moz-placeholder {
  color: #232f5f;
  opacity: 1;
}
#contact #contact-form .form-col .form-control:focus {
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(215,215,215,0.6);
  border-color: #d7d7d7;
}
#contact #contact-form .form-col .form-header {
  margin-bottom: 40px;
}
#contact #contact-form .form-col .form-message {
  color: #232f5f;
  max-height: 110px;
  min-height: 110px;
  max-width: 100%;
}
#contact #contact-form .form-col button {
  margin-top: 40px;
}
#subpage-how {
  color: #232f5f;
}
#subpage-how #points {
  padding-bottom: 80px;
}
#subpage-how #points a {
  text-decoration: none;
  color: #232f5f;
  transition: background-color 0.3s ease-out;
}
#subpage-how #points a .points-row {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
#subpage-how #points a .points-row .points-paragraph {
  transition: font-weight 0.3s ease-out;
}
#subpage-how #points a .points-row:hover .points-paragraph {
  font-weight: bold;
}
#subpage-how #points a .points-row:hover .wheel {
  background-color: #1792a8;
}
#subpage-how #points a .points-row .wheel {
  height: 51px;
  width: 51px;
  background-color: #57c5c9;
  border-radius: 50%;
  transition: background-color 0.3s ease-out;
}
#subpage-how #points a .points-row .wheel .wheel-number {
  color: #fff;
  font-size: 33.87px;
  text-align: center;
  font-weight: bold;
}
#subpage-how .ground section:nth-child(odd) {
  background-color: #f5f5f5;
}
#subpage-how .point {
  padding-top: 80px;
  padding-bottom: 80px;
}
#subpage-how .point .point-row {
  margin-bottom: 30px;
  display: flex;
}
#subpage-how .point .point-row .roundel {
  height: 51px;
  width: 51px;
  background-color: #fff;
  border: 2px solid #57c5c9;
  border-radius: 50%;
  margin-top: 10px;
}
#subpage-how .point .point-row .roundel .roundel-number {
  color: #57c5c9;
  font-size: 33.87px;
  text-align: center;
  font-weight: bold;
}
#subpage-how .point a {
  text-decoration: none;
}
#subpage-how .point a .btn-point {
  font-size: 13px;
  height: 32px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 33px;
}
#subpage-how .point .point-img {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
#subpage-how .point .point-img img {
  max-height: 640px;
}
#subpage-how .point .social-media-wrapper {
  padding: 0;
  list-style-type: none;
  margin: 0 auto;
  display: block;
  text-align: center;
}
#subpage-how .point .social-media-wrapper li {
  margin: 0 8px;
  display: inline;
}
#subpage-how .point .social-media-wrapper li a img,
#subpage-how .point .social-media-wrapper li a svg {
  width: 64px;
}
#subpage-how .how-works .steps-line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
#subpage-how .how-works .steps-line image {
  width: 100%;
}
#instructional-film .film-header {
  font-weight: bold;
  margin-bottom: 50px;
}
#instructional-film .short-film {
  position: relative;
}
#instructional-film .short-film button {
  position: absolute;
  left: 50%;
  bottom: 50%;
}
#support {
  color: #232f5f;
}
#support .support-content {
  padding-bottom: 120px;
}
@media (max-width: 1024px) {
  #support .support-content {
    padding-bottom: 80px;
  }
}
@media (max-width: 768px) {
  #support .support-content {
    padding-bottom: 50px;
  }
}
#support .support-content .support-paragraph {
  margin-bottom: 50px;
  line-height: 30px;
}
#support .support-content .support-header {
  margin-top: 60px;
  margin-bottom: 50px;
}
@media (max-width: 326px) {
  #support .support-content .support-header {
    font-size: 23px;
  }
}
#support .support-content .link-pay,
#support .support-content button {
  display: inline-block;
  padding: 0 20px;
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 0.3s ease-out;
}
@media (max-width: 768px) {
  #support .support-content .link-pay,
  #support .support-content button {
    height: 150px;
  }
}
@media (max-width: 425px) {
  #support .support-content .link-pay,
  #support .support-content button {
    height: 140px;
  }
}
@media (max-width: 375px) {
  #support .support-content .link-pay,
  #support .support-content button {
    height: 130px;
  }
}
@media (max-width: 320px) {
  #support .support-content .link-pay,
  #support .support-content button {
    height: 120px;
  }
}
@media (max-width: 767px) {
  #support .support-content .link-pay,
  #support .support-content button {
    margin-bottom: 25px;
  }
}
#support .support-content .link-pay:hover,
#support .support-content button:hover {
  background-color: #f5f5f5;
  border: 1px solid #232f5f;
}
#excerption {
  margin-top: -35px;
}
#excerption .citation {
  background-color: #57c5c9;
  padding-bottom: 95px;
  padding-top: 55px;
  position: relative;
}
#excerption .citation::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 435px;
  background-color: #57c5c9;
}
#excerption .citation .bg-img {
  position: absolute;
  top: 15px;
  right: 0;
  height: 500px;
  z-index: 2;
}
#excerption .citation .quote {
  border: none !important;
  padding: 0;
  margin: 0;
}
#excerption .citation .quote p {
  font-size: 36px;
  color: #fff;
  font-style: italic;
}
@media (max-width: 425px) {
  #excerption .citation .quote p {
    font-size: 32px;
  }
}
@media (max-width: 320px) {
  #excerption .citation .quote p {
    font-size: 24px;
  }
}
#excerption .citation .quote h6 {
  font-size: 16px;
  color: #167477;
  font-style: italic;
  font-weight: bold;
}
#excerption .citation .quote h6 b,
#excerption .citation .quote h6 strong {
  font-weight: bold;
}
@media (min-width: 1500px) {
  #excerption .citation .bg-img {
    right: 5%;
    top: 45%;
  }
}
#excerption .decoration-green {
  position: relative;
  width: 28%;
  height: 36px;
  margin-left: auto;
  margin-right: auto;
  background-color: #57c5c9;
  z-index: 2;
  margin-bottom: -1px;
}
@media (max-width: 578px) {
  #excerption .decoration-green {
    transform: scale(0.7);
    margin-bottom: -7px;
  }
}
@media (max-width: 364px) {
  #excerption .decoration-green {
    transform: scale(0.6);
    margin-bottom: -8px;
  }
}
#excerption .decoration-green::before {
  content: "";
  display: block;
  position: absolute;
  height: 36px;
  bottom: 0px;
  right: 99%;
  width: 210px;
  transform: rotateX(180deg) rotate(180deg);
  background-image: url("../img/decoration-green-right.png");
  background-repeat: no-repeat;
}
#excerption .decoration-green::after {
  content: "";
  display: block;
  position: absolute;
  height: 36px;
  bottom: 0px;
  left: 99%;
  width: 210px;
  background-image: url("../img/decoration-green-right.png");
  background-repeat: no-repeat;
}
#vision {
  padding-bottom: 120px;
}
#vision .vision-header {
  margin-bottom: 25px;
}
#vision .vision-subheader {
  margin-bottom: 25px;
}
#vision .vision-row {
  display: flex;
  margin-bottom: 40px;
}
#vision .vision-row .bullet-column {
  padding: 0;
  text-align: center;
}
@media (max-width: 768px) {
  #vision .vision-row .bullet-column {
    margin-left: 22px;
  }
}
#vision .vision-row .bullet-column .bullet {
  height: 51px;
  width: 51px;
  background-color: #57c5c9;
  border-radius: 50%;
  display: inline-block;
}
@media (max-width: 768px) {
  #vision .vision-row .bullet-column .bullet {
    height: 43px;
    width: 43px;
  }
}
#vision .vision-row .bullet-column .bullet .bullet-number {
  color: #fff;
  font-size: 33.87px;
  text-align: center;
  font-weight: bold;
}
@media (max-width: 768px) {
  #vision .vision-row .bullet-column .bullet .bullet-number {
    font-size: 28.87px;
  }
}
@media (max-width: 768px) {
  #vision .vision-row .column-paragraph {
    margin-left: 10px;
  }
}
#vision .under-paragraph {
  margin-top: 30px;
}
#vision .points-row {
  display: flex;
  align-items: center;
}
#vision .points-row .wheel {
  height: 51px;
  width: 51px;
  background-color: #57c5c9;
  border-radius: 50%;
}
#vision .points-row .wheel .wheel-number {
  color: #fff;
  font-size: 33.87px;
  text-align: center;
  font-weight: bold;
}
#subpage-team #crew .crew-header {
  margin-bottom: 30px;
}
#subpage-team #crew .crew-paragraph {
  margin-bottom: 45px;
}
#subpage-team #crew h6,
#subpage-team #crew h6 * {
  font-size: 18px;
  color: #57c5c9;
  margin-bottom: 70px;
  line-height: 30px;
}
@media (max-width: 768px) {
  #subpage-team #crew h6,
  #subpage-team #crew h6 * {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  #subpage-team #crew h6,
  #subpage-team #crew h6 * {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  #subpage-team #crew h6,
  #subpage-team #crew h6 * {
    font-size: 14px;
  }
}
#subpage-team .with-us {
  color: #232f5f;
  padding-top: 0;
}
#subpage-team .with-us h3,
#subpage-team .with-us h3 * {
  font-size: 24px;
  margin-bottom: 45px;
}
#subpage-team .with-us .wrapper .item h5,
#subpage-team .with-us .wrapper .item h5 * {
  color: #232f5f;
}
#subpage-team {
  color: #232f5f;
}
#subpage-team #excerption .citation {
  margin-bottom: 90px;
}
#subpage-team #excerption .citation::after {
  content: none;
}
#subpage-team #excerption .citation .bg-img {
  position: absolute;
  right: 10%;
  top: initial;
  bottom: -16px;
  height: 255px;
  z-index: 2;
}
#subpage-team #excerption .citation .quote {
  border: none !important;
  padding: 0;
  margin: 0;
}
@media (min-width: 1500px) {
  #subpage-team #excerption .citation .bg-img {
    right: 10%;
    top: initial;
    bottom: -16px;
  }
}
#subpage-team #excerption #crew .crew-header {
  margin-bottom: 30px;
}
#subpage-team #excerption #crew .crew-paragraph {
  margin-bottom: 45px;
}
#subpage-team #excerption #crew .crew-people {
  color: #57c5c9;
  margin-bottom: 70px;
}
#subpage-team .with-us {
  color: #232f5f;
  padding-top: 0;
}
#subpage-team .with-us h3 {
  font-size: 24px;
  margin-bottom: 45px;
}
#subpage-team .with-us .wrapper .item h5 {
  color: #232f5f;
}
.contact-block-wrapper {
  display: block;
  width: 100%;
}
.contact-block-wrapper .contact-single-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto 50px;
}
@media (min-width: 992px) {
  .contact-block-wrapper .contact-single-block {
    flex-direction: row;
    width: 80%;
  }
}
.contact-block-wrapper .contact-single-block .contact-picture-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
@media (min-width: 992px) {
  .contact-block-wrapper .contact-single-block .contact-picture-wrapper {
    width: 40%;
  }
}
.contact-block-wrapper .contact-single-block .contact-picture-wrapper .contact-block-picture {
  max-width: 100%;
  height: 120px;
  display: block;
  padding-bottom: 25px;
}
@media (min-width: 992px) {
  .contact-block-wrapper .contact-single-block .contact-picture-wrapper .contact-block-picture {
    height: 205px;
  }
}
.contact-block-wrapper .contact-single-block .contact-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}
@media (min-width: 992px) {
  .contact-block-wrapper .contact-single-block .contact-text-wrapper {
    width: 60%;
    text-align: left;
  }
}
.contact-block-wrapper .contact-single-block .contact-text-wrapper * {
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
}
@media (min-width: 992px) {
  .contact-block-wrapper .contact-single-block .contact-text-wrapper * {
    font-size: 34px;
  }
}
.contact-block-wrapper .contact-single-block .contact-text-wrapper .contact-navy {
  color: #232f5f;
}
.contact-block-wrapper .contact-single-block .contact-text-wrapper .contact-green {
  color: #57c5c9;
  padding: 20px 0 10px;
}
.contact-block-wrapper .contact-single-block .contact-text-wrapper .contact-green * {
  margin: 0;
}
@media (min-width: 992px) {
  .contact-block-wrapper .contact-single-block .contact-text-wrapper .contact-green * {
    margin: 0 0 10px;
  }
}
@media (min-width: 992px) {
  .contact-block-wrapper .contact-single-block .contact-text-wrapper .contact-green {
    padding: 60px 0 10px;
  }
}
div#driving-directions {
  height: 700px;
  width: calc(100% + 44px);
  position: relative;
  left: -22px;
  border-radius: 20px;
  box-shadow: 0 10px 2px -2px #f0f0f0;
  position: relative;
}
@media (min-width: 992px) {
  div#driving-directions {
    width: 100%;
    left: 0;
  }
}
div.map-wrapper {
  position: relative;
}
div.map-wrapper .informations-box {
  position: absolute;
  top: -20px;
  left: -22px;
  width: calc(100% + 44px);
  max-height: 370px;
  box-sizing: border-box;
  border-bottom: 10px;
  border-radius: 30px;
  box-shadow: 0 10px 2px -2px rgba(240,240,240,0.1);
  background-color: #57c5c9;
  z-index: 10;
  padding: 10%;
}
@media (min-width: 550px) {
  div.map-wrapper .informations-box {
    padding: 5% 10%;
  }
}
@media (min-width: 992px) {
  div.map-wrapper .informations-box {
    top: 100px;
    left: 0;
    max-width: 500px;
    max-height: 480px;
    padding: 5% 5%;
  }
}
@media (min-width: 1200px) {
  div.map-wrapper .informations-box {
    max-width: 550px;
  }
}
@media (min-width: 1440px) {
  div.map-wrapper .informations-box {
    left: -10%;
  }
}
@media (min-width: 1920px) {
  div.map-wrapper .informations-box {
    left: -15%;
    max-width: 600px;
  }
}
div.map-wrapper .informations-box * {
  font-size: 24px;
  font-family: 'Titillium Web', sans-serif;
  line-height: 34px;
  margin: 0;
}
@media (min-width: 992px) {
  div.map-wrapper .informations-box * {
    font-size: 34px;
    margin: 0 0 10px;
  }
}
div.map-wrapper .informations-box .contact-info-title {
  color: #232f5f;
}
div.map-wrapper .informations-box .contact-info {
  color: #fff;
}
.blocks-wrapper {
  display: block;
  width: 100%;
}
.blocks-wrapper .single-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 0 50px;
}
@media (min-width: 768px) {
  .blocks-wrapper .single-block:nth-child(2n) {
    flex-direction: row-reverse;
  }
  .blocks-wrapper .single-block:nth-child(2n-1) {
    flex-direction: row;
  }
}
.blocks-wrapper .single-block .picture-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
@media (min-width: 768px) {
  .blocks-wrapper .single-block .picture-wrapper {
    width: 40%;
  }
}
.blocks-wrapper .single-block .picture-wrapper .block-picture {
  max-width: 100%;
  height: 120px;
  display: block;
  padding-bottom: 25px;
}
@media (min-width: 768px) {
  .blocks-wrapper .single-block .picture-wrapper .block-picture {
    height: 205px;
  }
}
.blocks-wrapper .single-block .picture-wrapper .picture-label {
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  line-height: 33px;
  font-weight: bold;
  color: #232f5f;
  text-transform: uppercase;
  text-align: center;
}
@media (min-width: 992px) {
  .blocks-wrapper .single-block .picture-wrapper .picture-label {
    font-size: 33px;
  }
}
.blocks-wrapper .single-block .text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px 5% 0;
  width: 100%;
}
@media (min-width: 768px) {
  .blocks-wrapper .single-block .text-wrapper {
    width: 60%;
  }
}
.blocks-wrapper .single-block .text-wrapper > * {
  color: #232f5f;
  font-family: 'Titillium Web', sans-serif;
  line-height: 28px;
}
.blocks-wrapper .single-block .text-wrapper ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 20px 10px 10px;
  margin: 0;
}
.blocks-wrapper .single-block .text-wrapper ul li {
  font-size: 20px;
  padding-left: 15px;
  position: relative;
}
.blocks-wrapper .single-block .text-wrapper ul li::before {
  content: '';
  width: 7px;
  height: 7px;
  position: absolute;
  top: 10px;
  left: -10px;
  border-radius: 50%;
  background-color: #1792a8;
}
.blocks-wrapper .single-block .text-wrapper p {
  font-size: 18px;
  line-height: 28px;
  padding: 0 0 10px;
  background: none;
}
@media (min-width: 992px) {
  .blocks-wrapper .single-block .text-wrapper p {
    font-size: 20px;
  }
}
.blocks-wrapper .single-block .text-wrapper h2 {
  font-size: 18px;
  line-height: 28px;
  padding: 0;
  background: none;
  font-weight: bold;
  margin: 0;
  display: contents;
}
@media (min-width: 992px) {
  .blocks-wrapper .single-block .text-wrapper h2 {
    font-size: 24px;
    display: block;
  }
}
.day-plan {
  display: block;
  padding: 20px 0;
}
.day-plan .day-bar {
  width: 100%;
  height: 52px;
  background-color: #daf2f3;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
  border-color: #57c5c9;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .day-plan .day-bar {
    padding-left: 100px;
  }
}
.day-plan .day-bar * {
  font-family: 'Titillium Web', sans-serif;
  font-size: 20px;
  line-height: 29px;
  margin: 0;
  color: #232f5f;
}
.program-element {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px 20px 10px 40px;
  margin: 0;
}
@media (min-width: 992px) {
  .program-element {
    padding: 10px 0 10px 120px;
  }
}
.program-element .hour_granatowy,
.program-element .hour_jasnoniebieski {
  width: 10%;
  position: relative;
}
@media (min-width: 992px) {
  .program-element .hour_granatowy,
  .program-element .hour_jasnoniebieski {
    width: 15%;
  }
}
.program-element .hour_granatowy *,
.program-element .hour_jasnoniebieski * {
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}
@media (min-width: 992px) {
  .program-element .hour_granatowy *,
  .program-element .hour_jasnoniebieski * {
    font-size: 24px;
  }
}
.program-element .hour_granatowy::before,
.program-element .hour_jasnoniebieski::before {
  content: '';
  width: 5px;
  height: 5px;
  position: absolute;
  top: 10px;
  left: -20px;
  border-radius: 50%;
  background-color: #1792a8;
}
.program-element .activity_jasnoniebieski,
.program-element .activity_granatowy {
  width: 70%;
}
@media (min-width: 768px) {
  .program-element .activity_jasnoniebieski,
  .program-element .activity_granatowy {
    width: 80%;
  }
}
@media (min-width: 992px) {
  .program-element .activity_jasnoniebieski,
  .program-element .activity_granatowy {
    width: 85%;
  }
}
.program-element .activity_jasnoniebieski *,
.program-element .activity_granatowy * {
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}
@media (min-width: 992px) {
  .program-element .activity_jasnoniebieski *,
  .program-element .activity_granatowy * {
    font-size: 24px;
  }
}
.program-element .hour_granatowy *,
.program-element .activity_granatowy * {
  color: #232f5f;
}
.program-element .hour_jasnoniebieski *,
.program-element .activity_jasnoniebieski * {
  color: #57c5c9;
}
.wrapper {
  margin: 0 auto 150px;
}
.wrapper .carousel {
  margin-bottom: 50px;
  position: relative;
  margin: 100px 0 20px;
  padding: 0;
}
.wrapper .carousel .scrollbar {
  background-color: #daf2f3;
  height: 12px;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
}
@media (min-width: 992px) {
  .wrapper .carousel .scrollbar {
    width: 600px;
    height: 10px;
  }
}
.wrapper .carousel .scrollbar .handle {
  height: 12px;
  background-color: #57c5c9;
  cursor: pointer;
}
@media (min-width: 992px) {
  .wrapper .carousel .scrollbar .handle {
    height: 10px;
  }
}
.wrapper .carousel .frame {
  height: 230px;
}
@media (min-width: 992px) {
  .wrapper .carousel .frame {
    height: 400px;
  }
}
.wrapper .carousel .frame .carousel-slider {
  display: -webkit-box;
  height: 230px;
  padding-top: 5px;
  width: auto;
}
@media (min-width: 992px) {
  .wrapper .carousel .frame .carousel-slider {
    height: 400px;
  }
}
.wrapper .carousel .frame .carousel-slider .image-item {
  height: 100%;
}
.wrapper .carousel .frame .carousel-slider .image-item a {
  height: 100%;
}
.wrapper .carousel .frame .carousel-slider .image-item a .carousel-slide {
  margin-left: 10px;
  width: 280px;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 992px) {
  .wrapper .carousel .frame .carousel-slider .image-item a .carousel-slide {
    width: 360px;
    cursor: pointer;
  }
}
@media (min-width: 1200px) {
  .wrapper .carousel .frame .carousel-slider .image-item a .carousel-slide {
    width: 600px;
  }
}
.join-event-photo-wrapper {
  width: 100%;
  height: 600px;
  margin: -60px 0 0;
  position: relative;
}
@media (min-width: 992px) {
  .join-event-photo-wrapper {
    height: 1100px;
  }
}
.join-event-photo-wrapper .background-photo {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.join-event-photo-wrapper .background-photo .text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10% 9% 20%;
  align-content: flex-start;
}
@media (min-width: 992px) {
  .join-event-photo-wrapper .background-photo .text-wrapper {
    padding: 5% 3% 15% 18%;
  }
}
@media (min-width: 1200px) {
  .join-event-photo-wrapper .background-photo .text-wrapper {
    padding: 5% 12% 15% 20%;
  }
}
@media (min-width: 1440px) {
  .join-event-photo-wrapper .background-photo .text-wrapper {
    padding: 5% 15% 15% 20%;
  }
}
.join-event-photo-wrapper .background-photo .text-wrapper .events-date {
  font-family: 'Titillium Web', sans-serif;
  font-size: 30px;
  line-height: 45px;
  font-weight: bold;
  color: #57c5c9;
}
@media (min-width: 992px) {
  .join-event-photo-wrapper .background-photo .text-wrapper .events-date {
    font-size: 45px;
    line-height: 65px;
  }
}
.join-event-photo-wrapper .background-photo .text-wrapper .events-title {
  font-family: 'Titillium Web', sans-serif;
  font-size: 36px;
  line-height: 35px;
  font-weight: bold;
  color: #fff;
  padding: 0 !important;
  background: none !important;
}
@media (min-width: 650px) {
  .join-event-photo-wrapper .background-photo .text-wrapper .events-title {
    padding: 0 20% 0 0 !important;
  }
}
@media (min-width: 850px) {
  .join-event-photo-wrapper .background-photo .text-wrapper .events-title {
    padding: 0 32% 0 0 !important;
  }
}
@media (min-width: 992px) {
  .join-event-photo-wrapper .background-photo .text-wrapper .events-title {
    font-size: 96px;
    line-height: 100px;
    padding: 0 !important;
  }
}
@media (min-width: 1200px) {
  .join-event-photo-wrapper .background-photo .text-wrapper .events-title {
    padding: 0 10% 0 0 !important;
  }
}
.join-event-photo-wrapper .background-photo .text-wrapper .events-subtitle {
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 27px;
  color: #fff;
  font-weight: normal;
}
@media (min-width: 992px) {
  .join-event-photo-wrapper .background-photo .text-wrapper .events-subtitle {
    font-size: 60px;
    line-height: 108px;
  }
}
div.content-wrapper {
  width: 100%;
  position: relative;
}
div.content-wrapper .info-block {
  margin: -100px 0 0;
  height: 100%;
  background-color: #57c5c9;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-betwween;
  flex-wrap: wrap;
  padding: 8% 9%;
  align-content: center;
}
@media (min-width: 992px) {
  div.content-wrapper .info-block {
    margin: -170px 12% 0;
    padding: 3% 16%;
  }
}
div.content-wrapper .info-block .start-header * {
  font-family: 'Titillium Web', sans-serif;
  font-size: 30px !important;
  line-height: 35px;
  text-align: center;
  color: #232f5f !important;
  margin: 0px;
}
@media (min-width: 992px) {
  div.content-wrapper .info-block .start-header * {
    font-size: 45px !important;
    line-height: 56px;
  }
}
div.content-wrapper .info-block .start-quote {
  margin: 40px 0 20px;
}
div.content-wrapper .info-block .start-quote * {
  font-family: 'Titillium Web', sans-serif;
  font-size: 22px !important;
  line-height: 29px;
  color: #fff !important;
  text-align: center;
  line-height: 35px;
  margin: 0px;
}
@media (min-width: 992px) {
  div.content-wrapper .info-block .start-quote * {
    font-size: 30px !important;
    line-height: 43px;
  }
}
div.content-wrapper .info-block .quote-info * {
  font-family: 'Titillium Web', sans-serif;
  font-size: 17px;
  line-height: 29px;
  color: #232f5f;
  text-align: center;
}
@media (min-width: 992px) {
  div.content-wrapper .info-block .quote-info * {
    font-size: 18px;
    line-height: 29px;
  }
}
.join-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0% 5%;
  align-content: center;
  text-align: center;
}
@media (min-width: 992px) {
  .join-text-wrapper {
    padding: 0 20% 5%;
  }
}
.join-text-wrapper .join-date {
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
  color: #57c5c9;
  margin: 0;
}
@media (min-width: 992px) {
  .join-text-wrapper .join-date {
    font-size: 45px;
    line-height: 65px;
  }
}
.join-text-wrapper .join-title {
  font-family: 'Titillium Web', sans-serif;
  font-size: 30px;
  line-height: 40px;
  font-weight: bold;
  color: #232f5f;
  background: none !important;
  margin: 20px -20px 0;
}
@media (min-width: 992px) {
  .join-text-wrapper .join-title {
    font-size: 58px;
    line-height: 76px;
  }
}
.join-text-wrapper .join-subtitle {
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 34px;
  color: #57c5c9;
  margin: 0;
}
@media (min-width: 992px) {
  .join-text-wrapper .join-subtitle {
    font-size: 36px;
    line-height: 64px;
  }
}
.quote-below-gallery * {
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin: 0 auto;
  color: #232f5f;
  text-align: center;
}
@media (min-width: 992px) {
  .quote-below-gallery * {
    font-size: 24px;
  }
}
.sponsor-module * {
  font-family: 'Titillium Web', sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin: 0 auto;
  color: #232f5f;
  text-align: center;
}
@media (min-width: 992px) {
  .sponsor-module * {
    font-size: 24px;
  }
}
.sponsor-module .sponsor-logo-wrapper {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 300px;
  height: 150px;
  transform: scale(0.7);
  margin: 20px auto 50px;
}
@media (min-width: 768px) {
  .sponsor-module .sponsor-logo-wrapper {
    height: 215px;
    transform: scale(0.8);
  }
}
@media (min-width: 992px) {
  .sponsor-module .sponsor-logo-wrapper {
    margin: 50px auto 70px;
    transform: scale(1);
  }
}
#use-app #page-header {
  margin-bottom: 0;
}
.loop {
  color: #232f5f;
}
.loop section:nth-child(odd) {
  overflow: hidden;
}
.loop section .ptb {
  padding-top: 75px;
  padding-bottom: 100px;
}
@media (max-width: 1199px) {
  .loop section .ptb {
    padding-top: 70px;
  }
}
@media (max-width: 425px) {
  .loop section .ptb {
    padding-top: 60px;
  }
}
@media (max-width: 1199px) {
  .loop section .ptb {
    padding-bottom: 70px;
  }
}
@media (max-width: 425px) {
  .loop section .ptb {
    padding-bottom: 60px;
  }
}
.loop section:nth-child(even) {
  background-color: #f5f5f5;
}
.loop section:nth-child(even) a button {
  background-color: #f5f5f5;
}
.loop section:nth-child(even) a button:hover {
  background-color: #57c5c9;
}
.loop section:nth-child(even) .group-img {
  padding-right: 8.33%;
  margin-top: -115px;
}
@media (max-width: 1199px) {
  .loop section:nth-child(even) .group-img {
    margin-top: 0;
  }
}
.loop section:nth-child(even) .group-img img {
  float: right;
}
@media (max-width: 1199px) {
  .loop section:nth-child(even) .group-img img {
    float: left;
    max-width: 121%;
  }
}
@media (max-width: 1599px) {
  .loop section:nth-child(even) .group-img img {
    max-width: 119%;
  }
}
@media (max-width: 1439px) {
  .loop section:nth-child(even) .group-img img {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .loop section:nth-child(odd) .group {
    display: flex;
    align-items: center;
  }
  .loop section:nth-child(odd) .group .group-img {
    order: 1;
    padding-left: 8.33%;
  }
  .loop section:nth-child(odd) .group .group-img img {
    float: left;
  }
}
.loop section:first-child .group-img img {
  height: 100%;
  max-height: 300px;
  width: 300px;
}
.loop section .group {
  display: flex;
}
@media (max-width: 1199px) {
  .loop section .group {
    flex-direction: column;
    align-items: flex-start;
  }
}
.loop section .group .group-img {
  width: 49%;
}
@media (max-width: 1199px) {
  .loop section .group .group-img {
    margin-bottom: 40px;
    width: 100%;
  }
}
.loop section .group .group-img img {
  display: block;
}
@media (max-width: 1199px) {
  .loop section .group .group-img img {
    max-width: 100%;
    height: auto;
  }
}
.loop section .group .group-text {
  width: 49%;
}
@media (max-width: 1199px) {
  .loop section .group .group-text {
    width: 100%;
  }
}
.loop section .group .group-text h5 {
  margin-bottom: 40px;
}
.loop section .group .group-text h5 strong {
  font-weight: bold;
}
.loop section .group .group-text a {
  text-decoration: none;
}
.loop section .group .group-text a button {
  font-size: 13px;
  padding: 7px 32px 9px;
  white-space: pre-wrap;
  height: auto;
}
#meetings-text {
  color: #232f5f;
}
#meetings-text .subpage-meetings-paragraph {
  padding-bottom: 60px;
}
#meetings-series {
  background-color: #f5f5f5;
  padding-top: 120px;
  padding-bottom: 60px;
}
@media (max-width: 1024px) {
  #meetings-series {
    padding-top: 80px;
  }
}
@media (max-width: 768px) {
  #meetings-series {
    padding-top: 50px;
  }
}
#meetings-series .series-all-wrapper .series-list {
  position: relative;
}
#meetings-series .series-all-wrapper .series-list:not(:last-child) {
  margin-bottom: 100px;
}
@media (max-width: 1024px) {
  #meetings-series .series-all-wrapper .series-list:not(:last-child) {
    margin-bottom: 80px;
  }
}
@media (max-width: 320px) {
  #meetings-series .series-all-wrapper .series-list:not(:last-child) {
    margin-bottom: 60px;
  }
}
#meetings-series .series-all-wrapper .series-list .group-navbar {
  width: 25px;
  height: calc(100% - 6px);
  background-color: #fff;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 6px;
}
#meetings-series .series-all-wrapper .series-list .group-navbar .nav-text {
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  position: absolute;
  top: 4%;
  left: 50%;
  width: auto;
  height: 25px;
  white-space: nowrap;
}
#meetings-series .series-all-wrapper .series-list .group-navbar .nav-text .navbar-series {
  color: #57c5c9;
  font-weight: bold;
  margin: 0;
  display: inline-block;
}
#meetings-series .series-all-wrapper .series-list .group-navbar .nav-text .separator {
  font-size: 12px;
  color: #3169a7;
}
#meetings-series .series-all-wrapper .series-list .group-navbar .nav-text .navbar-group {
  font-size: 12px;
  color: #3169a7;
  margin: 0;
  display: inline-block;
}
#meetings-series .series-all-wrapper .series-list .serie {
  display: flex;
  margin-left: 25px;
  flex-direction: column;
}
#meetings-series .series-all-wrapper .series-list .serie .serie-title {
  background-color: #fff;
  color: #232f5f;
  margin-left: 7px;
  width: 97%;
  padding: 35px 25px;
  border-radius: 4px;
  margin-bottom: 6px;
}
#meetings-series .series-all-wrapper .series-list .serie .serie-title h5 {
  font-weight: bold;
}
#meetings-series .series-all-wrapper .series-list .serie .serie-title .serie-description {
  padding-top: 30px;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item {
  background-color: #fff;
  width: 32%;
  border: 2px solid #fff;
  border-radius: 4px;
  margin-left: 7px;
  margin-bottom: 6px;
}
@media (max-width: 991px) {
  #meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item {
    width: 48%;
  }
}
@media (max-width: 639px) {
  #meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item {
    width: 99%;
  }
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item:hover .trailer {
  background-color: #57c5c9;
  transition: background-color 0.3s ease;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item:hover .trailer p {
  color: #99e8eb;
  transition: color 0.3s ease;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item:hover .photo:after {
  background-color: rgba(200,236,237,0.33);
  transition: background-color 0.3s ease;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item:hover .content .meeting .name,
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item:hover .content .meeting p {
  color: #1792a8;
  transition: color 0.3s ease;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item:hover .content .meeting .title,
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item:hover .content .meeting p {
  color: #57c5c9;
  transition: color 0.3s ease;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item:hover .content .materials-wrapper .materials-item .text-pikto .cls-1 {
  fill: #57c5c9;
  transition: fill 0.3s ease;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item:hover .content .materials-wrapper .materials-item p {
  color: #1792a8;
  transition: color 0.3s ease;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .photo {
  height: 210px;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  cursor: pointer;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .photo:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .photo .btn-films-play {
  width: 21px;
  height: 17px;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .photo:hover .btn-films-play {
  fill: #1792a8;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .trailer {
  background-color: #232f5f;
  position: relative;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .trailer p {
  font-size: 16px;
  color: #697abd;
  font-weight: bold;
  padding: 5px 10px;
  margin-top: -22px;
  margin-bottom: 0;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content {
  padding: 22px 22px 30px;
  color: #232f5f;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1200px) and (min-width: 640px) {
  #meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content {
    min-height: 238px;
  }
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .meeting .name {
  margin-bottom: 3px;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .meeting .title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 24px;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .title-text {
  margin-bottom: 10px;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .title-text .content-title p {
  font-size: 12px;
  color: #232f5f;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2px;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .title-text .content-text a {
  text-decoration: none;
  width: 100%;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .title-text .content-text p {
  font-size: 12px;
  color: #1792a8;
  padding: 0;
  margin: 0;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .materials-wrapper {
  display: flex;
}
@media (max-width: 1200px) {
  #meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .materials-wrapper {
    flex-direction: column;
  }
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .materials-wrapper a {
  text-decoration: none;
  color: #232f5f;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .materials-wrapper a:first-child {
  margin-bottom: 10px;
  margin-right: 12px;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .materials-wrapper a .materials-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .materials-wrapper a .materials-item .text-pikto {
  fill: #57c5c9;
  margin-right: 6px;
  width: 33px;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .materials-wrapper a .materials-item .text-pikto .cls-1 {
  fill: #57c5c9;
}
#meetings-series .series-all-wrapper .series-list .serie .meetings-wrapper .meetings-item .content .materials-wrapper a .materials-item p {
  font-size: 16px;
  margin: 0;
  line-height: 17px;
  width: calc(100% - 41px);
}
#meetings-series .series-all-wrapper .series-list .text-wrapper {
  margin-left: 32px;
  width: 95%;
  border-bottom: 6px solid #f5f5f5;
  margin-top: 80px;
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  #meetings-series .series-all-wrapper .series-list .text-wrapper {
    width: 94%;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
@media (max-width: 639px) {
  #meetings-series .series-all-wrapper .series-list .text-wrapper {
    width: 90%;
    margin-bottom: 40px;
  }
}
#meetings-series .series-all-wrapper .series-list .text-wrapper .text-border {
  background-color: #fff;
  padding: 35px 25px;
  color: #232f5f;
  border: 2px solid #232f5f;
  border-radius: 4px;
}
#meetings-series .series-all-wrapper .series-list .text-wrapper .text-border p {
  margin: 0;
}
#meetings-series .series-all-wrapper .series-list .text-wrapper .text-border .header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 32px;
}
@media (max-width: 425px) {
  #meetings-series .series-all-wrapper .series-list .text-wrapper .text-border .header {
    font-size: 21px;
    margin-bottom: 20px;
  }
}
#meetings-series .series-all-wrapper .series-list .text-wrapper .text-border .description {
  color: #1792a8;
  margin-bottom: 28px;
}
#meetings-series .series-all-wrapper .series-list .text-wrapper .text-border .caption1 {
  font-weight: bold;
}
#popup-text {
  font-family: 'Titillium Web', sans-serif;
  color: #232f5f;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(87,197,201,0.77);
  z-index: 999999999;
  display: none;
}
#popup-text .popup-close-blenda {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
#popup-text .popup-wrapper {
  position: absolute;
  background-color: #fff;
  top: 2%;
  height: 96%;
  width: 700px;
  max-width: 90%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#popup-text .popup-wrapper .popup-header {
  width: 100%;
  background-color: #57c5c9;
  padding: 25px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}
#popup-text .popup-wrapper .popup-header h3 {
  font-size: 25px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 0;
}
#popup-text .popup-wrapper .popup-header h4 {
  font-size: 14px;
  color: #fff;
  margin: 0;
  padding: 0;
}
#popup-text .popup-wrapper .popup-header .exit-cross {
  width: 23px;
  height: 23px;
  top: 34px;
  right: 50px;
}
#popup-text .popup-wrapper .popup-header .exit-cross::before,
#popup-text .popup-wrapper .popup-header .exit-cross::after {
  background-color: #fff;
  height: 23px;
  top: -3px;
}
#popup-text .popup-wrapper .nano-content {
  padding: 50px;
}
#popup-text .popup-wrapper .popup-content:nth-last-child(1) {
  margin-bottom: 0;
}
#popup-text .popup-wrapper .popup-content h1 {
  font-size: 18px;
  color: #57c5c9;
  padding: 0;
  margin: 25px 0;
}
#popup-text .popup-wrapper .popup-content h1:nth-child(1) {
  margin: 0 0 25px 0;
}
#popup-text .popup-wrapper .popup-content h1 strong {
  font-size: 18px;
  color: #57c5c9;
  padding: 0;
}
#popup-text .popup-wrapper .popup-content p {
  font-size: 14px;
  margin: 0;
}
#popup-text .popup-wrapper .popup-content p:nth-last-child(1) {
  margin-bottom: 0;
}
#popup-text .popup-wrapper .popup-content h3 {
  font-size: 18px;
}
#popup-text .popup-wrapper .popup-content h3 span {
  margin-bottom: 25px;
  font-size: 18px;
}
#popup-text .popup-wrapper .popup-content h4 {
  font-size: 14px;
  margin: 0;
}
#popup-text .popup-wrapper .popup-content ul {
  padding-left: 18px;
  margin: 0;
  margin-bottom: 0;
}
#popup-text .popup-wrapper .popup-content li {
  margin-bottom: 10px;
}
#popup-text .popup-wrapper .popup-content blockquote {
  border: none;
  padding: 0;
  font-size: 14px;
  margin: 0;
}
#popup-text .popup-wrapper .buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
}
#popup-text .popup-wrapper .buttons .btn {
  font-size: 13px;
  padding: 0 22px;
  height: 100%;
  margin-bottom: 12px;
}
#latest-news {
  padding-bottom: 50px;
}
#latest-news h3.all-news-header {
  font-size: 30px;
  line-height: 69px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  color: #232f5f;
  margin-bottom: 50px;
}
#latest-news .last-news-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  border-radius: 15px;
  width: 100%;
  height: 420px;
}
@media (min-width: 768px) {
  #latest-news .last-news-wrapper {
    height: 560px;
  }
}
#latest-news .last-news-wrapper .picture-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
}
#latest-news .last-news-wrapper .picture-wrapper .info-wrapper {
  width: 100%;
  min-height: 100px;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  z-index: 11;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgba(41,49,88,0.65);
  padding: 50px 10%;
}
@media (min-width: 768px) {
  #latest-news .last-news-wrapper .picture-wrapper .info-wrapper {
    min-height: 280px;
    padding: 50px 20% 50px 40px;
  }
}
@media (min-width: 1200px) {
  #latest-news .last-news-wrapper .picture-wrapper .info-wrapper {
    min-height: 280px;
    padding: 50px 40% 50px 40px;
  }
}
#latest-news .last-news-wrapper .picture-wrapper .info-wrapper p.news-date {
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
}
#latest-news .last-news-wrapper .picture-wrapper .info-wrapper h5.news-title {
  font-size: 30px;
  margin: 0 !important;
  line-height: 35px;
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
}
@media (min-width: 768px) {
  #latest-news .last-news-wrapper .picture-wrapper .info-wrapper h5.news-title {
    font-size: 48px;
    line-height: 52px;
  }
}
#latest-news .last-news-wrapper .picture-wrapper .info-wrapper p.news-short-description {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin-top: 5px;
  font-family: 'Titillium Web', sans-serif;
}
#rest-news {
  padding-bottom: 80px;
}
#rest-news h3.all-news-header {
  font-size: 30px;
  line-height: 69px;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  color: #232f5f;
  margin-bottom: 50px;
}
#rest-news .rest-news-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  border-radius: 15px;
  width: 100%;
}
#rest-news .rest-news-wrapper .single-news-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
}
@media (min-width: 600px) {
  #rest-news .rest-news-wrapper .single-news-wrapper {
    width: 100%;
    padding: 0 10%;
  }
}
@media (min-width: 768px) {
  #rest-news .rest-news-wrapper .single-news-wrapper {
    width: 48%;
    padding: 0;
  }
}
@media (min-width: 1200px) {
  #rest-news .rest-news-wrapper .single-news-wrapper {
    width: 31%;
  }
}
#rest-news .rest-news-wrapper .single-news-wrapper .news-image {
  height: 300px;
  width: 100%;
  z-index: 15;
  border-radius: 20px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;
}
@media (min-width: 600px) {
  #rest-news .rest-news-wrapper .single-news-wrapper .news-image {
    height: 390px;
  }
}
#rest-news .rest-news-wrapper .single-news-wrapper .news-image .blend {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  transition: opacity 0.3s ease-out;
  background-color: rgba(41,49,88,0.65);
}
#rest-news .rest-news-wrapper .single-news-wrapper .news-image .blend:hover {
  opacity: 1;
}
#rest-news .rest-news-wrapper .single-news-wrapper .news-image .blend img {
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  width: 70px;
  height: 70px;
}
#rest-news .rest-news-wrapper .single-news-wrapper .info-wrapper {
  width: 100%;
  flex: auto;
}
#rest-news .rest-news-wrapper .single-news-wrapper .info-wrapper p.news-date {
  font-size: 14px;
  line-height: 24px;
  color: #232f5f;
  margin: 0 !important;
  font-family: 'Titillium Web', sans-serif;
}
#rest-news .rest-news-wrapper .single-news-wrapper .info-wrapper h5.news-title {
  font-size: 30px;
  margin: 0 !important;
  line-height: 38px;
  color: #232f5f;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
}
#rest-news .rest-news-wrapper .single-news-wrapper .info-wrapper p.news-short-description {
  font-size: 18px;
  line-height: 24px;
  color: #232f5f;
  margin-top: 15px;
  font-family: 'Titillium Web', sans-serif;
}
#rest-news .rest-news-wrapper-no-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  border-radius: 15px;
  width: 100%;
}
#rest-news .rest-news-wrapper-no-div .single-news-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
}
@media (min-width: 600px) {
  #rest-news .rest-news-wrapper-no-div .single-news-wrapper {
    width: 100%;
    padding: 0 10%;
  }
}
@media (min-width: 768px) {
  #rest-news .rest-news-wrapper-no-div .single-news-wrapper {
    width: 48%;
    padding: 0;
    margin-right: 25px;
  }
  #rest-news .rest-news-wrapper-no-div .single-news-wrapper:nth-child(2n) {
    margin-right: 0px;
  }
}
@media (min-width: 1200px) {
  #rest-news .rest-news-wrapper-no-div .single-news-wrapper {
    padding: 0;
    width: 31%;
    margin-right: 39px;
  }
  #rest-news .rest-news-wrapper-no-div .single-news-wrapper:nth-child(2n) {
    margin-right: 39px;
  }
  #rest-news .rest-news-wrapper-no-div .single-news-wrapper:nth-child(3n) {
    margin-right: 0px;
  }
}
#rest-news .rest-news-wrapper-no-div .single-news-wrapper .news-image {
  height: 300px;
  width: 100%;
  z-index: 15;
  border-radius: 20px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;
}
@media (min-width: 600px) {
  #rest-news .rest-news-wrapper-no-div .single-news-wrapper .news-image {
    height: 390px;
  }
}
#rest-news .rest-news-wrapper-no-div .single-news-wrapper .news-image .blend {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  transition: opacity 0.3s ease-out;
  background-color: rgba(41,49,88,0.65);
}
#rest-news .rest-news-wrapper-no-div .single-news-wrapper .news-image .blend:hover {
  opacity: 1;
}
#rest-news .rest-news-wrapper-no-div .single-news-wrapper .news-image .blend img {
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  width: 70px;
  height: 70px;
}
#rest-news .rest-news-wrapper-no-div .single-news-wrapper .info-wrapper {
  width: 100%;
  flex: auto;
}
#rest-news .rest-news-wrapper-no-div .single-news-wrapper .info-wrapper p.news-date {
  font-size: 14px;
  line-height: 24px;
  color: #232f5f;
  margin: 0 !important;
  font-family: 'Titillium Web', sans-serif;
}
#rest-news .rest-news-wrapper-no-div .single-news-wrapper .info-wrapper h5.news-title {
  font-size: 30px;
  margin: 0 !important;
  line-height: 38px;
  color: #232f5f;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
}
#rest-news .rest-news-wrapper-no-div .single-news-wrapper .info-wrapper p.news-short-description {
  font-size: 18px;
  line-height: 24px;
  color: #232f5f;
  margin-top: 15px;
  font-family: 'Titillium Web', sans-serif;
}
#pagination-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 70px;
}
#pagination-wrapper .navigation-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#pagination-wrapper .navigation-wrapper img {
  width: 23px;
  height: 19px;
  margin: 0 30px;
  cursor: pointer;
}
#pagination-wrapper .navigation-wrapper .empty-img {
  opacity: 0;
}
#pagination-wrapper .navigation-wrapper .blogPostList__pagination a.blogPostList__pagination__pageNumber {
  text-decoration: none !important;
  font-size: 16px !important;
  line-height: 24px;
  color: #232f5f;
  font-family: 'Titillium Web', sans-serif;
  cursor: pointer;
}
#pagination-wrapper .navigation-wrapper .blogPostList__pagination a.blogPostList__pagination__prev {
  text-decoration: none !important;
}
#pagination-wrapper .navigation-wrapper .blogPostList__pagination span {
  text-decoration: none;
  font-size: 16px !important;
  line-height: 24px;
  color: #6cd2d6;
  font-family: 'Titillium Web', sans-serif;
}
#pagination-wrapper .navigation-wrapper .blogPostList__pagination span.dots {
  color: #232f5f;
}
#single-news {
  margin: -60px 0 0;
  position: relative;
}
#single-news .baner-photo-wrapper {
  width: 100%;
  height: 570px;
}
#single-news .baner-photo-wrapper .background-photo {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  padding: 80px 30px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#single-news .baner-photo-wrapper .background-photo:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(7,7,73,0.45);
}
@media (min-width: 768px) {
  #single-news .baner-photo-wrapper .background-photo {
    padding: 100px 20%;
  }
}
@media (min-width: 1200px) {
  #single-news .baner-photo-wrapper .background-photo {
    padding: 150px 35% 150px 20%;
  }
}
#single-news .baner-photo-wrapper .background-photo .text-wrapper {
  width: 100%;
  display: flex;
  z-index: 10;
  flex-direction: column;
  justify-content: center;
}
#single-news .baner-photo-wrapper .background-photo .text-wrapper h3.news-title {
  font-size: 50px;
  margin: 0 !important;
  line-height: 56px;
  color: #fff;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
}
@media (min-width: 768px) {
  #single-news .baner-photo-wrapper .background-photo .text-wrapper h3.news-title {
    font-size: 80px;
    line-height: 86px;
  }
}
#single-news .baner-photo-wrapper .background-photo .text-wrapper p.news-description {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin-top: 30px;
  font-family: 'Titillium Web', sans-serif;
}
.content-wrapper {
  width: 100%;
  padding: 10px 30px;
}
@media (min-width: 992px) {
  .content-wrapper {
    padding: 10px 20%;
  }
}
@media (min-width: 1200px) {
  .content-wrapper {
    padding: 10px 30%;
  }
}
.content-wrapper .single-news-text p {
  font-size: 18px;
  line-height: 24px;
  color: #232f5f;
  margin-top: 30px;
  font-family: 'Titillium Web', sans-serif;
}
.content-wrapper .single-news-signing-wrapper {
  margin: 30px 0;
}
.content-wrapper .single-news-signing-wrapper p.single-news-signing {
  font-size: 14px !important;
  line-height: 19px !important;
  color: #232f5f;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
  margin: 0 !important;
}
.container .single-news-photo {
  width: 100%;
  height: 360px;
  margin: 50px 0;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media (min-width: 768px) {
  .container .single-news-photo {
    height: 560px;
  }
}
.header-author-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 50px;
}
@media (min-width: 992px) {
  .header-author-wrapper {
    flex-direction: row;
    margin-top: 100px;
  }
}
.header-author-wrapper .date-author-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 40px;
  width: 50%;
  padding-left: 30px;
}
@media (min-width: 992px) {
  .header-author-wrapper .date-author-wrapper {
    width: 20%;
    padding-right: 60px;
  }
}
@media (min-width: 1200px) {
  .header-author-wrapper .date-author-wrapper {
    width: 30%;
    padding-left: 15%;
  }
}
@media (min-width: 1440px) {
  .header-author-wrapper .date-author-wrapper {
    padding-left: 17%;
  }
}
@media (min-width: 1920px) {
  .header-author-wrapper .date-author-wrapper {
    padding-left: 20%;
  }
}
.header-author-wrapper .date-author-wrapper .date-author {
  font-size: 14px;
  line-height: 19px;
  color: #232f5f;
  margin: 0 !important;
  font-family: 'Titillium Web', sans-serif;
}
.header-author-wrapper .single-news-header {
  width: 50%;
  padding-top: 20px;
  padding-left: 30px;
}
@media (min-width: 992px) {
  .header-author-wrapper .single-news-header {
    padding: 0;
    width: 80%;
  }
}
@media (min-width: 1200px) {
  .header-author-wrapper .single-news-header {
    width: 70%;
  }
}
.header-author-wrapper .single-news-header p {
  font-size: 24px;
  line-height: 30px;
  color: #232f5f;
  font-family: 'Titillium Web', sans-serif;
}
.header-author-wrapper-back {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 25px;
}
@media (min-width: 992px) {
  .header-author-wrapper-back {
    flex-direction: row;
    margin-top: 40px;
  }
}
.header-author-wrapper-back .date-author-wrapper-back {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 40px;
  width: 50%;
  padding-left: 30px;
}
@media (min-width: 992px) {
  .header-author-wrapper-back .date-author-wrapper-back {
    width: 20%;
    padding-right: 20px;
  }
}
@media (min-width: 1200px) {
  .header-author-wrapper-back .date-author-wrapper-back {
    width: 30%;
    padding-left: 15%;
  }
}
@media (min-width: 1440px) {
  .header-author-wrapper-back .date-author-wrapper-back {
    padding-left: 17%;
  }
}
@media (min-width: 1920px) {
  .header-author-wrapper-back .date-author-wrapper-back {
    padding-left: 20%;
  }
}
.header-author-wrapper-back .date-author-wrapper-back a.back-link {
  text-decoration: none !important;
}
.header-author-wrapper-back .date-author-wrapper-back .text-arrow-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none !important;
  position: relative;
  cursor: pointer;
}
.header-author-wrapper-back .date-author-wrapper-back .text-arrow-wrapper:before {
  content: '';
  position: absolute;
  top: 30px;
  left: 0;
  width: 28px;
  height: 3px;
  background-color: #57c5c9;
}
.header-author-wrapper-back .date-author-wrapper-back .text-arrow-wrapper .back-text {
  font-size: 14px;
  line-height: 19px;
  color: #57c5c9;
  margin: 0 !important;
  font-family: 'Titillium Web', sans-serif;
  font-weight: bold;
}
.header-author-wrapper-back .date-author-wrapper-back .text-arrow-wrapper .back-text .arrow-back {
  width: 9px;
  height: 11px;
}
.create-account .net {
  position: relative;
  height: auto;
  color: #797979;
}
.create-account .net p {
  font-size: 14px;
  line-height: 24px;
  color: #797979;
}
.create-account .net .info {
  font-size: 11px;
  text-transform: uppercase;
  margin-top: 20px;
  text-align: center;
}
.create-account .net h1,
.create-account .net h1 * {
  margin-bottom: 20px;
}
.create-account .net h2,
.create-account .net h2 * {
  font-size: 24px;
  margin-bottom: 24px;
  background: transparent;
  color: #232f5f;
}
@media (max-width: 320px) {
  .create-account .net h2,
  .create-account .net h2 * {
    font-size: 21px;
  }
}
.create-account .net .flex-wrapper .left-sidebar {
  width: 670px;
  padding-top: 22px;
  padding-bottom: 22px;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.create-account .net .flex-wrapper .left-sidebar .login-content .reset-paragraph {
  margin-bottom: 80px;
}
@media (max-width: 375px) {
  .create-account .net .flex-wrapper .left-sidebar .login-content .reset-paragraph {
    margin-bottom: 50px;
  }
}
.create-account .net .flex-wrapper .left-sidebar .login-content .account-paragraph {
  margin-bottom: 20px;
}
.create-account .net .flex-wrapper .left-sidebar .login-content .account-telephone p {
  margin-bottom: 40px;
  color: #57c5c9;
}
.create-account .net .flex-wrapper .left-sidebar .forms .form-group {
  margin-bottom: 20px;
}
.create-account .net .flex-wrapper .left-sidebar .forms .form-group .warning {
  display: none;
  color: #ce8483;
}
.create-account .net .flex-wrapper .left-sidebar .forms .form-group label {
  padding-left: 12px;
  font-weight: 400;
}
.create-account .net .flex-wrapper .left-sidebar .forms .input-group {
  margin-bottom: 11px;
  width: 100%;
}
.create-account .net .flex-wrapper .left-sidebar .forms .form-control {
  border-color: #d7d7d7;
  height: 40px;
}
.create-account .net .flex-wrapper .left-sidebar .forms .has-error .form-control {
  border-color: #ce8483;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 6px #ce8483;
}
.create-account .net .flex-wrapper .left-sidebar .forms .has-error .warning {
  display: block;
}
.create-account .net .flex-wrapper .left-sidebar .forms input {
  color: #232f5f;
  font-weight: 600;
}
.create-account .net .flex-wrapper .left-sidebar .forms input::-webkit-input-placeholder {
  color: #b1b1b1;
  opacity: 1;
  font-weight: 400;
}
.create-account .net .flex-wrapper .left-sidebar .forms input::-moz-placeholder {
  color: #b1b1b1;
  opacity: 1;
  font-weight: 400;
}
.create-account .net .flex-wrapper .left-sidebar .forms input:-ms-input-placeholder {
  color: #b1b1b1;
  opacity: 1;
  font-weight: 400;
}
.create-account .net .flex-wrapper .left-sidebar .forms input:-moz-placeholder {
  color: #b1b1b1;
  opacity: 1;
  font-weight: 400;
}
.create-account .net .flex-wrapper .buttons {
  margin-top: 50px;
}
.create-account .logo {
  padding-bottom: 100px;
}
@media (max-width: 1024px) {
  .create-account .logo {
    padding-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .create-account .logo {
    padding-bottom: 50px;
  }
}
.create-account .logo svg,
.create-account .logo img {
  width: 185px;
}
.create-account .bar {
  background-color: #1792a8;
}
.flash-message-popup {
  position: fixed;
  background-color: rgba(0,0,0,0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999;
  transition: all 0.4s ease;
}
.flash-message-popup .window-popup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  width: 475px;
  padding: 36px;
  text-align: center;
  margin: 0 22px;
}
.flash-message-popup .window-popup .cross-popup {
  position: absolute;
  top: 20px;
  right: 22px;
  width: 21px;
  height: 21px;
  cursor: pointer;
}
.flash-message-popup .window-popup .cross-popup::before {
  position: absolute;
  content: '';
  height: 22px;
  width: 2px;
  background-color: #57c5c9;
  transform: rotate(45deg);
}
.flash-message-popup .window-popup .cross-popup::after {
  position: absolute;
  content: '';
  height: 22px;
  width: 2px;
  background-color: #57c5c9;
  transform: rotate(-45deg);
}
.flash-message-popup .window-popup .content-popup {
  padding: 70px 0;
}
.flash-message-popup .window-popup .content-popup h6 {
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 24px;
  color: #232f5f;
  font-weight: 700;
}
@media (max-width: 425px) {
  .flash-message-popup .window-popup .content-popup h6 {
    font-size: 20px;
  }
}
#download-popup.flash-message-popup {
  display: none;
  font-family: 'Titillium Web', sans-serif;
}
#download-popup.flash-message-popup .window-popup {
  width: 763px;
  padding: 36px;
  margin: 0 22px;
}
#download-popup.flash-message-popup .window-popup .content-popup {
  padding: 70px 0;
}
#download-popup.flash-message-popup .window-popup .content-popup img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 479px) {
  #download-popup.flash-message-popup .window-popup .content-popup img {
    display: none;
  }
}
@media (max-height: 799px) {
  #download-popup.flash-message-popup .window-popup .content-popup img {
    display: none;
  }
}
#download-popup.flash-message-popup .window-popup .content-popup h6 {
  margin-top: 0;
  margin-bottom: 32px;
  font-size: 36px;
  color: #1792a8;
  font-weight: 700;
  max-width: 567px;
}
@media (max-width: 479px) {
  #download-popup.flash-message-popup .window-popup .content-popup h6 {
    font-size: 22px;
  }
}
@media (max-height: 799px) {
  #download-popup.flash-message-popup .window-popup .content-popup h6 {
    font-size: 22px;
  }
}
#download-popup.flash-message-popup .window-popup .content-popup hr {
  margin: 0 0 60px;
}
@media (max-width: 479px) {
  #download-popup.flash-message-popup .window-popup .content-popup hr {
    display: none;
  }
}
@media (max-height: 799px) {
  #download-popup.flash-message-popup .window-popup .content-popup hr {
    display: none;
  }
}
#download-popup.flash-message-popup .window-popup .content-popup .paragraph1 {
  color: #1792a8;
  margin: 0;
  font-size: 17px;
}
@media (max-width: 479px) {
  #download-popup.flash-message-popup .window-popup .content-popup .paragraph1 {
    font-size: 15px;
  }
}
@media (max-height: 799px) {
  #download-popup.flash-message-popup .window-popup .content-popup .paragraph1 {
    font-size: 15px;
  }
}
#download-popup.flash-message-popup .window-popup .content-popup .paragraph2 {
  color: #57c5c9;
  font-weight: 700;
  font-size: 18px;
}
@media (max-width: 479px) {
  #download-popup.flash-message-popup .window-popup .content-popup .paragraph2 {
    font-size: 16px;
  }
}
@media (max-height: 799px) {
  #download-popup.flash-message-popup .window-popup .content-popup .paragraph2 {
    font-size: 16px;
  }
}
@media (max-width: 1199px) {
  #location {
    padding-top: 105px !important;
  }
}
.styl-map {
  color: #232f5f;
}
.styl-map h6 {
  font-size: 14px;
  font-weight: bold;
}
.styl-map p {
  font-size: 14px;
}
.styl-map .btn-next {
  font-size: 13px;
  background-color: #57c5c9;
  height: 22px;
  line-height: 9px;
  margin-top: 11px;
}
#map {
  height: 90vh;
  width: 100%;
}
.search-window {
  color: #232f5f;
}
.search-window .base {
  position: absolute;
  z-index: 1;
  top: 188px;
  border-radius: 2px;
  border: 1px solid;
  border-color: rgba(0,0,0,0.2);
  box-shadow: rgba(0,0,0,0.3) 0px 1px 4px -1px;
  background-color: #fff;
  padding: 12px 22px 7px;
  margin-left: 22px;
  max-width: 300px;
}
.search-window .base h5 {
  margin-bottom: 14px;
  font-weight: bold;
}
.search-window .base input {
  margin-bottom: 12px;
  width: 100%;
  height: 42px;
  padding-left: 20px;
  padding-right: 20px;
}
.search-window .base button {
  margin-bottom: 23px;
  width: 100%;
  background-color: #57c5c9;
  padding-left: 0;
  padding-right: 24px;
}
.search-window .base button:focus,
.search-window .base button:hover {
  color: #fff;
}
.search-window .base img {
  padding-right: 16px;
  padding-bottom: 3px;
}
.search-window .base p {
  margin-bottom: 3px;
  font-size: 14px;
}
.search-window .base a {
  color: #57c5c9;
  text-decoration: none;
  font-weight: bold;
}
.search-window .base ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}
.search-window .base ul a,
.search-window .base ul a:hover,
.search-window .base ul a:active,
.search-window .base ul a:focus,
.search-window .base ul a:visited {
  text-decoration: none;
}
.search-window .base ul .social-media {
  display: inline-block;
  margin-top: 10px;
  margin-right: 7px;
}
.search-window .base ul li {
  color: #1792a8;
  font-size: 18px;
}
.search-window .base ul li a img,
.search-window .base ul li a svg {
  width: 28px;
  padding: 0;
  cursor: pointer;
}
.search-window .base .map-filtration-label {
  color: #8f8f8f;
  font-size: 14px;
  line-height: 22px;
  position: relative;
}
.search-window .base .map-filtration-label:before {
  content: '';
  position: absolute;
  top: -5px;
  height: 1px;
  width: 100%;
  background-color: #e0e0e0;
}
.search-window .base .form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
}
.search-window .base .form-wrapper label {
  display: inline-block;
  font-family: 'Titillium Light', sans-serif;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  position: relative;
  padding-left: 20px;
  margin-right: 10px;
  margin-bottom: 0px;
  color: #232f5f;
}
.search-window .base .form-wrapper label:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 3px;
  background-color: #fff;
  border: 1px solid #cdcdcd;
}
.search-window .base .form-wrapper label:after {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin-right: 10px;
  position: absolute;
  left: 0;
  top: 3px;
  background-color: #fff;
  border: 1px solid #cdcdcd;
}
.search-window .base .form-wrapper label img {
  margin: 3px 0 0 3px;
}
.search-window .base .form-wrapper .marker-selected {
  display: none;
}
.search-window .base .form-wrapper .marker-selected:checked + label::after {
  content: "";
  color: #57c5c9;
  font-size: 30px;
  text-align: center;
  line-height: 15px;
  height: 6px;
  width: 6px;
  left: 3px;
  top: 6px;
  border-radius: 50%;
  background-color: #57c5c9;
  border: none;
}
.search-window .base .form-wrapper .marker-selected:checked + label::before {
  border-color: #57c5c9;
}
.information-campaign {
  color: #232f5f;
  padding-bottom: 120px;
}
@media (max-width: 1024px) {
  .information-campaign {
    padding-bottom: 80px;
  }
}
@media (max-width: 768px) {
  .information-campaign {
    padding-bottom: 50px;
  }
}
.information-campaign .material-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.information-campaign .material-wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  width: 31%;
}
@media (max-width: 767px) {
  .information-campaign .material-wrapper a {
    width: 100%;
  }
}
.information-campaign .material-wrapper a .svg {
  height: 29px;
}
.information-campaign .material-wrapper a p {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 12px;
}
.information-campaign .accordion-information-campaign {
  padding-bottom: 60px;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-item .accordion-header {
  display: flex;
  align-items: center;
  padding: 15px 0;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-item .accordion-header .accordion-cross {
  position: relative;
  height: 22px;
  width: 22px;
  margin-right: 22px;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-item .accordion-header .accordion-cross:before,
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-item .accordion-header .accordion-cross:after {
  content: '';
  position: absolute;
  background: #57c5c9;
  transition: transform 500ms ease;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-item .accordion-header .accordion-cross:before {
  left: 0;
  top: 10px;
  height: 2px;
  width: 22px;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-item .accordion-header .accordion-cross:after {
  left: 10px;
  top: 0;
  height: 22px;
  width: 2px;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-item .accordion-header .accordion-cross:after {
  transform-origin: center;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-item .accordion-content {
  max-height: 0;
  transition: max-height 1s ease-out;
  overflow: hidden;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-item .accordion-content .accordion-paragraph {
  font-size: 18px;
  padding: 0;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-expand .accordion-header .accordion-cross:after {
  transform: rotate(90deg);
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-expand .accordion-header .accordion-cross:before {
  transform: rotate(180deg);
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-expand .accordion-content {
  max-height: max-content;
  transition: max-height 2s ease-in;
  padding: 30px 0;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-expand .accordion-content .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-bottom: 40px;
}
.information-campaign .accordion-information-campaign .accordion-wrapper .accordion-expand .accordion-content .video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.access-code p {
  font-size: 24px;
}
@media (max-width: 768px) {
  .access-code p {
    font-size: 20px;
  }
}
@media (max-width: 424px) {
  .access-code p {
    font-size: 18px;
  }
}
.access-code .access-paragraph {
  margin-bottom: 50px;
}
.access-code .examples-heading {
  font-weight: bold;
  margin-bottom: 60px;
}
.access-code .material-wrapper {
  margin-bottom: 60px;
}
.access-code .code-heading {
  font-weight: bold;
  margin-bottom: 30px;
}
.access-code .input-button {
  display: flex;
  justify-content: center;
}
@media (max-width: 423px) {
  .access-code .input-button .form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.access-code .input-button .form-group input {
  width: 180px;
  margin-right: 16px;
  display: inline-block;
  height: 32px;
}
@media (max-width: 423px) {
  .access-code .input-button .form-group input {
    margin-bottom: 16px;
  }
}
.access-code .input-button .form-group button {
  width: 180px;
  line-height: 20px;
}
@media (max-width: 767px) {
  .access-code .input-button .form-group button {
    margin-bottom: 3px;
  }
}
.my-input .form-control {
  border-color: light-gray;
  width: inherit;
}
.my-input .form-control:focus {
  border-color: #c7c7c7;
}
.my-input input::placeholder {
  color: light-gray;
}
.rules {
  padding-bottom: 60px;
  font-size: 18px;
  color: #232f5f;
}
.privacy-policy {
  padding-bottom: 60px;
  font-size: 18px;
  color: #232f5f;
}
#payment-result-text-container {
  text-align: center;
  padding-bottom: 60px;
}
#payment-result-text-container h1,
#payment-result-text-container h2 {
  padding: 0;
  margin: 0;
  font-size: 25px;
}
#payment-result-text-container h1 p,
#payment-result-text-container h2 p {
  color: #232f5f;
  background: transparent;
  font-size: 25px;
  line-height: 25px;
  padding: 0;
  margin: 0;
}
#payment-result-text-container h1 {
  margin-top: 20px;
}
#payment-result-text-container h2 {
  background: transparent;
  margin-top: 10px;
}
#payment-result-text-container p {
  font-size: 15px;
  padding-top: 20px;
}
#payment-result-text-container p a {
  color: #57c5c9;
}
#testimonies #page-header {
  margin-bottom: 0;
}
#testimonies .item {
  padding: 120px 0;
  color: #232f5f;
}
@media (max-width: 1024px) {
  #testimonies .item {
    padding: 90px 0;
  }
}
@media (max-width: 768px) {
  #testimonies .item {
    padding: 80px 0;
  }
}
@media (max-width: 576px) {
  #testimonies .item {
    padding: 60px 0;
  }
}
#testimonies .item:nth-child(even) {
  background-color: #f5f5f5;
}
#testimonies .item .place {
  font-weight: bold;
  margin-bottom: 40px;
}
@media (max-width: 576px) {
  #testimonies .item .place {
    margin-bottom: 30px;
  }
}
#testimonies .item .description {
  margin-bottom: 40px;
}
#testimonies .item .person {
  font-weight: 300;
}
.pagination-navigation {
  margin: 60px 0;
}
@media (max-width: 576px) {
  .pagination-navigation {
    margin: 40px 0;
  }
}
.pagination-navigation span {
  font-size: 12px;
}
.pagination-navigation a {
  margin: 0 5px;
}
.pagination-navigation a:hover {
  text-decoration: none;
}
.pagination-navigation .blogPostList__pagination__pageNumber {
  color: #57c5c9;
  font-size: 12px;
}
.pagination-navigation .pageNumberIsActive {
  font-size: 12px;
  font-weight: bold;
  color: #232f5f;
}
.pagination-navigation .blogPostList__pagination__next {
  font-size: 12px;
  color: #232f5f;
  font-weight: bold;
}
.pagination-navigation .blogPostList__pagination__next:hover {
  color: #232f5f;
}
.pagination-navigation .blogPostList__pagination__prev {
  font-size: 12px;
  color: #232f5f;
  font-weight: bold;
}
.pagination-navigation .blogPostList__pagination__prev:hover {
  color: #232f5f;
}
.my-form .form-group {
  margin-bottom: 20px;
}
.my-form .form-group label {
  font-weight: 400;
}
.my-form .input-group {
  margin-bottom: 11px;
  width: 100%;
}
.my-form .form-control {
  border-color: #d7d7d7;
  height: 40px;
}
.my-form .has-error .form-control {
  border-color: #ce8483;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 6px #ce8483;
}
.my-form input {
  color: #232f5f;
  font-weight: 600;
}
.my-form input::-webkit-input-placeholder {
  color: #b1b1b1;
  opacity: 1;
  font-weight: 400;
}
.my-form input::-moz-placeholder {
  color: #b1b1b1;
  opacity: 1;
  font-weight: 400;
}
.my-form input:-ms-input-placeholder {
  color: #b1b1b1;
  opacity: 1;
  font-weight: 400;
}
.my-form input:-moz-placeholder {
  color: #b1b1b1;
  opacity: 1;
  font-weight: 400;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(87,197,201,0.77);
  z-index: 1000000;
}
.ball {
  position: relative;
  width: 78px;
  height: 78px;
  margin: auto;
  top: calc(50% - 39px);
}
.ball .wBall {
  position: absolute;
  width: 74px;
  height: 74px;
  opacity: 0;
  transform: rotate(225deg);
  -o-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  animation: orbit 6.96s infinite;
  -o-animation: orbit 6.96s infinite;
  -ms-animation: orbit 6.96s infinite;
  -webkit-animation: orbit 6.96s infinite;
  -moz-animation: orbit 6.96s infinite;
}
.ball .wBall .wInnerBall {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  left: 0px;
  top: 0px;
  border-radius: 10px;
}
.ball #wBall_1 {
  animation-delay: 1.52s;
  -o-animation-delay: 1.52s;
  -ms-animation-delay: 1.52s;
  -webkit-animation-delay: 1.52s;
  -moz-animation-delay: 1.52s;
}
.ball #wBall_2 {
  animation-delay: 0.3s;
  -o-animation-delay: 0.3s;
  -ms-animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
}
.ball #wBall_3 {
  animation-delay: 0.61s;
  -o-animation-delay: 0.61s;
  -ms-animation-delay: 0.61s;
  -webkit-animation-delay: 0.61s;
  -moz-animation-delay: 0.61s;
}
.ball #wBall_4 {
  animation-delay: 0.91s;
  -o-animation-delay: 0.91s;
  -ms-animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  -moz-animation-delay: 0.91s;
}
.ball #wBall_5 {
  animation-delay: 1.22s;
  -o-animation-delay: 1.22s;
  -ms-animation-delay: 1.22s;
  -webkit-animation-delay: 1.22s;
  -moz-animation-delay: 1.22s;
}
@-moz-keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
    origin: 0%;
  }
  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
    origin: 7%;
  }
  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
    origin: 30%;
  }
  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
    origin: 39%;
  }
  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
    origin: 70%;
  }
  76% {
    opacity: 0;
    transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    transform: rotate(900deg);
  }
}
@-webkit-keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
    origin: 0%;
  }
  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
    origin: 7%;
  }
  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
    origin: 30%;
  }
  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
    origin: 39%;
  }
  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
    origin: 70%;
  }
  76% {
    opacity: 0;
    transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    transform: rotate(900deg);
  }
}
@-o-keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
    origin: 0%;
  }
  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
    origin: 7%;
  }
  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
    origin: 30%;
  }
  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
    origin: 39%;
  }
  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
    origin: 70%;
  }
  76% {
    opacity: 0;
    transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    transform: rotate(900deg);
  }
}
@keyframes orbit {
  0% {
    opacity: 1;
    z-index: 99;
    transform: rotate(180deg);
    animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    transform: rotate(300deg);
    animation-timing-function: linear;
    origin: 0%;
  }
  30% {
    opacity: 1;
    transform: rotate(410deg);
    animation-timing-function: ease-in-out;
    origin: 7%;
  }
  39% {
    opacity: 1;
    transform: rotate(645deg);
    animation-timing-function: linear;
    origin: 30%;
  }
  70% {
    opacity: 1;
    transform: rotate(770deg);
    animation-timing-function: ease-out;
    origin: 39%;
  }
  75% {
    opacity: 1;
    transform: rotate(900deg);
    animation-timing-function: ease-out;
    origin: 70%;
  }
  76% {
    opacity: 0;
    transform: rotate(900deg);
  }
  100% {
    opacity: 0;
    transform: rotate(900deg);
  }
}
.exit-cross {
  position: absolute;
  top: 20px;
  right: 14px;
  width: 26px;
  height: 26px;
  cursor: pointer;
  z-index: 1;
}
.exit-cross::before {
  position: absolute;
  right: 12px;
  top: 2px;
  content: '';
  height: 22px;
  width: 2px;
  background-color: #57c5c9;
  transform: rotate(45deg);
}
.exit-cross::after {
  position: absolute;
  right: 12px;
  top: 2px;
  content: '';
  height: 22px;
  width: 2px;
  background-color: #57c5c9;
  transform: rotate(-45deg);
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 200;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbewI1Db5yciWM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 200;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbewI1DYZyc.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 300;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbepI5Db5yciWM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 300;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbepI5DYZyc.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPAcZTIAOhVxoMyOr9n_E7fdMbWAaxWXr0.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPAcZTIAOhVxoMyOr9n_E7fdMbWD6xW.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 600;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbe0IhDb5yciWM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 600;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbe0IhDYZyc.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbetIlDb5yciWM.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPFcZTIAOhVxoMyOr9n_E7fdMbetIlDYZyc.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 200;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffAzHGIVzY4SY.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 200;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffAzHGItzYw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffGjEGIVzY4SY.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffGjEGItzYw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPecZTIAOhVxoMyOr9n_E7fdM3mDbRS.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPecZTIAOhVxoMyOr9n_E7fdMPmDQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffBzCGIVzY4SY.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffBzCGItzYw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffHjDGIVzY4SY.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffHjDGItzYw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffEDBGIVzY4SY.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/titilliumweb/v15/NaPDcZTIAOhVxoMyOr9n_E7ffEDBGItzYw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwaPGR_p.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url("https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwiPGQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjxAwXjeu.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wXg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwaPGR_p.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwiPGQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh50XSwaPGR_p.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh50XSwiPGQ.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 100;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 200;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 300;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 800;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_c6Dpp_k.woff2") format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_cqDpp_k.woff2") format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/worksans/v18/QGYsz_wNahGAdqQ43Rh_fKDp.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.payment-popup {
  opacity: 0;
  visibility: hidden;
  height: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(87,197,201,0.77);
  z-index: 1000;
  color: #232f5f;
  font-size: 12px;
  font-family: "Titillium Web", sans-serif;
  transition: all 0.2s ease-out;
}
.payment-popup.active {
  height: 100%;
  opacity: 1;
  visibility: visible;
}
.payment-popup> div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}
@media (max-width: 1199px) {
  .payment-popup> div {
    max-height: calc(100% - 106px);
    top: calc(50% + 54px);
  }
}
.payment-popup> div .payment-wrapper {
  width: 100%;
  background: #fff;
  position: relative;
  padding: 50px;
  border-radius: 5px;
}
.payment-popup .close.exit-cross {
  opacity: 1;
}
.payment-popup .close.blenda {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#helloasso-popup .payment-wrapper {
  padding: 50px 30px;
}
#payU-popup .payU-logo {
  width: 200px;
  display: block;
  margin: -30px auto 0;
}
#payU-popup .amount {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}
#payU-popup .amount label {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
}
#payU-popup .amount label span {
  font-weight: normal;
  padding-top: 5px;
}
#payU-popup .text-fields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}
#payU-popup .text-fields label {
  display: flex;
  flex-direction: column;
}
#payU-popup .text-fields label span {
  font-weight: normal;
  margin-bottom: 5px;
}
#payU-popup .text-fields label input {
  font-weight: normal;
}
#payU-popup .text-fields label.other-amount {
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-out;
  height: 1px;
}
#payU-popup .text-fields label.other-amount.active {
  height: auto;
  visibility: visible;
  opacity: 1;
}
#payU-popup .statements {
  margin-top: 50px;
}
#payU-popup .statements h1 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
#payU-popup .statements h2 {
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px;
  margin-top: 10ps;
}
#payU-popup .statements label {
  display: flex;
  flex-direction: row;
}
#payU-popup .statements label input {
  flex-shrink: 0;
}
#payU-popup .statements label div {
  width: 13px;
  height: 13px;
  flex-shrink: 0;
}
#payU-popup .statements label span {
  font-weight: normal;
  padding-left: 30px;
}
#payU-popup .submit-btn {
  display: block;
  margin: 40px auto 0;
  width: 300px;
  max-width: 100%;
}
#payU-popup .ball {
  display: none;
  margin-top: 0;
}
#payU-popup .ball.active {
  display: block;
  margin-top: 20px;
}
#payU-popup .ball .wBall .wInnerBall {
  background-color: #57c5c9;
}
.mobile-app {
  padding: 50px 0;
  min-height: 100vh;
  color: #1792a8;
  display: flex;
}
.mobile-app .container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.mobile-app .container .logo {
  margin-bottom: 42px;
}
.mobile-app .container .logo img {
  width: 200px;
}
.mobile-app .container h5 {
  margin-bottom: 32px;
  font-size: 20px;
}
.mobile-app .container p {
  margin-bottom: 35px;
  font-size: 14px;
}
.mobile-app .container .app {
  margin-bottom: 27px;
}
.mobile-app .container .app img {
  margin-bottom: 7px;
}
.mobile-app .container a {
  text-decoration: none;
  font-size: 16px;
  color: #57c5c9;
}
