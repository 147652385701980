.mobile-app
    padding: 50px 0
    min-height: 100vh
    color: primary-font-color
    display: flex
    .container
        display: flex
        justify-content: center
        flex-direction: column

        .logo
            margin-bottom: 42px
            img
                width: 200px

        h5
            margin-bottom: 32px
            font-size: 20px
        p
            margin-bottom: 35px
            font-size: 14px
        .app
            margin-bottom: 27px
            img
                margin-bottom: 7px
        a
            text-decoration: none
            font-size: 16px
            color: brand-color
