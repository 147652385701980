.how-works
	padding-top: 80px
	margin-top: 0px 
	background-repeat: no-repeat
	background-size: 1223px
	background-position: 45% 166px
	@media(max-width: 1199px)
		background-image: none !important
       																																								

	.content
		// overflow: hidden
		position: relative
		   


		> .row-flex
			display: flex
			flex-direction: row
			flex-wrap: wrap
			justify-content: center
			.item-wrapper
				&:nth-child(-n+3)
					.item	
						a
							margin-bottom: 168px
				&:nth-child(-n+4)
					.item	
						a
							@media(max-width: 1199px)
								margin-bottom: 110px

				&:nth-child(-n+5)
					.item	
						a
							@media(max-width: 767px)
								margin-bottom: 110px
			// > div
			//     &:nth-child(1)
			//         .item-img
			//             img
			//             &::before
			//                 content: "1"	
			//     &:nth-child(2)
			//         .item-img
			//             img
			//             &::before
			//                 content: "2"	
			//     &:nth-child(3)
			//         .item-img
			//             img
			//             &::before
			//                 content: "3"
			//     &:nth-child(4)
			//         .item-img
			//             img
			//             &::before
			//                 content: "4"
			//     &:nth-child(5)
			//         .item-img
			//             img
			//             &::before
			//                 content: "5"
			//     &:nth-child(6)
			//         .item-img
			//             img
			//             &::before
			//                 content: "6"			


		.items-wrapper
			.item-wrapper
				@media(min-width: 1200px)
					&:nth-child(-n+3)
						.item
							.item-img
								.cover-img
									.ball
										bottom: 91px
				@media(min-width:1200px)
					&:nth-child(4)
						.item
							.item-img
								.cover-img
									.ball
										bottom: 31px
				
				@media(min-width: 2260px)
					&:nth-child(4)
						.item
							.item-img
								.cover-img
									.ball				
										right: 118%    
				@media(max-width: 1600px)
					&:nth-child(4)
						.item
							.item-img
								.cover-img
									.ball				
										right: 101% 



				@media(min-width: 1441px)
					width: 30%
				@media(min-width: 1200px) and (max-width: 1440px)
					width: 33%
				@media(min-width: 768px) and (max-width: 1199px)
					width: 49%										   				
				@media(max-width: 767px)
					width: 70%   
				@media(max-width: 500px)
					width: 80%         
				@media(max-width: 458px) 
					width: 90%                              
				@media(max-width: 412px)
					width: 100%             


				.item
					display: flex
					justify-content: flex-end
					flex-direction: column
					// height: 100%
					// a
					//     &:last-child                
					//         @media(max-width: 1024px)
					//             margin-bottom: 50px	    
					@media(max-width:1199px) and (min-width: 992px)
						padding: 0 22px   

						

					.item-img
				 
						.cover-img
							position: relative
							display: inline-block
							@media(max-width: 1199px)
								display: block
							@media(max-width: 991px) and (min-width: 768px)
								padding: 0 58px  


							img
								max-width: 100%
								padding-bottom: 25px
								height: 205px
								display: inline-block
								@media(max-width: 600px) 
									height: 185px 
								@media(max-width: 375px) 
									height: 157px                                                                   
								@media(max-width: 320px) 
									height: 146px  
					
							.ball
								color: white
								font-size: 33.87px
								font-weight: bold
								background-color: brand-color
								height: 52px
								right: calc(100% - 52px)
								width: 52px	
								border-radius: 50%     
								bottom: 18px 
								right: 106% 
								display: inline-block
								position: absolute
								top: initial
																  
								@media(max-width:1199px)
									left: -6px
									bottom: 22px   
								@media(max-width: 991px)
									left: -1px   
								@media(max-width: 375px)
									left: 0px                                      
								@media(max-width: 600px)
									height: 43px
									width: 43px
									font-size: 28.87px                                                             
									 

							// &::before
							//     content: ""


							//     color: white
							//     font-size: 33.87px
							//     display: block
							//     position: absolute
							//     background-color: brand-color
							//     height: 52px
							//     bottom: 30px
							//     right: calc(100% - 52px)
							//     width: 52px	
							//     border-radius: 50%
							&.circle
								img
								// &::before						
									bottom: 86px
									@media(max-width:991px)
										bottom:30px						
						

							// height: 235px
							// width: auto
					.paragraph
						height: 80px
						@media(max-width: 600px)
							height: 66px
						

					a
						font-size: 13px
						height: 32px
						padding: 18px 25px
						display: flex
						justify-content: center
						@media(max-width: 425px)
							font-size: 12px								
						margin-top: 20px
						@media(max-width: 480px)
							margin-top: 5px
						margin-right: auto
						margin-left: auto
						width: auto																		
						background-color: transparent									
						&:hover
							background-color: brand-color			
						&:focus
							background-color: brand-color	
							

		.steps-line
			position: absolute
			top: 0
			left: 0
			width: 100%

			image
				width: 100%
