.day-plan
    display: block;
    padding: 20px 0;
    .day-bar
        width: 100%;
        height: 52px;
        background-color: #daf2f3;
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-left-width: 0;
        border-right-width: 0;
        border-style: solid;
        border-color: #57c5c9;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        @media(min-width: 992px)
            padding-left: 100px;
        & *
            font-family: 'Titillium Web', sans-serif;
            font-size: 20px;
            line-height: 29px;
            margin: 0;
            color: #232f5f;
.program-element
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 20px 10px 40px;
    margin: 0;
    @media(min-width: 992px)
        padding: 10px 0 10px 120px;
    .hour_granatowy,
    .hour_jasnoniebieski
        width: 10%;
        position: relative;
        @media(min-width: 992px)
            width: 15%;
        & *
            font-family: 'Titillium Web', sans-serif;
            font-size: 18px;
            line-height: 24px;
            margin: 0;
            @media(min-width: 992px)
                font-size: 24px;
        &::before
            content: '';
            width: 5px;
            height: 5px;
            position: absolute;
            top: 10px;
            left: -20px;
            border-radius: 50%;
            background-color: #1792a8;
    
    .activity_jasnoniebieski,
    .activity_granatowy
        width: 70%;
        @media(min-width: 768px)
            width: 80%;
        @media(min-width: 992px)
            width: 85%;
        & *
            font-family: 'Titillium Web', sans-serif;
            font-size: 18px;
            line-height: 24px;
            margin: 0;
            @media(min-width: 992px)
                font-size: 24px;

    .hour_granatowy,
    .activity_granatowy
        & *
            color: #232f5f;

    .hour_jasnoniebieski,
    .activity_jasnoniebieski
        & *
            color: #57c5c9;

