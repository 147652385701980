#contact
    #contact-data 
        padding-bottom: 80px
        @media(max-width: 768px)
            padding-bottom: 50px        
        .contact-header
            @media(max-width: 767px)
                margin-top: 40px
            height: 42px
            editorText()
            margin-bottom: 40px
    ul
        list-style-type: none
        padding: 0     
        li
            color: primary-font-color
            font-size: 18px        		
        a, a:hover, a:active, a:focus, a:visited
            // color: primary-font-color
            text-decoration: none
    .social-media
        display: inline
        li
            display: inline
            a         
                img
                svg
                    width: 28px
                    margin-top: 10px
                    margin-right: 7px                     