.how-work-newpastoral
    background-color: like-white
    padding-top: 558px
    margin-top: -500px
    @media(max-width:320px)
        margin-top: -387px
    padding-bottom: pad-bot
    @media(max-width: 1024px)
        padding-bottom: 80px
    @media(max-width: 768px)
        padding-bottom: 50px    
    h3
        text-align: right
        color: buttons-color
        position: relative
        display: inline-block
        margin-bottom: 0
        &::before
            content: ""
            display: block
            position: absolute
            background-color: #fefefe
            height:  10px
            bottom: 10px
            right: 112%
            width: 693px