#homepage
	.what-is
		position: relative
		overflow: hidden

		.content 
			border-top-right-radius: 7px 
			padding-top: pad-top
			padding-bottom: pad-bot
			position: relative
			z-index: 2
			@media(max-width: 1024px)
				padding-top: 80px
			@media(max-width: 768px)
				padding-top: 50px
			@media(max-width: 1024px)
				padding-bottom: 80px
			@media(max-width: 768px)
				padding-bottom: 50px	

			.front
				position: relative
				z-index: 1

			h3
				color: buttons-color
			p
				color: ligt-grey-font-color
			a
				text-decoration: none				
				button
					margin-bottom: 25px
					margin-top: 60px
					img
						@media(max-width: 425px)
							display: none				

			&::after
				content: ""
				position: absolute
				right: -50vw
				display: block
				background-color: white
				top: 0
				height: 100%
				width: 150vw
				z-index: 0
				border: 2px solid white 
				border-top-right-radius: 8px
				
				@media( min-width: 1024px )
					right: 8.33%

			.app
				img
				&:first-child
					margin-right: 14px
					@media(max-width: 347px)
						margin-bottom: 14px
		.img-right	
			// position: absolute
			// left: 80%
			// top: 0
			// display: none
			@media(max-width: 991px )
				text-align: center
			img
				max-width: 100%
				height auto
				transform: translateX(25px)
				@media(max-width: 991px)
					transform: none
					margin-top: space


			// .img-tablet
				// width: 618px
				// height: 465px
				// position: absolute	
				// top: 26px
				// left: 264px
				// background-size: cover
			// .glare
			// 	position: absolute	
			// 	top: 5px
			// 	left: 583px				

			
