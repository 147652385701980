.underline-header-wrapper
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 50px 0;
    @media(min-width: 992px)
        margin: 100px 0;
    .underline-header
        color: #232f5f !important;
        font-size: 30px;
        line-height: 32px;
        font-weight: bold;
        display: block;
        position: relative;
        white-space: nowrap;
        @media(min-width: 992px)
            font-size: 45px;
            line-height: 32px;
    .underline-header::after
        content:'';
        height: 5px;
        width: 110%;
        background-color: #57c5c9;
        position: absolute;
        z-index: -1;
        left: -5%;
        bottom: -5px; 
