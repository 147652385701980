.information-campaign
    .accordion-information-campaign
        padding-bottom: 60px
        .accordion-wrapper
            .accordion-item
                .accordion-header
                    display: flex
                    align-items: center
                    padding: 15px 0
                    .accordion-cross
                        position: relative
                        height: 22px
                        width: 22px
                        margin-right: 22px
                        &:before,
                        &:after
                            content: ''
                            position: absolute
                            background: brand-color
                            transition: transform 500ms ease
                        &:before
                            left: 0
                            top: 10px
                            height: 2px
                            width: 22px
                        &:after
                            left: 10px
                            top: 0
                            height: 22px
                            width: 2px
                        &:after
                            transform-origin: center

                .accordion-content
                    max-height: 0
                    transition: max-height 1s ease-out
                    overflow: hidden


                    .accordion-paragraph
                        font-size: 18px
                        padding: 0

            .accordion-expand
                .accordion-header
                    .accordion-cross
                        &:after
                            transform: rotate(90deg)
                        &:before
                            transform: rotate(180deg)

                .accordion-content
                    max-height: max-content
                    transition: max-height 2s ease-in
                    padding: 30px 0
                    .video-responsive
                        overflow:hidden
                        padding-bottom: 56.25%
                        position: relative
                        height: 0
                        margin-bottom: 40px

                        iframe
                            left: 0
                            top:0
                            height: 100%
                            width: 100%
                            position: absolute
