.button-wrapper
    display: flex;
    .link-bialy-button
        outline: none;
        text-decoration: none;
        cursor: pointer;
    .button-events
        display: flex; 
        align-items: center;
        padding: 0 38px 0 55px;
        color: #1792a8;
        background-color: white;
        font-family: 'Titillium Web', sans-serif;
        font-weight: bold; 
        font-size: 14px;  
        border-radius: 23px;
        outline: none;
        box-shadow: none;
        border: none;
        cursor: pointer;
        text-decoration: none;
        @media(min-width: 992px)
            font-size: 18px;  
        .big-add-symbol
            font-size: 30px;
            margin: 0px 0px 7px 20px;

