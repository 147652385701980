#popup-text
    font-family: 'Titillium Web', sans-serif
    color: buttons-color
    position: fixed
    top: 0
    left: 0
    height: 100%
    width: 100%
    background-color: rgba( brand-color, 0.77 )
    z-index: 999999999
    display: none

    .popup-close-blenda
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0

    .popup-wrapper
        position: absolute
        background-color: #fff
        top: 2%
        height: 96%
        width: 700px
        max-width: 90%
        left: 50%
        transform: translateX(-50%)
        border-radius: 10
        overflow-y: auto

        display: flex
        flex-direction: column
        justify-content: space-between

        .popup-header
            width: 100%
            background-color: #57c5c9;
            padding: 25px 50px
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            border-bottom: 1px solid #e5e5e5

            h3
                font-size: 25px
                color: white
                margin: 0 0 8px 0
                padding: 0
            
            h4
                font-size: 14px
                color: white
                margin: 0
                padding: 0

            .exit-cross
                width: 23px
                height: 23px
                top: 34px
                right: 50px


                &::before,
                &::after
                    background-color: white
                    height: 23px
                    top: -3px

        .nano-content
            padding: 50px

        .popup-content
            &:nth-last-child(1)
                margin-bottom: 0        
            h1
                font-size: 18px
                color: brand-color
                padding: 0
                margin: 25px 0
                &:nth-child(1)
                    margin: 0 0 25px 0

                strong
                    font-size: 18px
                    color: brand-color
                    padding: 0
            p
                font-size: 14px
                margin: 0

                &:nth-last-child(1)
                    margin-bottom: 0
            h3
                font-size: 18px            
                span
                    margin-bottom: 25px
                    font-size: 18px
            h4
                font-size: 14px            
                margin: 0
            ul
                padding-left: 18px
                margin: 0
                margin-bottom: 0
            li
                margin-bottom: 10px
            
            blockquote
                border: none
                padding: 0
                font-size: 14px
                margin: 0


        .buttons
            display: flex
            flex-wrap: wrap
            justify-content: space-between
            margin-top: 50px
            .btn
                font-size: 13px
                padding: 0 22px
                height: 100%
                margin-bottom: 12px
