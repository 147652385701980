.ui-selectmenu-button.ui-button
	color: white
	background-color: transparent
	border: 1px solid white
	border-radius: 4px
	font-weight: bold
	font-size: 13px
	height: 22px
	width: 56px
	float: right
	padding: 0 6px
	line-height: 23px
	height: 25px

	.ui-icon
		background-image: url('../img/select-arrow.png')
		background-position: center
		transition: transform 0.4s ease
		margin-top: 3px
	&:focus
		outline-color:white					

		.ui-selectmenu-text
			height:18px
			width: 18px
		&:focus
			outline-color: white				

	&.ui-selectmenu-button-open
		.ui-icon
			transform: rotate(180deg)

.ui-selectmenu-button.ui-button
	background: primary-font-color

.btn-return
	color: white
	background: primary-font-color
	border: 1px solid white
	border-radius: 4px
	font-weight: bold
	font-size: 15px
	text-align: center
	height: 25px
	width: 167px
	margin-right: 11px
	display: inline-block
	
	a
		color: #fff
		text-decoration: none 

	&:focus
		outline-color: white


.ui-selectmenu-menu
	margin: -1px

.ui-selectmenu-menu.ui-front.ui-selectmenu-open 
	top: 52px
	left: 1775.7px
	position: fixed
	right: 70px
	z-index: 99999999
	display: inline-table
	.ui-menu.ui-corner-bottom.ui-widget.ui-widget-content.overflow
		border-radius: 3px

.ui-widget-content	
	background: primary-font-color
	color: white

.ui-widget.ui-widget-content
	border: 1px solid white
	border-top: transparent
	border-radius: 4px


.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover 
	background: primary-font-color 
	border: 1px solid primary-font-color


.select2-selection.select2-selection--single
	background-color: white
.select2-offscreen 
	position: fixed

.select2-container
	.select2-selection--single
		height: 40px

.select2-container--default
	&.select2-container--open 
		.select2-selection--single 
			.select2-selection__arrow 
				b
					border-color: transparent
					border-width: 5px
					transform: rotate(-180deg)

	.select2-selection--single
		border: 1px solid #ccc
		border-radius: 4px

		.select2-selection__rendered
			line-height: 40px
			padding-left: 35px
			color: ligt-grey-font-color
		&:focus
			outline-color: #d7d7d7
			

		.select2-selection__arrow
			width: 94px
			border-left: 1px solid #ccc		
			height: calc(100% - 1px)
			
			b
				background-image: url('../img/form-arrow.png')
				background-position: center
				background-repeat: no-repeat
				border-color: transparent
				border-style: solid
				border-width: 5px
				height: 6px
				left: 50%
				margin-left: -4px
				margin-top: -4px
				position: absolute
				top: 50%
				width: 9px					
				transition: transform 0.4s ease
				
	.select2-results__option[aria-disabled=true]
		display:none
	.select2-results__option--highlighted[aria-selected]
		background-color: #f0f0f0
		color: #bcbcbc
	.select2-results__option[aria-selected=true] 
		background-color: #f0f0f0


// .select2-results
// 	height: 211px
.select2-results__options
	li
		padding-left:35px
		color: #bcbcbc
.select2-container--default
	width: 100%  !important
	.select2-search--dropdown 
		.select2-search__field
			outline-color: #d7d7d7



.select-background .ui-.buttonui-menu-item-wrapper:hover, .select-background .ui-button:active, .select-background .ui-widget-content .ui-menu-item-wrapper, .select-background .ui-widget-header .ui-menu-item-wrapper, .select-background a.ui-button:active
	text-transform: uppercase
	font-size: 15px
	font-family: 'Titillium Web', sans-serif
	margin: -1px


.language-white
	.ui-widget-content	
		background: white
		color: primary-font-color
	.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover
		background: white
		border: 1px solid brand-color
	.ui-widget.ui-widget-content 
		border: 1px solid red
		width: 55px

// .ui-widget.ui-widget-content
// 	border-color: light-blue
// 	border-radius: 5px

	
// .ui-widget-content .ui-menu-item-wrapper, .ui-widget-header .ui-menu-item-wrapper, a.ui-button:active, .ui-button:active, .ui-.buttonui-menu-item-wrapper:hover
// 	background: #fff 
// 	color: primary-font-color
// 	font-weight: bold
// 	border: none
// 	margin: 0

.select-background
	position: absolute !important
	.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover 
		background: brand-color
		color: white
	.ui-widget-content	
		background: white
		color: primary-font-color
	.ui-widget-content .ui-menu-item-wrapper, .ui-widget-header .ui-menu-item-wrapper, a.ui-button:active, .ui-button:active, .ui-.buttonui-menu-item-wrapper:hover
		font-weight: bold
		border: none
		margin: 0
	.ui-widget.ui-widget-content
		border-color: brand-color
		border-radius: 5px		
	.ui-widget.ui-widget-content
		width: 70px !important
	.ui-selectmenu-menu.ui-front.ui-selectmenu-open
		position: static
	.select2-offscreen 
		position: static	


.select-button
	.ui-selectmenu-button.ui-button
		margin-left: 0px


.select-open
	.ui-widget.ui-widget-content
		width: 56px !important	

.net.panels
	.ui-selectmenu-menu.ui-front.ui-selectmenu-open
		position: fixed !important


