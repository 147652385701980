.preloader
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background: rgba(87,197,201,.77)
	z-index: 1000000

.ball
	position: relative
	width: 78px
	height:78px
	margin:auto
	top: calc( 50% - 39px )

	.wBall
		position: absolute
		width: 74px
		height: 74px
		opacity: 0
		transform: rotate(225deg)
		-o-transform: rotate(225deg)
		-ms-transform: rotate(225deg)
		-webkit-transform: rotate(225deg)
		-moz-transform: rotate(225deg)
		animation: orbit 6.96s infinite
		-o-animation: orbit 6.96s infinite
		-ms-animation: orbit 6.96s infinite
		-webkit-animation: orbit 6.96s infinite
		-moz-animation: orbit 6.96s infinite

	.wBall .wInnerBall
		position: absolute
		width: 10px
		height: 10px
		background: rgb(255,255,255)
		left:0px
		top:0px
		border-radius: 10px

	#wBall_1
		animation-delay: 1.52s
		-o-animation-delay: 1.52s
		-ms-animation-delay: 1.52s
		-webkit-animation-delay: 1.52s
		-moz-animation-delay: 1.52s

	#wBall_2
		animation-delay: 0.3s
		-o-animation-delay: 0.3s
		-ms-animation-delay: 0.3s
		-webkit-animation-delay: 0.3s
		-moz-animation-delay: 0.3s

	#wBall_3
		animation-delay: 0.61s
		-o-animation-delay: 0.61s
		-ms-animation-delay: 0.61s
		-webkit-animation-delay: 0.61s
		-moz-animation-delay: 0.61s

	#wBall_4
		animation-delay: 0.91s
		-o-animation-delay: 0.91s
		-ms-animation-delay: 0.91s
		-webkit-animation-delay: 0.91s
		-moz-animation-delay: 0.91s

	#wBall_5
		animation-delay: 1.22s
		-o-animation-delay: 1.22s
		-ms-animation-delay: 1.22s
		-webkit-animation-delay: 1.22s
		-moz-animation-delay: 1.22s


$keyframe-name = orbit

@keyframes {$keyframe-name} {
	0% {
		opacity: 1;
		z-index:99;
		transform: rotate(180deg);
		animation-timing-function: ease-out;
	}

	7% {
		opacity: 1;
		transform: rotate(300deg);
		animation-timing-function: linear;
		origin:0%;
	}

	30% {
		opacity: 1;
		transform:rotate(410deg);
		animation-timing-function: ease-in-out;
		origin:7%;
	}

	39% {
		opacity: 1;
		transform: rotate(645deg);
		animation-timing-function: linear;
		origin:30%;
	}

	70% {
		opacity: 1;
		transform: rotate(770deg);
		animation-timing-function: ease-out;
		origin:39%;
	}

	75% {
		opacity: 1;
		transform: rotate(900deg);
		animation-timing-function: ease-out;
		origin:70%;
	}

	76% {
		opacity: 0;
		transform:rotate(900deg);
	}

	100% {
		opacity: 0;
		transform: rotate(900deg);
	}
}