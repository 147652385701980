.with-us
		padding-top: pad-top
		@media(max-width: 1024px)
			padding-top: 80px
		@media(max-width: 768px)
			padding-top: 50px		
		color: buttons-color
		background-color: white

		h3
			&, & *		
				margin-bottom: 100px 
				@media(max-width: 1024px)
					margin-bottom: 70px
				@media(max-width: 768px)
					margin-bottom: 40px				 
		.wrapper
			display: flex
			flex-direction: row
			flex-wrap: wrap
			align-items: center
			justify-content: center		
			position: relative
			margin-bottom: 70px	

			.item
				width: 120px
				height: 120px
				z-index: 2
				margin: 30px 10px
				position: relative
				&::before
					content: ""
					width: 120%
					height: 3px
					position: absolute
					background-color: #57c5c9
					top: 50%
					left: -10%
					z-index: 0
				@media(min-width: 425px)
					width: 150px
					height: 150px
					margin: 30px 20px
					&::before
						content: ""
						width: 130%
						height: 3px
						position: absolute
						background-color: #57c5c9
						top: 50%
						left: -15%
						z-index: 0
				@media(min-width: 768px)
					width: 250px
					height: 250px
					margin: 30px 50px
					&::before
						content: ""
						width: 140%
						height: 3px
						position: absolute
						background-color: #57c5c9
						top: 50%
						left: -20%
						z-index: 0
				@media(min-width: 992px)
					&::before
						content: ""
						width: 150%
						height: 3px
						position: absolute
						background-color: #57c5c9
						top: 50%
						left: -25%
						z-index: 0
				.frames
					height: 100%
					width: 100%
					z-index: 10
					position: relative
					background-size: cover
					background-position: center
					background-repeat: no-repeat
					border-radius: 50%
				h5
					&, & *					
						text-align: center
						font-weight: bold
						color: buttons-color
						margin-top: -32px
						position: relative
						z-index: 45
						margin-bottom: 40px
						font-size: 14px
						padding: 5px 0
						background-color: white
						@media(min-width: 425px)
							margin-top: -32px
						@media(min-width: 768px)
							margin-top: -40px
							font-size: 21px
						@media(min-width: 1200px)
							font-size: 23px
							margin-top: -48px



#download
	color: buttons-color
	padding-top: 315px
	@media(max-width: 1024px)
		padding-top: 220px
	@media(max-width: 768px)
		padding-top: 180px	

	padding-bottom: pad-bot
	@media(max-width: 1024px)
		padding-bottom: 80px
	@media(max-width: 768px)
		padding-bottom: 50px		
	margin-top: -150px
	background-color: like-white
	h3
		margin-bottom: 20px
	p
		font-weight: 200
		margin-bottom: space

	.app
		img
		&:first-child
			margin-right: 14px
			@media(max-width: 347px)
				margin-bottom: 14px