#subpage-team
	#crew
		.crew-header
			margin-bottom: 30px			
		.crew-paragraph
			margin-bottom: 45px
		h6
			&, & *			
				font-size: 18px
				color: brand-color
				margin-bottom: 70px
				line-height: 30px
				@media(max-width: 768px)
					font-size: 16px		
				@media(max-width: 768px)
					font-size: 16px
				@media(max-width: 320px)
					font-size: 14px	

	.with-us	
		color: buttons-color
		padding-top: 0

		h3
			&, & *			
				font-size: 24px	
				margin-bottom: 45px
          
		.wrapper
			.item				 										                                                            
				h5
					&, & *					
						color: buttons-color