#latest-news
    padding-bottom: 50px
    h3.all-news-header
        font-size: 30px
        line-height: 69px
        font-family: 'Titillium Web',sans-serif
        font-weight: bold
        color: primary-color
        margin-bottom: 50px
    .last-news-wrapper
        display: flex
        flex-direction: row
        justify-content: center
        position: relative
        border-radius: 15px
        width: 100%
        height: 420px
        @media(min-width: 768px)
            height: 560px
        .picture-wrapper
            width: 100%
            height: 100%
            border-radius: 20px
            position: relative
            background-size: cover
            background-position: center
            background-repeat: no-repeat
            z-index: 10
            .info-wrapper
                width: 100%
                min-height: 100px
                position: absolute
                cursor: pointer 
                bottom: 0 
                z-index: 11
                border-top-left-radius: 0
                border-top-right-radius: 0
                border-bottom-left-radius: 15px
                border-bottom-right-radius: 15px 
                background-color: rgba(41, 49, 88, 0.65)
                padding: 50px 10%
                @media(min-width: 768px)
                    min-height: 280px
                    padding: 50px 20% 50px 40px
                @media(min-width: 1200px)
                    min-height: 280px
                    padding: 50px 40% 50px 40px
                p.news-date
                    font-size: 14px
                    line-height: 24px
                    color: white
                    font-family: 'Titillium Web',sans-serif
                h5.news-title
                    font-size: 30px
                    margin: 0 !important
                    line-height: 35px
                    color: white
                    font-family: 'Titillium Web',sans-serif
                    font-weight: bold
                    @media(min-width: 768px)
                        font-size: 48px
                        line-height: 52px
                p.news-short-description
                    font-size: 18px
                    line-height: 24px
                    color: white
                    margin-top: 5px
                    font-family: 'Titillium Web',sans-serif

#rest-news
    padding-bottom: 80px
    h3.all-news-header
        font-size: 30px
        line-height: 69px
        font-family: 'Titillium Web',sans-serif
        font-weight: bold
        color: primary-color
        margin-bottom: 50px
    .rest-news-wrapper
        display: flex
        flex-direction: row
        justify-content: space-between
        flex-wrap: wrap
        position: relative
        border-radius: 15px
        width: 100%
        .single-news-wrapper
            display: flex
            width: 100%
            flex-direction: column
            justify-content: space-between
            margin-bottom: 50px
            @media(min-width: 600px)
                width: 100%
                padding: 0 10%
            @media(min-width: 768px)
                width: 48%
                padding: 0
            @media(min-width: 1200px)
                width: 31%
            .news-image
                height: 300px
                width: 100%
                z-index: 15
                border-radius: 20px
                position: relative
                background-size: cover
                background-position: center
                background-repeat: no-repeat
                background-position: center
                margin-bottom: 20px
                cursor: pointer
                overflow: hidden
                @media(min-width: 600px)
                    height: 390px
                .blend
                    opacity: 0
                    position: absolute
                    width: 100%
                    height: 100%
                    border-radius: 15px
                    transition: opacity 0.3s ease-out
                    background-color: rgba(41, 49, 88, 0.65)
                    &:hover
                        opacity: 1
                    img
                        position: absolute
                        top: calc(50% - 35px)
                        left: calc(50% - 35px)
                        width: 70px
                        height: 70px
            .info-wrapper
                width: 100%
                flex: auto;
                p.news-date
                    font-size: 14px
                    line-height: 24px
                    color: primary-color
                    margin: 0 !important
                    font-family: 'Titillium Web',sans-serif
                h5.news-title
                    font-size: 30px
                    margin: 0 !important
                    line-height: 38px
                    color: primary-color
                    font-family: 'Titillium Web',sans-serif
                    font-weight: bold
                p.news-short-description
                    font-size: 18px
                    line-height: 24px
                    color: primary-color
                    margin-top: 15px
                    font-family: 'Titillium Web',sans-serif

    .rest-news-wrapper-no-div
        display: flex
        flex-direction: row
        justify-content: flex-start
        flex-wrap: wrap
        position: relative
        border-radius: 15px
        width: 100% 
        .single-news-wrapper
            display: flex
            width: 100%
            flex-direction: column
            justify-content: space-between
            margin-bottom: 50px
            @media(min-width: 600px)
                width: 100%
                padding: 0 10%
            @media(min-width: 768px)
                width: 48%
                padding: 0
                margin-right: 25px
                &:nth-child(2n)
                    margin-right: 0px
            @media(min-width: 1200px)
                padding: 0
                width: 31%
                margin-right: 39px
                &:nth-child(2n)
                    margin-right: 39px
                &:nth-child(3n)
                    margin-right: 0px
            .news-image
                height: 300px
                width: 100%
                z-index: 15
                border-radius: 20px
                position: relative
                background-size: cover
                background-position: center
                background-repeat: no-repeat
                background-position: center
                margin-bottom: 20px
                cursor: pointer
                overflow: hidden
                @media(min-width: 600px)
                    height: 390px
                .blend
                    opacity: 0
                    position: absolute
                    width: 100%
                    height: 100%
                    border-radius: 15px
                    transition: opacity 0.3s ease-out
                    background-color: rgba(41, 49, 88, 0.65)
                    &:hover
                        opacity: 1
                    img
                        position: absolute
                        top: calc(50% - 35px)
                        left: calc(50% - 35px)
                        width: 70px
                        height: 70px
            .info-wrapper
                width: 100%
                flex: auto;
                p.news-date
                    font-size: 14px
                    line-height: 24px
                    color: primary-color
                    margin: 0 !important
                    font-family: 'Titillium Web',sans-serif
                h5.news-title
                    font-size: 30px
                    margin: 0 !important
                    line-height: 38px
                    color: primary-color
                    font-family: 'Titillium Web',sans-serif
                    font-weight: bold
                p.news-short-description
                    font-size: 18px
                    line-height: 24px
                    color: primary-color
                    margin-top: 15px
                    font-family: 'Titillium Web',sans-serif

#pagination-wrapper
    width: 100%
    display: flex
    flex-direction: row
    justify-content: center
    padding-bottom: 70px
    .navigation-wrapper
        display: flex
        flex-direction: row
        justify-content: space-between
        img
            width: 23px
            height: 19px
            margin: 0 30px
            cursor: pointer
        .empty-img
            opacity: 0    
        .blogPostList__pagination
            a.blogPostList__pagination__pageNumber
                text-decoration: none !important
                font-size: 16px !important
                line-height: 24px
                color: primary-color
                font-family: 'Titillium Web',sans-serif
                cursor: pointer
            a.blogPostList__pagination__prev
                text-decoration: none !important
            span
                text-decoration: none
                font-size: 16px !important
                line-height: 24px
                color: #6cd2d6
                font-family: 'Titillium Web',sans-serif
            span.dots
                color: primary-color


