.newsletter
    color: like-white-header
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    position: relative
    overflow: hidden
    height: 725px 
    margin-top: 120px
    margin-bottom: 50px 
    @media(min-width: 1440px)
        margin: 150px 60px 50px	  
    .blend
        height: 100%
        width: 100%
        background-color: rgba(35, 47, 95, 0.88)
        display: flex
        flex-direction: column
        justify-content: space-between
        @media(min-width: 992px)
            flex-direction: row
            justify-content: space-between
        .left-half
            width: 100%
            height: 50%
            @media(min-width: 992px)
                width: 50%
                height: 100%
            .content-wrapper-left
                display: flex
                flex-direction: column
                justify-content: center
                padding: 20px 50px
                height: 100%
                @media(min-width: 992px)
                    padding-left: 20%
                    padding-right: 50px
                @media(min-width: 1200px)
                    padding-left: 37%
                    padding-right: 50px
                .pictogram-wrapper
                    width: 56px
                    height: 59px
                    margin-bottom: 20px
                    background-size: cover
                    background-position: center
                    background-repeat: no-repeat
                    position: relative
                    @media(min-width: 992px)
                        width: 112px
                        height: 118px
                        margin-bottom: 0px
                    @media(min-width: 1920px)
                        margin-bottom: 40px
                .header-wrapper
                    h3.newsletter-header
                        font-size: 25px
                        line-height: 30px
                        font-family: 'Titillium Web',sans-serif
                        font-weight: bold
                        color: white
                        margin-bottom: 40px
                        @media(min-width: 576px)
                            font-size: 43px
                        @media(min-width: 992px)
                            font-size: 60px
                            line-height: 69px
                .description-wrapper
                    padding-bottom: 20px
                    @media(min-width: 992px)
                        padding-bottom: 0
                    @media(min-width: 1200px)
                        padding-bottom: 50px    
                    p.newsletter-desc
                        font-size: 16px
                        line-height: 20px
                        font-family: 'Titillium Web',sans-serif
                        color: white
                        @media(min-width: 992px)
                            font-size: 18px
                            line-height: 24px
        .right-half
            width: 100%
            height: 50%
            @media(min-width: 992px)
                width: 50%
                height: 100%
            background-color: #232f5f
            .content-wrapper-right
                display: flex
                flex-direction: column
                justify-content: center
                height: 100%
                padding: 20px 20px 20px 50px  
                @media(min-width: 992px)
                    padding-left: 11%
                    padding-right: 20%
                @media(min-width: 1200px)
                    padding-left: 11%
                    padding-right: 47%
                .input-with-description
                    p.input
                        font-size: 16px
                        line-height: 20px
                        font-family: 'Titillium Web',sans-serif
                        font-weight: bold
                        color: white
                        margin-bottom: 30px
                        @media(min-width: 992px)
                            font-size: 18px
                            line-height: 24px
                    form
                        
                        input
                            border: #6cd2d6 3px solid
                            background: none
                            font-size: 18px
                            border-radius: 4px
                            line-height: 24px
                            font-family: 'Titillium Web',sans-serif
                            font-weight: bold
                            color: #6cd2d6
                            width: 290px
                            height: 42px
                            padding-left: 35px
                            outline: none
                            cursor: pointer
                            margin-bottom: 30px
                            @media(min-width: 992px)
                                margin-bottom: 60px
                            &:active
                                border: #6cd2d6 3px solid
                            @media(min-width: 992px)
                                width: 370px
                                height: 42px
                    .recaptcha-wrapper
                    .signing-button
                        button
                            background-color: #6cd2d6
                            transition: color .3s ease-out, background .3s ease-out, border .3s ease-out
                            border-radius: 8px
                            color: primary-color
                            font-size: 18px
                            line-height: 24px
                            font-family: 'Titillium Web',sans-serif
                            font-weight: bold
                            padding: 10px 30px
                            border-color: primary-color
                            outline: none
                            &:hover
                                color: #6cd2d6
                                background-color: primary-color
                                border-color: #6cd2d6
    .people
        height: 30px
        width: 100%
        position: absolute 
        bottom: -10px
        overflow: hidden
        left: 0
        transform: scale(1.2)
        @media(min-width: 600px)
            height: 40px
        @media(min-width: 992px)
            height: 60px
        @media(min-width: 1440px)
            height: 85px
            transform: scale(1.2)
.newsletter-popup
    display: none
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    overflow: hidden
    position: fixed
    z-index: 100000
    background-color: rgba(130, 136, 163, 0.65)
    .popup-blend
        display: flex
        flex-direction: column
        justify-content: center
        width: 100%
        height: 100%
        .popup-content
            width: 100%
            height: 300px
            background-color: white
            border-radius: 15px
            display: flex
            flex-direction: column
            justify-content: center
            margin: 0 auto
            @media(min-width: 768px)
                width: 655px
                height: 390px
            img
                width: 46px
                height: 46px
                margin: 0 auto
            h3
                margin: 0 auto 30px
                text-align: center
                color: primary-color
                font-size: 30px
                padding: 0 30px
                font-family: 'Titillium Web',sans-serif
                font-weight: bold
                padding: 10px 30px
                @media(min-width: 768px)
                    font-size: 48px
                    line-height: 69px
                    padding: 10px 120px
            p
                margin: 0 auto
                color: primary-color
                font-size: 18px
                line-height: 24px
                font-family: 'Titillium Web',sans-serif
                font-weight: light
                text-align: center
                padding: 0 30px
                @media(min-width: 768px)
                    font-size: 24px
                    line-height: 28px
                    padding: 0 120px


