.button-green-wrapper
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 20px;
    .link-zielony-button
        outline: none;
        text-decoration: none;
        cursor: pointer;
        display: flex;
    .button-events-line
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 18px 0 35px;
        color: white;
        background-color: #57c5c9;
        font-family: 'Titillium Web', sans-serif;
        font-weight: bold;
        font-size: 14px; 
        border-radius: 23px;
        outline: none; 
        box-shadow: none;
        border: none;
        cursor: pointer;
        text-decoration: none;
        @media(min-width: 992px)
            font-size: 18px
            padding: 0 38px 0 55px;
        &::after
            content: ''
            background-color: #57c5c9;
            z-index: -1;
            height: 4px;
            width: 125%;
            position: absolute;
            right: -12%;
        .big-add-symbol
            font-size: 30px;
            margin: 0px 0px 7px 20px;

