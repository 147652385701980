.pagination-navigation
    margin: 60px 0
    @media(max-width: 576px)
        margin: 40px 0
    span 
        font-size: 12px
    a
        margin: 0 5px
        &:hover
            text-decoration: none
    .blogPostList__pagination__pageNumber
        color: brand-color
        font-size: 12px


    .pageNumberIsActive
        font-size: 12px
        font-weight: bold
        color: buttons-color

    .blogPostList__pagination__next
        font-size: 12px
        color: buttons-color
        font-weight: bold
        &:hover
            color: buttons-color

    .blogPostList__pagination__prev
        font-size: 12px
        color: buttons-color
        font-weight: bold
        &:hover
            color: buttons-color