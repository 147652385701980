.join-event-photo-wrapper
    width: 100%;
    height: 600px;
    margin: -60px 0 0;
    position: relative;
    @media(min-width: 992px)
        height: 1100px;
    .background-photo
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;  
        background-repeat: no-repeat;
        .text-wrapper
            display: flex;
            flex-direction: column;   
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 10% 9% 20%;
            align-content: flex-start;
            @media(min-width: 992px) 
                padding: 5% 3% 15% 18%;
            @media(min-width: 1200px)
                padding: 5% 12% 15% 20%;
            @media(min-width: 1440px)
                padding: 5% 15% 15% 20%;
            .events-date
                font-family: 'Titillium Web', sans-serif;
                font-size: 30px;
                line-height: 45px; 
                font-weight: bold;
                color: #57c5c9;
                @media(min-width: 992px)
                    font-size: 45px;
                    line-height: 65px;
            .events-title
                font-family: 'Titillium Web', sans-serif;
                font-size: 36px; 
                line-height: 35px;
                font-weight: bold;
                color: white;  
                padding: 0 !important;
                background: none !important;
                @media(min-width: 650px)
                    padding: 0 20% 0 0 !important;
                @media(min-width: 850px)
                    padding: 0 32% 0 0 !important;
                @media(min-width: 992px)
                    font-size: 96px;
                    line-height: 100px;
                    padding: 0 !important;
                @media(min-width: 1200px)
                    padding: 0 10% 0 0 !important;                   
            .events-subtitle
                font-family: 'Titillium Web', sans-serif;
                font-size: 18px;
                font-weight: normal;
                line-height: 27px; 
                color: white;  
                font-weight: normal;
                @media(min-width: 992px)
                    font-size: 60px;
                    line-height: 108px;