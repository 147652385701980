#excerption
	margin-top: -35px
	.citation
		background-color: brand-color
		padding-bottom: 95px
		padding-top: 55px	
		position: relative

		&::after
			content: ""
			position: absolute
			top: 100%
			left: 0
			width: 100%
			height: 435px
			background-color: brand-color

		.bg-img
			position: absolute
			top: 15px
			right: 0
			height: 500px
			z-index: 2

		.quote
			border: none !important
			padding: 0
			margin: 0

			p
				font-size: 36px
				color: white
				font-style: italic
				@media(max-width: 425px) 
					font-size: 32px
				@media(max-width: 320px)
					font-size: 24px					
			h6
				font-size: 16px
				color: second-primary-font-color
				font-style: italic
				font-weight: bold
				b, strong
					font-weight: bold

				

			

		@media( min-width: 1500px )
			.bg-img
				right: 5%
				top: 45%

	.decoration-green
		position: relative	
		width: 28%
		height: 36px
		margin-left: auto
		margin-right: auto
		background-color: brand-color 
		z-index: 2
		margin-bottom: -1px
		@media(max-width: 578px)
			transform: scale(0.7)
			margin-bottom: -7px	
		@media(max-width: 364px)
			transform: scale(0.6)
			margin-bottom: -8px						

		&::before
			content: ""
			display: block
			position: absolute
			height: 36px
			bottom: 0px
			right: 99%
			width: 210px
			transform: rotateX(180deg) rotate(180deg)
			background-image: url(../img/decoration-green-right.png)
			background-repeat: no-repeat


		&::after
			content: ""
			display: block
			position: absolute
			height: 36px
			bottom: 0px
			left: 99%
			width: 210px					
			background-image: url(../img/decoration-green-right.png)
			background-repeat: no-repeat		
			