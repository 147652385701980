.search-window
    color: buttons-color
    .base
        position: absolute
        z-index: 1
        top: 188px
        border-radius: 2px
        border: 1px solid
        border-color: rgba(0,0,0,0.2)
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px      
        background-color: white
        padding: 12px 22px 7px
        margin-left: 22px
        max-width: 300px        
        h5
            margin-bottom: 14px
            font-weight: bold
        input
            margin-bottom: 12px
            width: 100%
            height: 42px
            padding-left: 20px
            padding-right: 20px
        button
            margin-bottom: 23px
            width: 100%
            background-color: brand-color
            padding-left:0
            padding-right: 24px
            &:focus, &:hover
                color: white
        img
            padding-right: 16px
            padding-bottom: 3px
        p
            margin-bottom: 3px
            font-size: 14px  
        a
            color: brand-color
            text-decoration: none
            font-weight: bold
        ul
            list-style-type: none
            padding: 0		
            display: flex
            a, a:hover, a:active, a:focus, a:visited
                text-decoration: none
            .social-media
                display: inline-block
                margin-top: 10px
                margin-right: 7px              
            li
                color: primary-font-color
                font-size: 18px
                a
                    img
                    svg
                        width: 28px
                        padding: 0
                        cursor: pointer
        .map-filtration-label
            color: #8f8f8f;
            font-size: 14px;
            line-height: 22px;
            position: relative;
            &:before
                content: '';
                position: absolute;
                top: -5px;
                height: 1px;
                width: 100%;
                background-color: #e0e0e0;
        .form-wrapper
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 10px 0;
            label
                display: inline-block;
                font-family: 'Titillium Light',sans-serif; 
                font-size: 14px;
                line-height: 22px;
                cursor: pointer;  
                position: relative;  
                padding-left: 20px;  
                margin-right: 10px;
                margin-bottom: 0px;
                color: #232f5f;
                &:before
                    content: "";  
                    display: inline-block;  
                    width: 12px;  
                    height: 12px;  
                    border-radius: 6px;
                    margin-right: 10px;  
                    position: absolute;  
                    left: 0; 
                    top: 3px;  
                    background-color: #fff;
                    border: 1px solid #cdcdcd;
                &:after
                    content: "";  
                    display: inline-block;  
                    width: 12px;  
                    height: 12px;  
                    border-radius: 6px;
                    margin-right: 10px;  
                    position: absolute;  
                    left: 0; 
                    top: 3px;  
                    background-color: #fff;
                    border: 1px solid #cdcdcd;
                img
                    margin: 3px 0 0 3px;
            .marker-selected
                display: none
            .marker-selected:checked + label::after
                content: "";  
                color: #57c5c9;  
                font-size: 30px;  
                text-align: center;  
                line-height: 15px;
                height: 6px;
                width: 6px;
                left: 3px;
                top: 6px;
                border-radius: 50%;
                background-color: #57c5c9;
                border: none;
            .marker-selected:checked + label::before
                border-color: #57c5c9;

