.wrapper
    margin: 0 auto 150px;
    .carousel
        margin-bottom: 50px;
        position: relative;
        margin: 100px 0 20px;
        padding: 0;
        .scrollbar
            background-color: #daf2f3;
            height: 12px;
            position: absolute;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);
            width: 300px;
            @media(min-width: 992px)
                width: 600px;
                height: 10px;
            .handle 
                height: 12px;
                background-color: #57c5c9;
                cursor: pointer;
                @media(min-width: 992px)
                        height: 10px;
        .frame
            height: 230px;
            @media(min-width: 992px)
                height: 400px;
            .carousel-slider
                display: -webkit-box;
                height: 230px;
                padding-top: 5px;
                width: auto;
                @media(min-width: 992px)
                    height: 400px
                .image-item
                    height: 100%;
                    a
                        height: 100%;
                        .carousel-slide
                            margin-left: 10px;
                            width: 280px;
                            height: 100%;
                            position: relative;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            @media(min-width: 992px)
                                width: 360px
                                cursor: pointer;
                            @media(min-width: 1200px)
                                width: 600px