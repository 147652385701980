.news
    // margin-bottom: 50px
    margin: 0 auto 50px
    h3
        color:#232f5f
        
    .module-wrapper
        margin: 0 auto
        width: 100%
        display: flex
        flex-wrap: wrap
        justify-content: center
        @media(min-width: 1200px)
            justify-content: space-between
        // background-color: yellow
        .single-news-wrapper
            display: flex
            flex-direction: column
            justify-content: space-between
            cursor: pointer
            max-width: 545px
            margin-bottom: 50px
            @media(min-width: 768px)
                width: 545px
            .news-image
                height: 350px
                width: 100%
                z-index: 10
                border-radius: 20px
                position: relative
                background-size: cover
                background-position: center
                background-repeat: no-repeat
                // background-image: url('../img/forum_pl.png')
                background-position: center
                margin-bottom: 30px
                @media(min-width:768px)
                    height: 600px
                    width: 545px
            .info-wrapper
                    width: 100%
                p.news-date
                    font-size: 14px
                    line-height: 24px
                    color: primary-color
                    font-family: 'Titillium Web',sans-serif
                    margin: 0 !important
                h5.news-title
                    font-size: 30px
                    margin: 0 !important
                    line-height: 38px
                    color: primary-color
                    font-family: 'Titillium Web',sans-serif
                    font-weight: bold
                p.news-short-description
                    font-size: 18px
                    line-height: 24px
                    color: primary-color
                    margin-top: 20px !important
                    font-family: 'Titillium Web',sans-serif             
        a           
            text-decoration: none
            width: 545px;
            button.btn-news
                border-width: 2px
                font-size: 18px
                font-family: 'Titillium Web',sans-serif
                font-weight: bold
                border-radius: 4px
                text-transform: none
                text-decoration: none
                margin-top: 0 !important
                &:first-letter
                    text-transform: uppercase

