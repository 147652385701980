.payment-popup
    opacity: 0
    visibility: hidden
    height: 1
    position: fixed
    top: 0
    left: 0
    right: 0
    background: rgba(87,197,201,.77)
    z-index: 1000
    color: primary-color
    font-size: 12px
    font-family: "Titillium Web", sans-serif
    transition: all 0.2s ease-out

    &.active
        height: 100%
        opacity: 1
        visibility: visible

    &> div
        position: absolute
        top: 50%
        left: 50%
        transform: translate( -50%, -50% )
        width: 600px
        max-width: 100%
        max-height: 100%
        overflow: auto

        @media(max-width: 1199px)
            max-height: calc( 100% - 106px)
            top: calc( 50% + 54px )

        .payment-wrapper
            width: 100%
            background: white
            position: relative
            padding: 50px
            border-radius: 5px
    
    .close

        &.exit-cross
            opacity: 1

        &.blenda
            position: fixed
            top: 0
            bottom: 0
            left: 0
            right: 0

    
#helloasso-popup
    .payment-wrapper
        padding: 50px 30px

#payU-popup

    .payU-logo
        width: 200px
        display: block
        margin: -30px auto 0
    
    .amount
        display: flex
        flex-direction: row
        justify-content: center
        margin-top: 20px

        label
            display: flex
            flex-direction: column
            align-items: center
            width: 90px

            span 
                font-weight: normal
                padding-top: 5px

    .text-fields
        display: flex
        flex-direction: column
        justify-content: center
        margin-top: 20px

        label
            display: flex
            flex-direction: column

            span
                font-weight: normal
                margin-bottom: 5px

            input 
                font-weight: normal

            &.other-amount
                opacity: 0
                visibility: hidden
                transition: all 0.2s ease-out
                height: 1px

                &.active
                    height: auto
                    visibility: visible
                    opacity: 1

    .statements

        margin-top: 50px

        h1
            font-size: 20px
            font-weight: bold
            margin: 0
        h2
            font-size: 20px
            margin: 0
            margin-bottom: 20px
            margin-top: 10ps

        label
            display: flex
            flex-direction: row

            input 
                flex-shrink: 0

            div
                width: 13px
                height: 13px
                flex-shrink: 0

            span
                font-weight: normal
                padding-left: 30px

    .submit-btn
        display: block
        margin 40px auto 0
        width: 300px
        max-width: 100%

    
    .ball
        display: none
        margin-top: 0

        &.active
            display: block
            margin-top: 20px

        .wBall 
            .wInnerBall
                background-color: rgb(87,197,201)