.sponsor-module
    & *
        font-family: 'Titillium Web', sans-serif;
        font-size: 18px;
        line-height: 24px;
        margin: 0 auto;
        color: #232f5f;
        text-align: center;
        @media(min-width: 992px)
            font-size: 24px;
    .sponsor-logo-wrapper
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 300px;
        height: 150px;
        transform: scale(0.7);
        margin: 20px auto 50px;
        @media(min-width: 768px)
            height: 215px;
            transform: scale(0.8)
        @media(min-width: 992px)
            margin: 50px auto 70px;
            transform: scale(1);




