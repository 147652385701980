.contact-block-wrapper
    display: block;
    width: 100%;
    .contact-single-block
        display: flex;
        flex-direction: column; // row-reverse
        justify-content: space-between; 
        width: 100%;
        margin: 0 auto 50px;
        @media(min-width: 992px)
            flex-direction: row;
            width: 80%;
        .contact-picture-wrapper
            display: flex;
            flex-direction: column; 
            justify-content: flex-end;;
            width: 100%;
            @media(min-width: 992px)
                width: 40%
            .contact-block-picture
                max-width: 100%;
                height: 120px;
                display: block;
                padding-bottom: 25px;
                @media(min-width: 992px)
                    height: 205px;
        .contact-text-wrapper
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            text-align: center;
            @media(min-width: 992px)
                width: 60%;
                text-align: left;
            & *
                font-family: 'Titillium Web', sans-serif;
                font-size: 24px;
                font-weight: bold;
                line-height: 34px;
                @media(min-width: 992px)
                    font-size: 34px;
            .contact-navy
                color: #232f5f;
            .contact-green
                color: #57c5c9;
                padding: 20px 0 10px;
                & *
                    margin: 0;
                    @media(min-width: 992px)
                        margin: 0 0 10px;
                @media(min-width: 992px)
                    padding: 60px 0 10px;







