footer
    font-family: 'Titillium Web', sans-serif
    padding-top: 60px
    padding-bottom: pad-bot
    border-bottom: 10px solid #096272
    background-color: primary-font-color 
    position: relative

    #scroll-up
        background-color: transparent
        border: none
        outline: none
        position: fixed
        bottom: 30px
        right: 5%
        visibility: hidden
        opacity: 0
        transition: 0.8s all ease
        z-index: 99

        img
            height: 29px

        &.visible
            visibility: visible
            opacity: 1

        &.sticky
            top: -31px
            position: absolute
            bottom: auto

    .no-padding
        padding: 0
    
    .dis-blo
        display: block

    @media(max-width: 1024px)
        padding-bottom: 80px
    @media(max-width: 768px) 
        padding-bottom: 50px	
    .footer-padding
        @media(min-width:1440px)
            padding: 0 0 0 60px
    .footer-padding
        @media(min-width:1024px) and (max-width:1399px)
            padding: 0 0 0 30px
    .footer-padding
        @media(max-width:991px)
            padding: 0						
    ul
        list-style-type: none
        padding: 0		
        a, a:hover, a:active, a:focus, a:visited
            color: white
            text-decoration: none
        .social-media
            display: inline-block
            margin-top: 10px
            margin-right: 6px
            a
                img
                svg
                    width: 28px
                    height: 28px
        .ui-selectmenu-button.ui-button
            float: left
            margin: 0
            margin-top: 10px
    h6
        font-size: 14px		
        text-transform: uppercase
        font-weight: bold
        color: brand-color
        margin-bottom: 20px

    .pleo-footer
        position: absolute
        right: 10px
        bottom: 4px
        outline: 0
        text-decoration: none
        padding: 15px
        display: flex
        flex-direction: row
        align-items: center

        span
            padding-right: 10px
            font-size 12px
            color: brand-color
        img,
        svg
            display: inline-block
            float: right

    button
        border: 0
        background: transparent
        color: white
        padding: 0
