// nav
primary-font-color = #1792a8

// buttons, opacity, background
brand-color = #57c5c9 

// blockqoute, header
second-primary-font-color = #167477

// p 
ligt-grey-font-color = #797979

light-grey = #a3a3a3


// shadow
buttons-color = #232f5f

// p background frames
like-white = #f5f5f5

click-play-font= #6cd2d6

like-white-header = #fdfdfd

//hover na navbar
dark-primary-color = #106675

// border nav bar buttons frames
light-blue = #a8d1e7

lightest-blue = #d8f1ff

lighter-blue = #ddfeff


pad-bot = 120px

pad-top = 120px

space = 50px
@media(max-width: 1024px)
    space = 30px

primary-color = #232f5f
shadow-primary-color =#1f2a55 
secondary-font-color = #f96b69
gray-border = #d7d7d7
background-input = #e9f3f9


graphic-module-margin = 35px 


editorText() 
    & *
        font-size: inherit
        line-height: inherit
        margin: 0
        padding: 0