#vision
    padding-bottom: 120px
    .vision-header
        margin-bottom: 25px	
    .vision-subheader
        margin-bottom: 25px
    .vision-row
        display: flex
        margin-bottom: 40px
        .bullet-column
            padding:0
            text-align: center
            @media(max-width:768px)
                margin-left: 22px
            .bullet
                height: 51px
                width: 51px
                @media(max-width:768px)
                    height: 43px
                    width: 43px
                background-color: brand-color
                border-radius: 50%      
                display: inline-block
                .bullet-number
                    color: white        
                    font-size: 33.87px
                    @media(max-width:768px) 
                        font-size: 28.87px                   
                    text-align: center
                    font-weight: bold
        .column-paragraph
            @media(max-width: 768px)
                margin-left: 10px
    .under-paragraph
        margin-top: 30px

    .points-row
        display: flex
        align-items: center
        .wheel
            height: 51px
            width: 51px
            background-color: brand-color
            border-radius: 50%                  
            .wheel-number
                color: white        
                font-size: 33.87px
                text-align: center
                font-weight: bold        