#subpage-team
	color: buttons-color

	#excerption
		.citation
			margin-bottom: 90px
			&::after
				content: none

			.bg-img
				position: absolute
				right: 10%
				top: initial
				bottom: -16px
				height: 255px
				z-index: 2

			.quote
				border: none !important
				padding: 0
				margin: 0


			@media( min-width: 1500px )
				.bg-img
					right: 10%
					top: initial
					bottom: -16px				
		#crew
			.crew-header
				margin-bottom: 30px			
			.crew-paragraph
				margin-bottom: 45px
			.crew-people
				color: brand-color
				margin-bottom: 70px


	.with-us	
		color: buttons-color
		padding-top: 0

		h3
			font-size: 24px	
			margin-bottom: 45px
		.wrapper
			.item				 										                                                            
				h5
					color: buttons-color

	// .download
	// 	color: buttons-color
	// 	padding-top: 300px