#page-header
    overflow: hidden
    margin-bottom: 60px
    background-color: brand-color
    @media(max-width:1199px)
        padding-top: 103px

    .pageheader-center
        background-color: brand-color 
        color: white
        height: 230px
        display: flex
        align-items: center
        position: relative
        @media(max-width: 1000px)
            height: auto
      
        @media(max-width: 1024px)
            transform: translate(13%)
        @media(max-width: 1000px)
            transform: none                        
        &:before
            content: ""
            position: absolute
            top: 0
            right: 100%
            width: 100%
            height: 100%
            background-color: primary-font-color
            @media(max-width: 1000px)
                display: none            
        // &:after
        //     content: ""
        //     position: absolute
        //     top: 0
        //     left: 99%
        //     width: 100%
        //     height: 100%
        //     background-color: brand-color        
        .pageheader-svg
            height: -webkit-fill-available
            position: absolute
            left: -180px
            width: 360px
            height: 230px
            bottom: 0
            @media(max-width: 1000px)
                display: none
        .pageheader-col
            padding-top: 22px
            padding-bottom: 22px
            @media(min-width: 1440px)        
                padding-left: 19.6%
            @media(min-width: 1001px)
                padding-left: 25%             
            @media(max-width: 1000px)
                padding-left: 22px

        h3
            &, & *     
                margin-bottom: 26px
                margin-top: 0

        h6
            &, & *    
                font-size: 14px
                line-height: 19px
                margin-bottom: 0px

