#testimonies
    #page-header
        margin-bottom: 0

    .item
        padding: 120px 0
        color: buttons-color
        @media(max-width: 1024px)
            padding: 90px 0
        @media(max-width: 768px)
            padding: 80px 0
        @media(max-width: 576px)
            padding: 60px 0
        &:nth-child(even)
            background-color: like-white
        .place
            font-weight: bold
            margin-bottom: 40px
            @media(max-width: 576px)
                margin-bottom: 30px
        .description
            margin-bottom: 40px
        .person
            font-weight: 300
    // #download
    //     background-color white
