.loop
	color: buttons-color
	section
		&:nth-child(odd)
			overflow: hidden			
		.ptb
			padding-top: 75px
			@media(max-width:1199px)
				padding-top: 70px	
			@media(max-width:425px)
				padding-top: 60px						
			
			padding-bottom:  100px
			@media(max-width:1199px)
				padding-bottom: 70px
			@media(max-width:425px)
				padding-bottom: 60px

		&:nth-child(even)
			background-color: like-white
			a
				button
					background-color: like-white
					&:hover
						background-color: brand-color
			.group-img            
				padding-right: 8.33% 
				margin-top: -115px                                        
				// @media(max-width:1599px)
				// 	margin-top: -11%
				// @media(max-width:1439px)
				// 	margin-top: -16%                        
				@media(max-width:1199px)
					margin-top: 0                        
				img  
					float:right
					@media(max-width:1199px)
						float: left 							 
						max-width: 121%
					@media(max-width:1599px)
						max-width: 119%
					@media(max-width:1439px)
						max-width: 100%  

		@media(min-width: 1200px)        
			&:nth-child(odd)
				.group
					display: flex
					align-items: center
					.group-img 
						order: 1              
						padding-left: 8.33%    
						img
							// max-height: 465px							
							// min-width: 119%
							float: left 
							// @media(max-width:1199px)							 
							// 	max-width: 121%
							// @media(max-width:1599px)
							// 	max-width: 119%
							// @media(max-width:1439px)
							// 	max-width: 100%		

		&:first-child
			.group-img
				img
					height: 100%
					max-height: 300px	
					width: 300px					

		.group
			display: flex

			@media(max-width:1199px)   
				flex-direction: column
				align-items: flex-start 

			.group-img    
				width: 49%                      
				@media(max-width:1199px)
					margin-bottom: 40px
					width: 100%
				img
					display: block

					@media(max-width:1199px)
						max-width: 100%
						height: auto
			.group-text
				width: 49%
				@media(max-width:1199px)  
					width: 100%  
				h5
					margin-bottom: 40px
					strong 
						font-weight: bold
				a
					text-decoration: none
					button
						font-size: 13px
						padding: 7px 32px 9px
						white-space: pre-wrap
						height: auto
