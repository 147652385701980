#tabs-menu
    background-color: click-play-font
    @media(max-width:991px)
        height: auto
    .nav
        @media(max-width: 1200px)
            display: none
    .open-tabs-menu
        display: block
    ul
        list-style: none
        display: flex
        justify-content: flex-start
        flex-wrap: wrap
        padding: 0
        margin: 0 
              
        @media(max-width:1200px)
            flex-direction: column  
      
        li
            margin-right: 64px
            cursor: pointer          
            a
                text-decoration: none
                color: primary-font-color
                display: block
                padding-top: 18px
                padding-bottom: 12px
                font-weight: bold
                text-transform: uppercase
                border-bottom: 4px solid click-play-font
                transition: border-bottom 0.3s ease-out
                 
                            

    .active
        color: white
        border-bottom: 4px solid primary-font-color
        @media(max-width: 1200px)
            border-bottom: none

    #arrow-tabs-manu
        background-color: brand-color 
        padding-left: 22px       
        @media(min-width: 1201px)
            display: none  
        img
            transform: rotate(180deg)
        .active-tab
            font-family: 'Titillium Web',sans-serif		
            padding-left: 13px
            color white
            text-transform: uppercase

