#meetings-series
    background-color: like-white

    padding-top: pad-top
    @media(max-width: 1024px)
        padding-top: 80px
    @media(max-width: 768px)
        padding-top: 50px

    padding-bottom: 60px
    // @media(max-width:1024px)
    //     padding-bottom: 20px

    .series-all-wrapper
        .series-list
            position: relative
            &:not(:last-child) 
                margin-bottom: 100px
                @media(max-width: 1024px)
                    margin-bottom: 80px
                @media(max-width: 320px)
                    margin-bottom: 60px                
            .group-navbar 
                width: 25px 
                height: calc(100% - 6px) 
                background-color: white 
                border-radius: 3px 
                position: absolute 
                top: 0 
                left: 0 
                margin-bottom: 6px 
                .nav-text 
                    transform: translateX(-50%) translateY(-50%) rotate(-90deg) 
                    position: absolute 
                    top: 4% 
                    left: 50% 
                    width: auto 
                    height: 25px 
                    white-space: nowrap       
                    .navbar-series 
                        color: brand-color 
                        font-weight: bold 
                        margin: 0 
                        display: inline-block 
                    .separator 
                        font-size: 12px 
                        color: #3169a7           
                    .navbar-group 
                        font-size: 12px 
                        color: #3169a7 
                        margin: 0 
                        display: inline-block   			

            .serie
                display: flex
                margin-left: 25px 
                flex-direction: column				
                .serie-title 
                    background-color: white
                    color: buttons-color
                    margin-left: 7px
                    width: 97%				
                    padding: 35px 25px
                    border-radius: 4px
                    margin-bottom: 6px

                    h5
                        font-weight: bold

                    .serie-description
                        padding-top: 30px

                        // p
                        //     font-size: 18px
                        //     color: primary-color
                                                
                .meetings-wrapper
                    width: 100%					
                    display: flex
                    flex-wrap: wrap


                    .meetings-item
                        background-color: white
                        width: 32%
                        border: 2px solid white
                        border-radius: 4px
                        margin-left: 7px
                        margin-bottom: 6px

                        @media(max-width:991px)
                            width: 48%	
                        @media(max-width:639px)
                            width: 99%												
                        &:hover
                            .trailer
                                background-color: brand-color
                                transition: background-color .3s ease	
                                p
                                    color: #99e8eb
                                    transition: color .3s ease                                    							
                            .photo
                                &:after
                                    background-color: rgba(200,236,237,.33)
                                    transition: background-color .3s ease
                            .content
                                .meeting
                                    .name
                                    p
                                        color: primary-font-color
                                        transition: color .3s ease                                        
                                    .title
                                    p
                                        color: brand-color
                                        transition: color .3s ease                                        
                                .materials-wrapper
                                    .materials-item
                                        .text-pikto 
                                            .cls-1                                         
                                                fill: brand-color
                                                transition: fill .3s ease                                            
                                        p
                                            color: primary-font-color
                                            transition: color .3s ease

                                                                
                        .photo
                            height: 210px
                            background-position: 50%
                            background-size: cover
                            background-repeat: no-repeat
                            position: relative
                            cursor: pointer
                            &:after
                                content: ""
                                position: absolute
                                top: 0
                                left: 0
                                width: 100%
                                height: 100%
                                background-color: transparent
                            .btn-films-play
                                width: 21px
                                height: 17px
                            &:hover
                                .btn-films-play
                                    fill: primary-font-color											


                        .trailer
                            background-color: buttons-color
                            position: relative
                            p
                                font-size: 16px
                                color: #697abd
                                font-weight: bold
                                padding: 5px 10px
                                margin-top: -22px
                                margin-bottom: 0

                        .content
                            padding: 22px 22px 30px
                            color: buttons-color
                            min-height: 200px
                            display: flex
                            flex-direction: column
                            justify-content: space-between
                            @media(max-width: 1200px) and (min-width: 640px) 
                                 min-height 238px                           
                            .meeting
                                .name
                                    margin-bottom: 3px
                                .title
                                    font-size: 20px
                                    font-weight: bold
                                    margin-bottom: 24px

                            .title-text
                                margin-bottom: 10px							
                                .content-title
                                    p
                                        font-size: 12px
                                        color: buttons-color
                                        font-weight: bold
                                        text-transform: uppercase
                                        margin-bottom: 2px

                                .content-text

                                    a
                                        text-decoration: none	
                                        width: 100%						
                                    p
                                        font-size: 12px
                                        color: primary-font-color
                                        padding:0
                                        margin:0

                            .materials-wrapper
                                display: flex
                                @media(max-width: 1200px)
                                    flex-direction: column
                                a
                                    text-decoration: none
                                    color: buttons-color
                                    &:first-child
                                        margin-bottom: 10px   
                                        margin-right: 12px                                                                         
                                    .materials-item
                                        display: flex
                                        align-items: center
                                        cursor: pointer

                                        
                                        .text-pikto                                        
                                            fill: brand-color
                                            margin-right: 6px
                                            width: 33px
                                            .cls-1
                                                fill: brand-color
             
                                        p
                                            font-size: 16px
                                            margin: 0
                                            line-height: 17px
                                            width: calc(100% - 41px)


            .text-wrapper
                margin-left: 32px
                width: 95%
                border-bottom:6px solid #f5f5f5
                margin-top: 80px
                margin-bottom: 80px
                @media(max-width:991px)
                    width: 94%	
                    margin-top: 60px
                    margin-bottom: 60px
                @media(max-width:639px)
                    width: 90%
                    margin-bottom: 40px

                .text-border
                    background-color: white
                    padding: 35px 25px
                    color: buttons-color
                    border: 2px solid buttons-color
                    border-radius: 4px
                    p
                        margin: 0

                    .header
                        font-size: 24px
                        font-weight: bold
                        margin-bottom: 32px
                        @media(max-width: 425px)
                            font-size: 21px
                            margin-bottom: 20px
                    .description
                        color: primary-font-color
                        margin-bottom: 28px
                    .caption1
                        font-weight: bold

