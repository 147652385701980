.access-code
    p
        font-size: 24px	
        @media(max-width: 768px)
            font-size: 20px
        @media(max-width: 424px)
            font-size: 18px       
  
    .access-paragraph 
        margin-bottom: 50px

    .examples-heading
        font-weight: bold
        margin-bottom: 60px

    .material-wrapper
        margin-bottom: 60px

    .code-heading
        font-weight: bold
        margin-bottom: 30px
    .input-button
        display: flex
        justify-content: center
        .form-group
            @media(max-width: 423px)
                display: flex
                flex-direction: column
                justify-content: center
            input
                width: 180px
                margin-right: 16px
                @media(max-width: 423px)
                    margin-bottom: 16px
                display: inline-block
                height: 32px
            button
                width: 180px
                line-height: 20px
                @media(max-width: 767px)
                    margin-bottom: 3px
