header
    nav
        font-family: 'Titillium Web'
        @media( max-width: 1199px )
            position: fixed
            z-index: 999999
            width: 100%
            background-color: #fff		

        .main-menu
            display: flex
            justify-content: space-around

        ul
            display: flex
            flex-direction: row
            list-style-type: none
            align-items: center
            margin: 0
            height: 105px
            padding: 0
 
            li
                position: relative


                &.submenu-visible
                    .submenu
                        display: block
                        .column-wrapper
                            max-height: inherit
                            transition: max-height 0.25s ease-in
                            @media(max-width: 1199px) and (min-width: 425px)
                                padding-left: 29px
                > .active
                    font-weight: bold        

                .submenu
                    position: absolute
                    top: calc( 100% + 20px )
                    left: 50%
                    transform: translateX(-50%)
                    background-color: #fff
                    z-index: 3
                    border-radius: 5px
                    box-shadow: 0 1px 6px 2px rgba(0,0,0,.05)
                    display: none	

                    @media( max-width: 1199px )
                        display: block

                    .close-ico
                        position: absolute
                        top: 0
                        left: 50%
                        transform: translateX( -50% )
                        cursor: pointer

                        .svg
                            position: relative
                            z-index: 5
                            display: block

                        &::after
                            content: ""
                            position: absolute
                            bottom: calc( 100% - 9px )
                            left: 50%
                            transform: translateX(-50%) rotate(45deg)
                            width: 18px
                            height: 18px
                            z-index: 0
                            background-color: #fff
                            box-shadow: -2px -2px 6px 0px rgba(0,0,0,.05)

                        &::before
                            content: ""
                            position: absolute
                            top: 0
                            left: 50%
                            transform: translateX( -50% )
                            width: 30px
                            height: 16px
                            z-index: 1
                            background-color: #fff
                    
                    &.submenu-lg
                        width: 604px
                        @media(max-width: 1199px)
                            width: auto                        
                        .column-wrapper
                            .column
                                @media(max-width: 1199px)
                                    width: auto 

                        @media( max-width: 1850px )
                            left: -100px 
                            transform: none

                            .close-ico
                                left: 132px 

                    &.submenu-md
                        width: 310px

                        @media( max-width: 1200px )
                            display: none
                        
                        @media( max-width: 1199px )
                            display: block

                        .column-wrapper
                            .column
                                width: 100%

                    &.submenu-sm
                        width: 190px

                        .column-wrapper
                            .column
                                width: 100%

                                .column-items
                                    .column-item
                                        a
                                            padding: 5px 12px							
                    
                    .column-wrapper
                        display: flex
                        flex-direction: column
                        padding: 5px					
                        .column-header
                            font-size: 14px
                            margin: 0 11px 19px
                            padding: 12px 0 15px
                            border-bottom: 1px solid brand-color
                            font-weight: bold
                            color: brand-color
                        .columns
                            display flex
                            @media(max-width: 1199px)
                                display: block
                        .column
                            width: 50%
                            padding: 11px							



                            .column-items
                                padding: 0
                                // @media(max-width:1199px)
                                //     padding-left: 29px
                                margin: 0
                                display: flex
                                flex-direction: column
                                
                                > .column-item
                                    width: 100%
                                    margin-bottom: 10px									

                                    &:last-child
                                        margin-bottom: 0

                                    > a
                                        display: flex
                                        flex-direction: row
                                        border: 1px solid primary-font-color
                                        border-radius: 5px
                                        align-items: center
                                        color: ligt-grey-font-color
                                        transition: all 0.2s ease
                                        &:hover
                                            font-weight: normal
                                            background-color: lighter-blue
                                            color: primary-font-color



                                        > .img-wrapper
                                            width: 70px
                                            padding: 20px 12px 25px
                                            position: relative
                                            text-align: center

                                            &::before
                                                content: ""
                                                left: 0
                                                top: 0
                                                width: 1px
                                                height: 58px
                                                background-color: brand-color
                                                position: absolute

                                            .svg
                                                height: 34px


                                        > span
                                            display: inline-block
                                            width: calc( 100% - 70px )
                                            padding: 0 12px 0 19px
                                            line-height: 17px

                                    @media(min-width: 1200px)
                                        > .active
                                            font-weight: normal
                                            background-color: lighter-blue
                                            color: primary-font-color

                                    @media(max-width: 1199px)                                            
                                        > .active
                                            font-weight: bold                                           
            &.main-menu
                li
                    padding-right: 0px
                    
                    a
                        font-size: 15px
                        color: primary-font-color
                        text-transform: lowercase
                        text-decoration: none
                    // .active
                    //     font-weight: bold

                    &:last-child
                        padding-right: 0

                    // &:hover
                    //     > a
                    //         font-weight: bold

                    &.md-show
                        @media( min-width: 1200px )
                            display: none

                    &.sm-show
                        @media( min-width: 531px )
                            display: none

                    &.xs-hide
                        @media( max-width: 1024px )
                            display: none

                    &.xs-show
                        @media( min-width: 1025px )
                            display: none

                @media( max-width: 1199px )
                    position: fixed
                    z-index: 2
                    top: 90px
                    left: 0
                    width: 100%
                    height: calc( 100vh - 90px )
                    background-color: #fff
                    flex-direction: column
                    align-items: left
                    padding: 0px 22px 0px
                    overflow-y: auto
                    display: none

                    

                    .ui-selectmenu-button
                        &.ui-button
                            float: none
                            margin: 0

                    li
                        line-height: normal
                        margin: 35px 0
                        width: 100% !important
                        @media(max-height: 599px)
                            margin: 26px 0
                    .submenu
                        box-shadow: none		
                        position: static	
                        transform: none		

                        .close-ico
                            display: none

                        &.submenu-md
                            width: 100%
                            .column-wrapper
                                .column
                                    width: 100%

                        &.submenu-sm
                            .column-wrapper
                                .column
                                    .column-items
                                        .column-item
                                            a	
                                                padding: 23px 0 0 0								
                 
                        .column-wrapper	
                            padding: 0		
                            flex-direction: column		
                            max-height: 0
                            overflow: hidden
                            transition: max-height 0.15s ease-out	
                            @media(max-width: 1199px)
                                width: fit-content
                            .column-header
                                padding: 26px 0 0 0
                                margin: 0                                       
                            .column
                                padding: 0
                                                             



                                .column-items
                                
                                    > .column-item
                                        margin: 0									
                                        > a
                                            border: none										
                                            &:hover
                                                background-color: white
                                                color: ligt-grey-font-color	
                                                font-weight: bold																			

                                            > .img-wrapper
                                                display: none

                                                &::before
                                                    content: ""

                                                > img
                                                    max-width: 100%

                                            > span
                                                padding: 23px 0 0 0
                                                width: 100%


            &.actions-menu
                flex-direction: row-reverse
                display: flex
                @media(min-width: 1200px)
                    justify-content: space-around

                li
                    // margin-left: 18px
                    // @media(max-width: 600px) and (min-width: 513px)
                    // 	margin-left: 0px					

                    a
                        color: primary-font-color
                        text-decoration: none

                        // &:hover
                        //     font-weight: normal
                        //     background-color: lighter-blue
                        //     color: red

                        &.btn-normal
                            padding: 0 15px
                            line-height: 20px
                            border: 1px solid brand-color
                            border-radius: 4px
                            font-weight: bold
                            text-transform: uppercase
                            transition: all 0.15s ease
                            font-size: 15px
                            &:hover
                                color: #fff
                                border: 1px solid brand-color
                                background-color: brand-color


                    .btn-join-project
                        background-color: primary-font-color
                        color: white
                        border: 1px solid primary-font-color !important                    
                        &:hover
                            border: 1px solid brand-color !important 


                    // .btn-menu-search
                    //     color: brand-color

                    &.mobile-menu-toggler
                        flex-direction: column
                        justify-content: space-between
                        height: 14px
                        width: 21px
                        cursor: pointer
                        display: none

                        @media(max-width: 600px) and (min-width: 513px)
                            margin-left: 0px

                        span
                            width: 100%
                            height: 2px
                            background-color: primary-font-color
                            





                        @media( max-width: 1199px )
                            display: flex

                    &.md-hide
                        @media( max-width: 1199px )
                            display: none

                    &.sm-hide
                        @media( max-width: 530px )
                            display: none

        .logo-wrapper
            padding: 15px 22px 0
            @media( max-width: 1199px )
                padding: 2px 22px 0   
            // // @media( max-width: 512px )
            //     width: 50%

            .logo
                display: inline-block
                // padding: 20px 0 15px
                height: 90px
                display: flex
                justify-content: center
                @media(max-width: 1200px)
                    justify-content: flex-start
                @media(max-width: 1199px)
                    width: 150px            
                @media(min-width: 1200px)
                    width: 174px  


            .logo-lrg
                @media(max-width: 1199px)
                    display: none
                img
                    width: 150px
                    height: 90px

            .logo-small              
                @media(min-width: 1200px)
                    display: none 
                img
                    width: 174px           
                    height: 90px
        // .right-wrapper
        
        //     @media( max-width: 512px )
        //         width: 50%

    .decoration 
        position: relative	
        width: 222px
        height: 15px
        margin: auto
        background-color: white
        margin-bottom: -13px
        z-index: 2
        @media(max-width: 1199px)
            display: none


        &::before
            content: ""
            display: block
            position: absolute
            height: 14px
            bottom: 0px
            right: 98%
            width: 93px
            transform: rotateX(180deg) rotate(180deg)
            background-image: url(../img/decoration-right.png)

        &::after
            content: ""
            display: block
            position: absolute
            height: 14px
            bottom: 0px
            left: 98%
            width: 93px					
            background-image: url(../img/decoration-right.png)




.menu-open nav ul.main-menu
    display: block
    @media(min-width: 1200px)
        display: flex

.menu-open
    nav
        ul
            &.actions-menu	
                li
                    &.mobile-menu-toggler		
                        span
                            transition: 0.3s transform linear
                            transform-origin: center

                            &:first-child
                                transform: rotate(45deg) translateY( 8px )
                            &:nth-child(2)
                                display: none
                            &:nth-child(3)
                                transform: rotate(-45deg) translateY( -8px )

.menu-open nav .logo-wrapper .logo-small
    display: none

.menu-open nav .actions-menu
    @media(max-width: 599px)
        height: 58px
    
.menu-open nav ul.main-menu

    @media(max-width: 1199px) and (min-width: 426px)
        padding: 0 22px 0 100px

    @media(max-width: 599px)
        top: 59px
        height: calc(100vh - 42px)

    @media(max-width: 425px)
        padding: 0 22px