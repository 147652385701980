.exit-cross
    position absolute
    top: 20px
    right: 14px
    width: 26px
    height: 26px
    cursor: pointer
    z-index: 1
    &::before
        position: absolute
        right: 12px
        top: 2px
        // left: 15px
        content: ''
        height: 22px
        width: 2px
        background-color: brand-color
        transform: rotate(45deg)			
    &::after
        position: absolute
        right: 12px
        top: 2px
        // left: 15px
        content: ''
        height: 22px
        width: 2px
        background-color: brand-color
        transform: rotate(-45deg)