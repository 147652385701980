#contact
    #contact-form
        .form-col
            padding-bottom: 60px
            .form-group
                margin-bottom: 11px
                label
                    font-weight: 400
            .form-control
                border-color: gray-border
                height: 37px
                // @media(max-width:767px)
                //     width: 50%
            .form-message
                height: 100px
            input
                color: primary-color
                &::-webkit-input-placeholder
                    color: primary-color
                    opacity: 1					
                &::-moz-placeholder
                    color: primary-color
                    opacity: 1
                &:-ms-input-placeholder
                    color: primary-color
                    opacity: 1
                &:-moz-placeholder
                    color: primary-color
                    opacity: 1
            .form-control
                &:focus
                    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(215, 215, 215, 0.6)
                    border-color: gray-border 
                        
            .form-header
                margin-bottom: 40px
            .form-message
                color: buttons-color
                max-height: 110px
                min-height: 110px            
                max-width: 100%
            button
                margin-top: 40px