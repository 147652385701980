#single-news
    margin: -60px 0 0;
    position: relative;
    .baner-photo-wrapper
        width: 100%
        height: 570px
        .background-photo
            width: 100%
            height: 100%
            position: relative
            display: flex
            padding: 80px 30px
            background-size: cover
            background-position: center
            background-repeat: no-repeat
            &:before
                content: ''
                position: absolute 
                top: 0
                left: 0
                width: 100%
                height: 100%
                background-color: rgba(7, 7, 73, 0.45)
            @media(min-width: 768px)
                padding: 100px 20%
            @media(min-width: 1200px)
                padding: 150px 35% 150px 20%
            .text-wrapper
                width: 100%
                display: flex
                z-index: 10
                flex-direction: column
                justify-content: center
                h3.news-title
                    font-size: 50px
                    margin: 0 !important
                    line-height: 56px
                    color: white
                    font-family: 'Titillium Web',sans-serif
                    font-weight: bold
                    @media(min-width: 768px)
                        font-size: 80px
                        line-height: 86px
                p.news-description
                    font-size: 18px
                    line-height: 24px
                    color: white
                    margin-top: 30px
                    font-family: 'Titillium Web',sans-serif

.content-wrapper
    width: 100%
    padding: 10px 30px
    @media(min-width: 992px)
        padding: 10px 20%
    @media(min-width: 1200px)
        padding: 10px 30%
    .single-news-text
        p
            font-size: 18px
            line-height: 24px
            color: primary-color
            margin-top: 30px
            font-family: 'Titillium Web',sans-serif
    .single-news-signing-wrapper
        margin: 30px 0
        p.single-news-signing
            font-size: 14px !important
            line-height: 19px !important
            color: primary-color
            font-family: 'Titillium Web',sans-serif
            font-weight: bold
            margin: 0 !important

.container
    .single-news-photo
        width: 100%
        height: 360px
        margin: 50px 0
        border-radius: 15px
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        @media(min-width: 768px)
            height: 560px

.header-author-wrapper
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-top: 50px
    @media(min-width: 992px)
        flex-direction: row
        margin-top: 100px
    .date-author-wrapper
        display: flex
        flex-direction: column
        justify-content: space-between
        max-height: 40px
        width: 50%
        padding-left: 30px
        @media(min-width: 992px)
            width: 20%
            padding-right: 60px
        @media(min-width: 1200px)
            width: 30%
            padding-left: 15%
        @media(min-width: 1440px)
            padding-left: 17%
        @media(min-width: 1920px)
            padding-left: 20%
        .date-author
            font-size: 14px
            line-height: 19px
            color: primary-color
            margin: 0 !important
            font-family: 'Titillium Web',sans-serif
    .single-news-header
        width: 50%
        padding-top: 20px
        padding-left: 30px
        @media(min-width: 992px)
            padding: 0
            width: 80%
        @media(min-width: 1200px)
            width: 70%
        p
            font-size: 24px
            line-height: 30px
            color: primary-color
            font-family: 'Titillium Web',sans-serif

.header-author-wrapper-back
    width: 100%
    display: flex
    flex-direction: column
    justify-content: space-between
    margin-top: 25px
    @media(min-width: 992px)
        flex-direction: row
        margin-top: 40px
    .date-author-wrapper-back
        display: flex
        flex-direction: column
        justify-content: space-between
        max-height: 40px
        width: 50%
        padding-left: 30px
        @media(min-width: 992px)
            width: 20%
            padding-right: 20px
        @media(min-width: 1200px)
            width: 30%
            padding-left: 15%
        @media(min-width: 1440px)
            padding-left: 17%
        @media(min-width: 1920px)
            padding-left: 20%
        a.back-link
            text-decoration: none !important    
        .text-arrow-wrapper
            display: flex
            flex-direction: row
            justify-content: space-between
            text-decoration: none !important   
            position: relative
            cursor: pointer
            &:before
                content: ''
                position: absolute 
                top: 30px
                left: 0
                width: 28px
                height: 3px
                background-color: #57c5c9
            .back-text
                font-size: 14px
                line-height: 19px
                color: #57c5c9
                margin: 0 !important
                font-family: 'Titillium Web',sans-serif
                font-weight: bold
                .arrow-back
                    width: 9px
                    height: 11px