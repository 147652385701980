.create-account
    .net
        position: relative
        height: auto
        color: ligt-grey-font-color
        p
            font-size: 14px
            line-height: 24px
            color: ligt-grey-font-color
        .info
            font-size: 11px
            text-transform: uppercase
            margin-top: 20px
            text-align: center
        h1
            &, & *			
                margin-bottom: 20px
    

        h2
            &, & *			
                font-size: 24px
                margin-bottom: 24px
                background: transparent
                color: buttons-color		
                @media(max-width: 320px)
                    font-size: 21px


        .flex-wrapper
            .left-sidebar
                width: 670px
                padding-top: 22px
                padding-bottom: 22px
                min-height: 100vh
                flex-direction: column
                align-items: center
                display: flex			
                .login-content
                    .reset-paragraph
                        margin-bottom: 80px  
                        @media(max-width: 375px)
                            margin-bottom: 50px  						
                    .account-paragraph
                        margin-bottom: 20px 						 
                    .account-telephone
                        p
                            margin-bottom: 40px    
                            color: brand-color 						                 

                .forms
                    .form-group
                        margin-bottom: 20px

                        .warning
                            display: none
                            color: #ce8483
                            
                        label
                            padding-left: 12px
                            font-weight: 400

                    .input-group
                        margin-bottom: 11px
                        width: 100%

                    .form-control
                        border-color: gray-border
                        height: 40px

                    .has-error
                        .form-control                 
                            border-color: #ce8483 
                            box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 6px #ce8483                            
                        
                        .warning
                            display: block

                    input
                        color: primary-color
                        font-weight: 600
                        &::-webkit-input-placeholder
                            color: #b1b1b1
                            opacity: 1
                            font-weight: 400					
                        &::-moz-placeholder
                            color: #b1b1b1
                            opacity: 1
                            font-weight: 400	                            
                        &:-ms-input-placeholder
                            color: #b1b1b1
                            opacity: 1
                            font-weight: 400		                            
                        &:-moz-placeholder
                            color: #b1b1b1
                            opacity: 1
                            font-weight: 400	                            


            .buttons
                margin-top: 50px


    // .nopadding
    // 	padding: 0	

    // .seconary-color
    // 	color: secondary-font-color

    // .form-control
    // 	&:focus
    // 		box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(215, 215, 215, 0.6)
            
    // .btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus
    // 	outline-color: shadow-primary-color
        
    .logo
        padding-bottom 100px
        @media(max-width: 1024px)
            padding-bottom: 80px
        @media(max-width: 768px)
            padding-bottom: 50px
        svg, img
            width: 185px

    .bar
        background-color: primary-font-color
