#crumbs
    font-size: 10px
    background-color: brand-color
    border-bottom: 1px solid #2ca3b3		
    .breadcrumb
        background-color: brand-color
        margin: 0
        padding-left: 0
        padding-right: 0
        >.active
            color: white
        >li+li:before
            color: white	
        li
            text-transform: uppercase
            a, a:hover, a:active, a:focus, a:visited
                color: white
                text-decoration: none	