div#driving-directions
    height: 700px;
    width: calc(100% + 44px);
    position: relative;
    left: -22px;
    border-radius: 20px;
    box-shadow: 0 10px 2px -2px #f0f0f0;
    position: relative;
    @media(min-width: 992px)
        width: 100%;
        left: 0;

div.map-wrapper 
    position: relative;
    .informations-box
        position: absolute; 
        top: -20px; 
        left: -22px;
        width: calc(100% + 44px);
        max-height: 370px;
        box-sizing: border-box;
        border-bottom: 10px;
        border-radius: 30px; 
        box-shadow: 0 10px 2px -2px rgba(240,240,240,0.1);
        background-color: #57c5c9;
        z-index: 10;
        padding: 10%;
        @media(min-width: 550px)
            padding: 5% 10%;
        @media(min-width: 992px)
            top: 100px;
            left: 0;
            max-width: 500px;
            max-height: 480px;
            padding: 5% 5%; 
        @media(min-width: 1200px)
            max-width: 550px;
        @media(min-width: 1440px)
            left: -10%;
        @media(min-width: 1920px)
            left: -15%;
            max-width: 600px;
        & *
            font-size: 24px;
            font-family: 'Titillium Web', sans-serif;
            line-height: 34px;
            margin: 0;
            @media(min-width: 992px)
                font-size: 34px;
                margin: 0 0 10px;
        .contact-info-title
            color: #232f5f;
        .contact-info
            color: white;



