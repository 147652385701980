.btn, .navbar-btn
    text-transform: uppercase
    border-width: 1px
    font-weight: 700 !important
    background-image: none
    font-family: 'Titillium Web',sans-serif

.btn-background
    background-image: none
    background-color: transparent
    &:focus
        background-image: none
        background-color: transparent

.btn-white 
    color: primary-font-color
    background-color: white
    transition: background-color 0.3s ease-out     
    &:hover
        color: primary-font-color
        color: white
        background-color: brand-color
    &:focus
        color: white
        background-color: brand-color

.button-nav
    margin-top: 34px

.btn-log
    width: 96px
    border-color: light-blue 

.btn-join
    width: 153px
    border-color: light-blue
    margin-left: 13px
.btn-join-project
    background-color: primary-font-color
    color: white

.btn, .btn-default, .navbar-btn, .floatleft, .button-nav
    height: 20px
 
.btn-send
    background-color: primary-font-color
    color: #fff
    font-size: 18px
    font-weight: bold    
    text-transform: uppercase
    height: 42px
    padding-left: 20px
    padding-right: 20px
    &:hover
        color: white
    &:focus
        color: white

.btn-wide
    width: 100%	
    white-space: normal
    height: auto 

.btn-play
    font-size: 18px  
    @media(max-width: 425px)
        font-size: 16px
    // @media(max-width: 1024px)
    //     font-size: 16px        
    height: 42px 
    display: block
    margin-top: space
    border: 1px solid brand-color
    border-radius: 4px
    display: flex
    align-items: center
    img,svg
        display: inline-block
        margin-right: 12px 
        height: 12px
        width: 10px
        fill: primary-font-color
    &:hover
        svg
            fill: white

.btn-steps
    @media(max-width: 425px)
        font-size: 12px

.btn-circle-play
    height: 90px
    width: 90px
    border-radius: 50%
    background-color: white
    position: absolute
    top: 50%
    left: 50%
    transform: translate( -50%, -50% )
    transition: background-color 0.3s ease-out
    &:hover
        background-color: click-play-font

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus,.btn-circle-play
    outline-color: transparent
.btn-standard
    height: 42px
    font-size: 18px
    color: buttons-color
    text-transform: lowercase
    background-color: white
    margin-top: space
    padding: 6px 14px
.btn-standard::first-letter
    text-transform: uppercase   

.btn-films-play
    height: 34px
    width: 27px
    fill: white
    position: absolute
    top: 50%
    left: 50%
    transform: translate( -50%, -50% )
    transition: background-color 0.3s ease-out
    transition: fill 0.3s ease-out

header
    .ui-selectmenu-button.ui-button
        font-family: 'Titillium Web', sans-serif
        color: white
        background-color: transparent
        border: 1px solid white
        border-radius: 4px
        font-weight: bold
        font-size: 15px
        line-height: 21px
        padding: 0 15px
        width: 70px
        text-transform: uppercase
        &:focus
        &:hover
            color: primary-font-color
            color: white
            background-color: brand-color
            .ui-icon
                transform: rotate(180deg)
                background-image: url('../img/select-arrow.png')
                background-position: center
                transition: transform 0.4s ease      

        .ui-icon
            background-image: url('../img/green-arrow.png')
            background-position: center
            transition: transform 0.4s ease
            // &:hover
            //     background-image: url('../img/select-arrow.png')
            //     background-position: center
            //     transition: transform 0.4s ease                 
        &:focus
            outline-color:white	
            .ui-selectmenu-text
                height:18px
                width: 18px				
            &:focus
                outline-color: white

        &.ui-selectmenu-button-open
            .ui-icon
                transform: rotate(180deg)
                background-image: url('../img/select-arrow.png')
                background-position: center
                transition: transform 0.4s ease   

    .ui-selectmenu-button.ui-button
        background: transparent
        color: primary-font-color
        border: 1px solid light-blue

    .btn-return
        color: primary-font-color
        background: transparent
        border: 1px solid white
        border-radius: 4px
        font-weight: bold
        font-size: 13px
        height: 22px
        width: 167px
        &:focus
            outline-color: white

    .ui-selectmenu-menu.ui-front.ui-selectmenu-open
        top: 50px
        left: 1775.7px
        position: fixed
        right: 70px
        z-index: 2
        .ui-menu.ui-corner-bottom.ui-widget.ui-widget-content.overflow
            border-radius: 3px

    .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover
        background: brand-color
        border: 1px solid light-blue
        color: white

    .select2-selection.select2-selection--single
        background-color: white
    .select2-offscreen 
        position: fixed !important

    .select2-container
        .select2-selection--single
            height: 40px

    .select2-container--default
        &.select2-container--open 
            .select2-selection--single 
                .select2-selection__arrow 
                    b
                        border-color: transparent
                        border-width: 5px
                        transform: rotate(-180deg)

        .select2-selection--single
            border: 1px solid #ccc
            border-radius: 4px

            .select2-selection__rendered
                line-height: 40px
                padding-left: 35px
                color: #232f5f
            &:focus
                outline-color: #d7d7d7
                
            .select2-selection__arrow
                width: 94px
                border-left: 1px solid #ccc		
                height: calc(100% - 1px)
                
                b
                    background-image: url('../img/form-arrow.png')
                    background-position: center
                    background-repeat: no-repeat
                    border-color: transparent
                    border-style: solid
                    border-width: 5px
                    height: 6px
                    left: 50%
                    margin-left: -4px
                    margin-top: -4px
                    position: absolute
                    top: 50%
                    width: 9px					
                    transition: transform 0.4s ease
                    
        .select2-results__option[aria-disabled=true]
            display:none
        .select2-results__option--highlighted[aria-selected]
            background-color: #f0f0f0
            color: #bcbcbc
        .select2-results__option[aria-selected=true] 
            background-color: #f0f0f0

    // .select2-results
    // 	height: 211px
    .select2-results__options
        li
            padding-left:35px
            color: #bcbcbc

    .select2-container--default 
        .select2-search--dropdown 
            .select2-search__field
                outline-color: #d7d7d7

.btn-reset
    padding: 6px 50px

.btn-popup
    font-family: 'Titillium Web', sans-serif
    font-size: 13px
    line-height: 13px
    text-transform: uppercase
    background-color: white
    border: 1px primary-font-color solid
    text-decoration: none
    color: primary-font-color
    height: 32px
    padding: 8px 32px
    &:hover
    &:focus
        color: primary-font-color


.btn-tiles
    border: 1px solid primary-font-color
    border-radius: 5px
    background-color: white
    min-height: 132px
    transition: all 500ms ease
    color: primary-font-color
    &:hover
    &:focus
        background-color: primary-font-color
        transition: all 500ms ease
        color: white

.btn-green
    font-family: 'Titillium Web', sans-serif
    font-size: 13px
    line-height: 13px
    text-transform: uppercase
    background-color: brand-color
    border: 1px brand-color solid
    text-decoration: none
    color: white
    height: 32px
    transition: all 300ms ease
    &:hover
    &:focus
        color: brand-color	
        background-color: white
        transition: all 300ms ease

.btn-next
    background-color: #1792a8
    color: #fff
    font-size: 18px
    text-transform: uppercase
    height: 42px
    min-width: 93px
    &:hover, &:focus
        color: white

.btn-light
    background-color: transparent
    border: 1px solid primary-font-color
    color: primary-font-color
    font-weight: 700
    text-transform: uppercase
    height: auto
    transition: all 300ms ease
    &:hover
        color: white
        background-color: primary-font-color
        transition: all 300ms ease
    &:focus
        color: white
        background-color: primary-font-color
        transition: all 300ms ease

.btn-blue
    background-color: primary-font-color
    border: 1px solid primary-font-color
    color: white
    font-weight: 700
    text-transform: uppercase
    height: auto
    transition: all 300ms ease
    &:hover
        color: primary-font-color
        background-color: white
        transition: all 300ms ease
    &:focus
        color: primary-font-color
        background-color: white
        transition: all 300ms ease