#location
    @media(max-width:1199px)
        padding-top: 105px !important
.styl-map
    color: buttons-color 
    h6
        font-size: 14px
        font-weight: bold
    p
        font-size: 14px
    .btn-next
        font-size: 13px
        background-color: brand-color
        height: 22px
        line-height: 9px
        margin-top: 11px